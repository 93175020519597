import { useMutation, useQueryClient, UseMutationResult } from 'react-query'
import {
  useUpdateScheduledJobRPC,
  TUpdateScheduledJobRequest,
  TUpdateScheduledJobResponse
} from 'lib/hooks/api/useUpdateScheduledJobRPC'
import { jobQueryKeys } from './queryKeys'
import { TServerError } from 'lib/gRPC'

// The return type of the usePostScheduledJob mutation hook.
export type TUpdateScheduledJobMutation = UseMutationResult<
  TUpdateScheduledJobResponse,
  TServerError,
  TUpdateScheduledJobRequest,
  unknown
>

/**
 * Hook for updating an existing scheduled job.
 *
 * @returns {TUpdateScheduledJobMutation} - The react-query mutation object.
 */
export const useUpdateScheduledJob = (): TUpdateScheduledJobMutation => {
  const rpc = useUpdateScheduledJobRPC()
  const queryClient = useQueryClient()
  return useMutation(rpc, {
    onSuccess: async (resp) => {
      queryClient.invalidateQueries(jobQueryKeys.userJobs)
      queryClient.setQueryData(jobQueryKeys.job(resp.id), resp)
    }
  })
}
