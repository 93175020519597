import React from 'react'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend'
import { Formik, Form, FormikHelpers } from 'formik'
import { styled } from '@mui/material/styles'

import { ConnectedTextField } from 'components/lib/Form'
import ScreenParameters from './ScreenParameters'
import ScheduleField from './ScheduleField'
import ScreenEntitiesField from './ScreenEntitiesField'
import { IDropDownData } from 'components/lib/ListSourcesDropDown'
import { TSchedule } from 'lib/features/job'
import ContentPane from 'components/lib/ContentPane'
import { useNotification } from 'lib/notification'

import FileInstructions from 'components/App/BatchSearchPage/FileInstructions'

const StyledFileInstructions = styled(FileInstructions)(() => ({
  marginTop: 24
}))

const PaddedFormLabel = styled(FormLabel, { label: 'PaddedFormLabel' })(() => ({
  paddingBottom: 16
}))

export interface IFormValues {
  name: string
  scoreThreshold: number
  schedule: TSchedule | null
  screenEntities: File | null
  weakAKAs: boolean
  fuzzyDOB: boolean
}

export type TFormErrors = Record<keyof IFormValues, string>

type TJobFormProps = {
  initialValues: IFormValues
  listSourcesDropDownOptions: IDropDownData
  filename?: string
  validate: (values: IFormValues) => TFormErrors
  onSubmit: (values: IFormValues, actions: FormikHelpers<IFormValues>) => void
}

const JobForm = ({
  initialValues,
  listSourcesDropDownOptions,
  filename,
  validate,
  onSubmit
}: TJobFormProps): JSX.Element => {
  const setNotification = useNotification()

  const handleSubmit = (
    values: IFormValues,
    actions: FormikHelpers<IFormValues>
  ) => {
    if (!listSourcesDropDownOptions.anySelected()) {
      setNotification({
        variant: 'error',
        message: 'Select at least one List Source'
      })
    } else {
      onSubmit(values, actions)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
    >
      <>
        <ContentPane>
          <Form>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Stack direction='row' spacing={3}>
                  <CalendarTodayIcon
                    fontSize='large'
                    sx={{ marginTop: '20px', visibility: 'hidden' }}
                  />

                  <FormControl fullWidth required data-cy='form-control-name'>
                    <FormLabel>Scheduled Screen Name</FormLabel>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <ConnectedTextField name='name' />
                      </Grid>
                    </Grid>
                  </FormControl>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack direction='row' spacing={3}>
                  <CalendarTodayIcon
                    fontSize='large'
                    sx={{ marginTop: '40px', visibility: 'hidden' }}
                  />
                  <FormControl fullWidth>
                    <FormLabel>Screen Parameters</FormLabel>
                    <ScreenParameters
                      listSourcesDropDownOptions={listSourcesDropDownOptions}
                    />
                  </FormControl>
                </Stack>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Stack direction='row' spacing={3}>
                  <CalendarTodayIcon
                    fontSize='large'
                    sx={{ marginTop: '40px' }}
                  />
                  <FormControl
                    fullWidth
                    required
                    data-cy='form-control-schedule'
                  >
                    <PaddedFormLabel>Schedule</PaddedFormLabel>
                    <ScheduleField />
                  </FormControl>
                </Stack>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Stack direction='row' spacing={3}>
                  <PeopleAltIcon fontSize='large' sx={{ marginTop: '40px' }} />
                  <FormControl required data-cy='form-control-screen-entities'>
                    <PaddedFormLabel>Screen Entities</PaddedFormLabel>
                    <ScreenEntitiesField filename={filename} />
                  </FormControl>
                </Stack>
              </Grid>

              <Grid item container xs={12} justifyContent='center'>
                <Button
                  color='secondary'
                  endIcon={<ScheduleSendIcon />}
                  type='submit'
                  variant='contained'
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Form>
        </ContentPane>
        <StyledFileInstructions />
      </>
    </Formik>
  )
}

export default JobForm
