import { useGRPC } from 'lib/hooks'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb.js'
import { GetEntityTypesResponse, EntityType } from 'proto-js'

/** The processed entity types response type. */
type TResp = string[]

/**
 * Format the RPC response and create a list of entity types. Here we generate a list of
 * entity types by name since the raw response has this information as enums i.e.
 * integers.
 *
 * @param {typeof GetEntityTypesResponse} resp - The raw RPC response protobuf message
 * @returns {TResp} - The list of entitiy types
 */
const prepareRPCResponse = (resp: typeof GetEntityTypesResponse): TResp => {
  const ret = resp
    .getEntityTypesList()
    .map((t: typeof EntityType) => t.getName())
  return ret
}

/**
 * Hook providing the RPC for getting a list of entity types.
 *
 * @returns {() => Promise<typeof GetEntityTypesResponse>} - The RPC function
 */
export const useEntityTypesDataRPC = (): (() => Promise<TResp>) => {
  const { dataPromiseService } = useGRPC()
  return async (): Promise<TResp> => {
    const req = new Empty()
    const md = { deadline: new Date(Date.now() + 2 * 1000).getTime() }
    return dataPromiseService
      .getEntityTypes(req, md)
      .then((resp: typeof GetEntityTypesResponse) => {
        return prepareRPCResponse(resp)
      })
  }
}

export default useEntityTypesDataRPC
