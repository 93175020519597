import React from 'react'
import {
  usePostScreen,
  usePostScreenedEntity,
  usePostAudits,
  TAdHocScreen,
  TScreenData,
  TScreenedEntity,
  TPersistedScreenedEntity,
  isPersistedScreenedEntity
} from 'lib/features/Screening'
import { TWorkflowState } from './workflowReducer'
import { TPostScreenResponse } from 'lib/hooks/api/usePostScreenRPC'

export type TPostFunc = () => Promise<
  TPostScreenResponse | TPersistedScreenedEntity | void
>

type TSubmitWorkflowProps = {
  screenData: TScreenData
  screenedEntityIndex: number
  workflow: TWorkflowState
  children: (arg0: TPostFunc) => JSX.Element
}

/**
 * SubmitWorkflow wraps a child component exposing functionality to submit the results of a
 * workflow. Depending on the state of the current screen it'll post a new screen, post a new
 * screen entity, or just post audit logs.
 *
 * @component
 * @example
 * <SubmitWorkflow screen={screen} screenEntityIndex={screenEntityIndex} workflow={workflow}>
 *  {handleSubmit} => <button onClick={handleSubmit}>Click Me!</button>
 * </SubmitWorkflow>
 */
const SubmitWorkflow = ({
  children: children,
  screenData: screenData,
  ...rest
}: TSubmitWorkflowProps): JSX.Element => {
  const screenedEntity = screenData.entities[rest.screenedEntityIndex]

  if (!('id' in screenData.screen)) {
    return (
      <PostScreen
        screen={screenData.screen}
        screenedEntity={screenedEntity}
        {...rest}
      >
        {children}
      </PostScreen>
    )
  } else if (!isPersistedScreenedEntity(screenedEntity)) {
    return (
      <PostScreenedEntity
        screenID={screenData.screen.id}
        screenedEntity={screenedEntity}
        {...rest}
      >
        {children}
      </PostScreenedEntity>
    )
  } else {
    return (
      <PostAudits
        screenID={screenData.screen.id}
        screenedEntity={screenedEntity}
        {...rest}
      >
        {children}
      </PostAudits>
    )
  }
}

type TPostScreenProps = {
  screen: TAdHocScreen
  screenedEntityIndex: number
  screenedEntity: TScreenedEntity
  workflow: TWorkflowState
  children: (arg0: TPostFunc) => JSX.Element
}
const PostScreen = (props: TPostScreenProps) => {
  const postScreen = usePostScreen(props.screenedEntityIndex, props.workflow)
  const post = async () => {
    return postScreen.mutateAsync({
      screen: props.screen,
      screenedEntity: props.screenedEntity
    })
  }
  return props.children(post)
}

type TPostScreenedEntityProps = {
  screenID: number
  screenedEntityIndex: number
  screenedEntity: TScreenedEntity
  workflow: TWorkflowState
  children: (arg0: TPostFunc) => JSX.Element
}
const PostScreenedEntity = (props: TPostScreenedEntityProps) => {
  const postScreenedEntity = usePostScreenedEntity(
    props.screenedEntityIndex,
    props.workflow
  )
  const post = async () => {
    return postScreenedEntity.mutateAsync({
      screenID: props.screenID,
      screenedEntity: props.screenedEntity
    })
  }
  return props.children(post)
}

type TPostAuditsProps = {
  screenID: number
  screenedEntityIndex: number
  screenedEntity: TPersistedScreenedEntity
  workflow: TWorkflowState
  children: (arg0: TPostFunc) => JSX.Element
}
const PostAudits = (props: TPostAuditsProps) => {
  const postAudits = usePostAudits(props.screenedEntityIndex)
  const post = async () => {
    return postAudits.mutateAsync({
      screenID: props.screenID,
      screenedEntity: props.screenedEntity,
      workflow: props.workflow
    })
  }
  return props.children(post)
}

export default SubmitWorkflow
