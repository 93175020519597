import { useMutation, UseMutationResult, useQueryClient } from 'react-query'
import {
  usePostAuditsRPC,
  TPostAuditsParams
} from 'lib/hooks/api/usePostAuditsRPC'
import {
  screenMutationKeys,
  isPersistedScreenedEntity,
  TPersistedScreenedEntity,
  TScreenData
} from '.'
import { findScreenDataFromCache, setScreenDataCache } from './utils'
import { TWorkflowState } from 'components/lib/Workflow/workflowReducer'
import { auditQueryKeys } from 'lib/features/audit'
import { replyAuditStatus } from 'lib/helpers/reply'

// The return type of the usePostAudits mutation hook.
export type TPostAuditsMutation = UseMutationResult<
  void,
  unknown,
  TPostAuditsParams,
  unknown
>

/**
 * classifyHits updates the classification of the hits in the screened entity based on the workflow
 * output.
 *
 * @param screenedEntity - The screened entity to update.
 * @param workflow - The workflow state.
 * @returns
 */
const classifyHits = (
  screenedEntity: TPersistedScreenedEntity,
  workflow: TWorkflowState
) => {
  const newScreenedEntity = { ...screenedEntity }
  workflow.checkedHitIndices.forEach((i) => {
    newScreenedEntity.hits[i].classification = workflow.classification
  })
  newScreenedEntity.status = replyAuditStatus(newScreenedEntity)
  return newScreenedEntity
}

/**
 * updateScreenData updates the screened entity with the workflow data and splices the change into
 * the broader screen data object.
 *
 * @param screenData - The screen data to update.
 * @param screenedEntity - The screened entity to update.
 * @param screenedEntityIndex - The index of the screened entity within the screen data.
 * @param workflow - The workflow data.
 * @returns
 */
const updateScreenData = (
  screenData: TScreenData,
  screenedEntity: TPersistedScreenedEntity,
  screenedEntityIndex: number,
  workflow: TWorkflowState
) => {
  // Update the classification of the screen entity
  screenedEntity = classifyHits(screenedEntity, workflow)
  const newScreenData = {
    ...screenData
  }
  newScreenData.entities.splice(screenedEntityIndex, 1, screenedEntity)
  return newScreenData
}

/**
 * Hook for posting audit information generated by going through a workflow. The active screen
 * cache is updated to reflect how hits were classified, but it does not store the full audit
 * information/history.
 *
 * @param {number} screenedEntityIndex - The index of the screen entity to audit.
 * @returns {UseMutationResult} - The react-query mutation object.
 */
export const usePostAudits = (
  screenedEntityIndex: number
): TPostAuditsMutation => {
  const queryClient = useQueryClient()
  const rpc = usePostAuditsRPC()
  return useMutation((params) => rpc(params), {
    mutationKey: screenMutationKeys.all,
    onSuccess: async (data, variables) => {
      const { screenData, queryKey } = findScreenDataFromCache(
        queryClient,
        variables.screenID
      )
      if (screenData !== undefined) {
        const screenedEntity = screenData.entities[screenedEntityIndex]
        if (isPersistedScreenedEntity(screenedEntity)) {
          // Invalidate the audit logs query for this screen entity
          queryClient.invalidateQueries(auditQueryKeys.logs(screenedEntity.id))
          const newScreenData = updateScreenData(
            screenData,
            screenedEntity,
            screenedEntityIndex,
            variables.workflow
          )
          setScreenDataCache(queryClient, newScreenData, queryKey)
        }
      }
    }
  })
}
