import { IEntitySearchParams } from 'types'

export const screenQueryKeys = {
  all: ['screenData'],
  adHoc: ['screenData', 'adHoc'],
  advanced: (
    params: IEntitySearchParams,
    isActive: boolean
  ): [string, string, string, IEntitySearchParams, boolean] => [
    'screenData',
    'adHoc',
    'advanced',
    params,
    isActive
  ],
  batch: ['screenData', 'adHoc', 'batch'],
  persisted: (screenID: number): (string | Record<string, number>)[] => [
    'screenData',
    { screenID: screenID }
  ]
}
