import { TScheduledJob } from 'lib/features/job'
import { useGRPC } from 'lib/hooks'
import { scheduleToProtobuf, jobFromProtobuf } from './utils'
import {
  File,
  UpdateScheduledJobRequest,
  UpdateScheduledJobResponse,
  ScheduledJob
} from 'proto-js'

export type TUpdateScheduledJobRequest = {
  job: Persisted<TScheduledJob>
  file?: File
}

export const prepareRPCRequest = async (
  request: TUpdateScheduledJobRequest
): Promise<typeof UpdateScheduledJobRequest> => {
  const job = new ScheduledJob()
  job.setId(request.job.id)
  job.setName(request.job.name)
  job.setSchedule(scheduleToProtobuf(request.job.schedule))
  job.setFilename(request.job.filename)
  if (request.job.listSourceCategoryIDs) {
    job.setListSourceCategoryIdsList(request.job.listSourceCategoryIDs)
  }
  if (request.job.listSourceIDs) {
    job.setListSourceIdsList(request.job.listSourceIDs)
  }
  if (request.job.listIDs) {
    job.setListIdsList(request.job.listIDs)
  }
  job.setMinScore(request.job.minScore)
  job.setExcludeWeakAkas(!request.job.weakAKAs)
  job.setDobMonthsRange(request.job.dobMonthsRange)

  const rpcRequest = new UpdateScheduledJobRequest()
  rpcRequest.setJob(job)
  if (request.file) {
    const file = new File()
    file.setBytes(
      await request.file.arrayBuffer().then((b) => new Uint8Array(b))
    )
    rpcRequest.setFile(file)
  }

  return rpcRequest
}

export type TUpdateScheduledJobResponse = Persisted<TScheduledJob>

export const prepareRPCResponse = (
  resp: typeof UpdateScheduledJobResponse
): TUpdateScheduledJobResponse => jobFromProtobuf(resp.getJob())

type TUpdateScheduledJobRPC = (
  params: TUpdateScheduledJobRequest
) => Promise<TUpdateScheduledJobResponse>

/**
 * useUpdateScheduledJobRPC provides an async function to invoke the UpdateScheduledJob RPC.
 *
 * @returns {TUpdateScheduledJobRPC} - An async function to invoke the RPC.
 */
export const useUpdateScheduledJobRPC = (): TUpdateScheduledJobRPC => {
  const { workflowPromiseService } = useGRPC()
  return async (params: TUpdateScheduledJobRequest) =>
    await prepareRPCRequest(params).then(async (req) => {
      return workflowPromiseService
        .updateScheduledJob(req, {})
        .then(prepareRPCResponse)
    })
}
