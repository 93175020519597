import React, { Dispatch } from 'react'
import FormControlLabel, {
  FormControlLabelProps
} from '@mui/material/FormControlLabel'
import Switch, { SwitchProps } from '@mui/material/Switch'
import Help from '@mui/icons-material/Help'
import Tooltip from '@mui/material/Tooltip'
import styled from '@mui/system/styled'
import { FieldState, FieldAction } from 'lib/hooks/useFormField'
import { ConnectedSwitch } from 'components/lib/Form'

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  [theme.breakpoints.up('lg')]: {
    justifyContent: 'center'
  }
}))

const StyledFormControlLabel = styled(FormControlLabel)<FormControlLabelProps>(
  ({ theme }) => ({
    '& .MuiFormControlLabel-label': {
      display: 'flex',
      alignItems: 'center'
    },
    '& .MuiFormControlLabel-root': {
      [theme.breakpoints.up('lg')]: {
        marginRight: 0
      }
    }
  })
)

const LabelText = styled('span')(() => ({
  marginRight: '5px'
}))

interface ToggleProps extends SwitchProps {
  field?: FieldState<boolean>
  setField?: Dispatch<FieldAction<boolean>>
  controlled?: boolean
  label: string
  tooltipText: string
  name: string
  disabled: boolean
}

export const Toggle: React.FC<ToggleProps> = ({
  field,
  setField,
  controlled = false,
  label,
  tooltipText,
  name,
  disabled,
  color = 'secondary',
  ...muiSwitchProps
}) => {
  const toggleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setField && setField({ action: 'value', value: event.target.checked })
  }

  return (
    <Root>
      <StyledFormControlLabel
        control={
          controlled ? (
            <ConnectedSwitch
              name={name}
              disabled={disabled}
              color={color}
              data-cy={`${name}-switch`}
              {...muiSwitchProps}
            />
          ) : (
            <Switch
              disabled={disabled}
              color='secondary'
              data-cy={`${name}-switch`}
              checked={field?.value}
              onChange={toggleChecked}
              {...muiSwitchProps}
            />
          )
        }
        label={
          <>
            <LabelText>{field?.label || label}</LabelText>
            <Tooltip title={tooltipText}>
              <Help fontSize='small' />
            </Tooltip>
          </>
        }
      />
    </Root>
  )
}

export default Toggle
