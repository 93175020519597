import { TScreenedEntity } from 'lib/features/Screening'

type TScreenStats = {
  numFlagged: number
  numResolved: number
  numScreened: number
}

/**
 * screenedEntitiesStats iterates through the screened entities of a screen to determine the number
 * of entities screened, flagged, and resolved.
 *
 * @param {TScreenedEntity[]} screenedEntities - The screen to analyze.
 * @returns {TScreenStats}
 */
export const screenedEntitiesStats = (
  screenedEntities: TScreenedEntity[] = []
): TScreenStats => {
  let numFlagged = 0
  let numResolved = 0

  screenedEntities.forEach((screenedEntity) => {
    if (screenedEntity.totalHits > 0) {
      numFlagged++
    }

    if (screenedEntity.status === 'resolved') {
      numResolved++
    }
  })

  return { numFlagged, numResolved, numScreened: screenedEntities.length }
}
