/**
 * The column of results displayed if a batch of screens has been submitted and it has at least 1 match
 */

import React from 'react'

import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import ListItemButton from '@mui/material/ListItemButton'

import ListItemText from '@mui/material/ListItemText'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'

import Launch from '@mui/icons-material/Launch'

import { QueryChip } from 'components/lib/Query'
import { AuditButton } from 'components/lib/AuditTrail'
import { TScreenedEntity } from 'lib/features/Screening'

const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'status',
  label: 'ListItemButton'
})<{ status: string }>(({ status, theme }) => {
  let bgColor = 'white'

  switch (status) {
    case 'flagged':
      bgColor = theme.palette.error.light
      break
    case 'resolved':
      bgColor = theme.palette.success.light
      break
    case 'wip':
      bgColor = theme.palette.warning.light
      break
  }

  return {
    backgroundColor: `${bgColor}`,
    height: '80px'
  }
})

type TScreenedEntitiesListItem = {
  idx: number
  screenEntity: TScreenedEntity
  selected?: number
  style: React.CSSProperties
  onSelect: (idx: number | null) => void
  onAudit: () => void
  onWorkflow: () => void
}

const ScreenedEntitiesListItem: React.FC<TScreenedEntitiesListItem> = ({
  idx,
  screenEntity,
  selected = 0,
  style = {},
  onSelect,
  onAudit,
  onWorkflow
}) => {
  const isPersisted = 'id' in screenEntity
  const handleSelect: React.MouseEventHandler<HTMLDivElement> = () => {
    onSelect(idx)
  }

  const handleAudit = () => {
    onSelect(idx)
    onAudit()
  }

  const handleWorkflow = () => {
    onSelect(idx)
    onWorkflow()
  }

  return (
    <div style={style}>
      <StyledListItemButton
        divider
        status={screenEntity.status}
        selected={selected === idx}
        onClick={handleSelect}
        data-cy='query-list-item'
      >
        <ListItemText
          primary={<QueryChip screenEntity={screenEntity} />}
          secondary={`${screenEntity.totalHits} Hits`}
        />

        <ListItemSecondaryAction>
          <AuditButton
            data-cy='audit-button'
            openAudit={handleAudit}
            disabled={!isPersisted}
          />
          <IconButton
            data-cy='workflow-button'
            onClick={handleWorkflow}
            size='large'
          >
            <Launch />
          </IconButton>
        </ListItemSecondaryAction>
      </StyledListItemButton>
    </div>
  )
}

export default ScreenedEntitiesListItem
