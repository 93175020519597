import { useGRPC } from 'lib/hooks'

import { GetAuditReportRequest, GetAuditReportResponse } from 'proto-js'
import { TAuditTrailDownloadParams } from 'lib/features/audit'
import ProtoTimestamp from 'google-protobuf/google/protobuf/timestamp_pb.js'

export const prepareRPCRequest = (
  params: TAuditTrailDownloadParams
): typeof GetAuditReportRequest => {
  const request = new GetAuditReportRequest()
  request.setName(params.name)
  request.setIdNumber(params.idNumber)

  if (params.startDate) {
    request.setStartDate(ProtoTimestamp.Timestamp.fromDate(params.startDate))
  }

  if (params.endDate) {
    request.setEndDate(ProtoTimestamp.Timestamp.fromDate(params.endDate))
  }

  return request
}

export const prepareRPCResponse = (
  resp: typeof GetAuditReportResponse
): BlobPart[] => {
  const res = resp.getFile()

  return [res.getBytes()]
}

export type TGetAuditReportResponse = ReturnType<typeof prepareRPCResponse>

type TGetAuditReportRPC = (
  ...args: Parameters<typeof prepareRPCRequest>
) => Promise<ReturnType<typeof prepareRPCResponse>>

/**
 * useGetAuditReportRPC provides an RPC to fetch the excel file for an audit
 * trail search.
 *
 * @returns {TGetAuditReportRPC} - An async function to invoke the RPC.
 */
export const useGetAuditReportRPC = (): TGetAuditReportRPC => {
  const { workflowPromiseService } = useGRPC()

  return async (...args: Parameters<typeof prepareRPCRequest>) => {
    return workflowPromiseService
      .getAuditReport(prepareRPCRequest(...args), {})
      .then(prepareRPCResponse)
  }
}
