import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import {
  useGetScheduledJobRPC,
  TGetScheduledJobResponse
} from 'lib/hooks/api/useGetScheduledJobRPC'
import { jobQueryKeys } from './queryKeys'

type TQueryOptions = Omit<
  UseQueryOptions<
    TGetScheduledJobResponse,
    (string | Record<string, number>)[],
    TGetScheduledJobResponse,
    (string | Record<string, number>)[]
  >,
  'queryKey' | 'queryFn'
>

/**
 * useGetScheduledJob is a hook for querying information about a scheduled job by ID.
 *
 * @param {number} jobID - The job ID to query.
 * @param {TQueryOptions} options - react-query query options.
 * @returns {UseQueryResult<TGetScheduledJobResponse>} - The query result..
 */
export const useGetScheduledJob = (
  jobID: number,
  options?: TQueryOptions
): UseQueryResult<TGetScheduledJobResponse> => {
  const rpc = useGetScheduledJobRPC()
  return useQuery(jobQueryKeys.job(jobID), () => rpc({ jobID: jobID }), {
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  })
}
