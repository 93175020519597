import React from 'react'
import { addDays, format } from 'date-fns'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Checkbox from '@mui/material/Checkbox'

import { TCRONExpression } from 'lib/features/job'

const DAYS_IN_A_WEEK = 7

// This is an arbitrarily chosen date that falls on a Sunday. It is used in this module to loop
// through the days of an arbitrary week and get the names of the days of the week via a date/time
// library.
const RANDOM_SUNDAY = new Date(2022, 10, 6)

type TWeeklyProps = {
  cron: TCRONExpression
  onChange: (cron: TCRONExpression) => void
}

const Weekly = (props: TWeeklyProps): JSX.Element => {
  const [days, setDays] = React.useState<Set<string>>(new Set([]))

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDays = new Set(days)
    if (event.target.checked) {
      newDays.add(event.target.value)
    } else {
      newDays.delete(event.target.value)
    }
    setDays(newDays)
    const dayOfWeekStr = newDays.size === 0 ? '*' : [...newDays].join(',')
    props.onChange({
      ...props.cron,
      dayOfMonth: '*',
      month: '*',
      dayOfWeek: dayOfWeekStr
    })
  }

  const checkboxes = Array(DAYS_IN_A_WEEK)
    .fill(0)
    .map((_, i) => (
      <Grid item xs={1} key={i}>
        <FormControlLabel
          key={i}
          control={
            <Checkbox
              checked={days.has(i.toString())}
              onChange={handleSelect}
              value={i}
            />
          }
          label={format(addDays(RANDOM_SUNDAY, i), 'EEEE')}
        />
      </Grid>
    ))

  return (
    <FormControl>
      <FormGroup row>
        <Grid container columns={{ xs: 2, sm: 4 }}>
          {checkboxes}
        </Grid>
      </FormGroup>
    </FormControl>
  )
}

export default Weekly
