/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import * as grpcWeb from 'grpc-web'
import { OAuthError } from '@auth0/auth0-react'
import {
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions
} from '@auth0/auth0-spa-js'
import { TNotificationDispatch } from 'lib/notification'
import { trackEvent } from 'lib/analytics/ga4'
import { Honeybadger } from '@honeybadger-io/react'

const ERROR_MESSAGE =
  'We had an issue processing your request.  Please try again later.'

const logHoneyBadgerError = (err: grpcWeb.Error) => {
  const additionalContext: any = {}
  let message = err.message
  const match = message.match(/row ([0-9]+)/)

  if (match) {
    additionalContext['rowNumber'] = match[1]
    message = message.replace(/row [0-9]+/, 'row X')
  }

  Honeybadger.notify(message, {
    name: message,
    fingerprint: message,
    ...additionalContext
  })
}

const logHoneyBadgerStreamingError = (err: any) => {
  Honeybadger.notify(err, {
    name: err.error,
    fingerprint: err.error
  })
}

/** Class representing a unary interceptor for authorization */
export class UnaryAuthInterceptor {
  getToken: (options?: GetTokenSilentlyOptions) => Promise<string>
  login: () => Promise<void>
  getTokenWithPopup: (options?: GetTokenWithPopupOptions) => Promise<string>
  setNotification: TNotificationDispatch

  /**
   * Create a unary interceptor for authorization
   *
   * @param {() => Promise<T>} getToken - Function to get access tokens from Auth0
   * @param {() => Promise<T>} login - Function to redirect to login page
   */
  constructor(
    getToken: (options?: GetTokenSilentlyOptions) => Promise<string>,
    login: () => Promise<void>,
    getTokenWithPopup: (options?: GetTokenWithPopupOptions) => Promise<string>,
    setNotification: TNotificationDispatch
  ) {
    this.getToken = getToken
    this.login = login
    this.getTokenWithPopup = getTokenWithPopup
    this.setNotification = setNotification
  }

  /**
   * Intercept unary RPC calls and append an authorization token to the request metadata
   *
   * @async
   * @param request
   * @param invoker
   */
  async intercept(request: any, invoker: any): Promise<string> {
    return this.getToken({
      audience: process.env.REACT_APP_AUTH_AUDIENCE
    })
      .catch(async (err: OAuthError) => {
        if (err.error === 'login_required') {
          // This error will be thrown by the getToken request when a user is not signed
          // in to Auth0.  Given we now allow anonymous users access, in that case we
          // return an empty token, which we use to determine whether to set the
          // authorization headers or not.
          return ''
        } else {
          if (err.error === 'consent_required') {
            await this.getTokenWithPopup({
              audience: process.env.REACT_APP_AUTH_AUDIENCE
            })
          }

          logHoneyBadgerError(err)
          throw err
        }
      })
      .then((token: string) => {
        if (token !== '') {
          const metadata = request.getMetadata()
          metadata.authorization = `Bearer ${token}`
        }

        return invoker(request)
      })
      .catch((err: grpcWeb.Error) => {
        trackEvent('exception', {
          description: err.message
        })
        this.setNotification({
          message: ERROR_MESSAGE,
          variant: 'error'
        })

        logHoneyBadgerError(err)
        throw new Error(err.message)
      })
  }
}

/** Class representing an intercepted stream */
class StreamAuthInterceptedStream {
  stream: any
  /**
   * Create an streaming intercepted stream for authorization
   *
   * @param stream
   */
  constructor(stream: any) {
    this.stream = stream
  }

  on(eventType: any, callback: any) {
    this.stream.on(eventType, callback)
  }

  cancel() {
    this.stream.cancel()
  }
}

/** Class representing a streaming interceptor for authorization */
export class StreamAuthInterceptor {
  getToken: (options?: GetTokenSilentlyOptions) => Promise<string>
  login: () => Promise<void>
  setNotification: TNotificationDispatch

  /**
   * Create a streaming interceptor for authorization
   *
   * @param {() => Promise<T>} getToken - Function to get access tokens from Auth0
   * @param {() => Promise<T>} login - Function to redirect to login page
   */
  constructor(
    getToken: (options?: GetTokenSilentlyOptions) => Promise<string>,
    login: () => Promise<void>,
    setNotification: TNotificationDispatch
  ) {
    this.getToken = getToken
    this.login = login
    this.setNotification = setNotification
  }

  /**
   * This means we're returning a promise here which might be unexpected.
   * Intercept streaming RPC calls and append an authorization token to the request
   * metadata.
   *
   * This function is async which means that even callback based gRPC client will return
   * a Promise as opposed to a stream object.
   *
   * @async
   * @param request
   * @param invoker
   */
  async intercept(
    request: any,
    invoker: any
  ): Promise<StreamAuthInterceptedStream> {
    let token
    const metadata = request.getMetadata()

    try {
      token = await this.getToken({
        audience: process.env.REACT_APP_AUTH_AUDIENCE
      })

      metadata.authorization = `Bearer ${token}`
    } catch (err: any) {
      if (err.error !== 'login_required') {
        trackEvent('exception', {
          description: err.error
        })
        this.setNotification({
          message: ERROR_MESSAGE,
          variant: 'error'
        })

        logHoneyBadgerStreamingError(err)

        throw err
      }
    }

    return new StreamAuthInterceptedStream(invoker(request))
  }
}
