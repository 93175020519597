import { useGRPC } from 'lib/hooks'

import {
  GetListEntriesDataRequest,
  GetListEntriesDataResponse,
  ListEntry
} from 'proto-js'

export type TGetListEntriesDataResponse = {
  totalListEntries: number
  entries: typeof ListEntry[]
}

export type TGetListEntriesDataRequest = {
  queryName: string
}

export const prepareRPCRequest = ({
  queryName
}: TGetListEntriesDataRequest): typeof GetListEntriesDataRequest => {
  const rpcRequest = new GetListEntriesDataRequest()

  rpcRequest.setQueryName(queryName)

  return rpcRequest
}

export const prepareRPCResponse = (
  response: typeof GetListEntriesDataResponse
): TGetListEntriesDataResponse => {
  return {
    totalListEntries: response.getTotalListEntries(),
    entries: response.getListEntriesList()
  }
}

type TGetListEntriesDataRPC = (
  ...args: Parameters<typeof prepareRPCRequest>
) => Promise<ReturnType<typeof prepareRPCResponse>>

/**
 * useGetListEntriesDataRPC provides an RPC to fetch report data by queryName.
 *
 * @returns {TGetListEntriesDataRPC} - An async function to invoke the RPC.
 */
export const useGetListEntriesDataRPC = (): TGetListEntriesDataRPC => {
  const { searchPromiseService } = useGRPC()

  return async (...args: Parameters<typeof prepareRPCRequest>) => {
    return searchPromiseService
      .getListEntriesData(prepareRPCRequest(...args), {})
      .then(prepareRPCResponse)
  }
}
