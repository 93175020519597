import React from 'react'

import { styled } from '@mui/material/styles'
import { Link as RouterLink } from 'react-router-dom'

import Collapse from '@mui/material/Collapse'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Chip from '@mui/material/Chip'
import {
  CompleteIcon,
  IncompleteIcon,
  ScreenedIcon
} from 'components/App/ScheduledScreeningPage/lib/Icons'
import { ContentWithLoader } from 'components/lib/Loader'
import { TSchedule, useGetJobScreens } from 'lib/features/job'
import { TPersistedScreen } from 'lib/features/Screening'
import { formatDate, getNextScreenDate } from 'lib/utils'

const INITIAL_DATA_HEIGHT = 80

const StatusChip = styled(Chip)(() => ({
  minWidth: 80
}))

const StyledTableCell = styled(TableCell)(() => ({
  width: '30%'
}))

const ScreenRow = styled(TableRow)(() => ({
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

const CompletedScreenRow: React.FC<{
  index: number
  row: TPersistedScreen
}> = ({ index, row }) => {
  return (
    <ScreenRow>
      <StyledTableCell align='right'>
        {index === 0 && <StatusChip label='Latest' />}
      </StyledTableCell>
      <StyledTableCell>
        <ScreenedIcon screenedAt={formatDate(row.ranAt)} />
      </StyledTableCell>
      <StyledTableCell>
        {row.reviewCompleted ? <CompleteIcon /> : <IncompleteIcon />}
      </StyledTableCell>
      <TableCell>
        <Chip
          clickable
          label='Review'
          component={RouterLink}
          to={`/jobs/${row.jobID}/screens/${row.id}`}
        />
      </TableCell>
    </ScreenRow>
  )
}

const NextScreenRow: React.FC<{
  schedule: TSchedule
}> = ({ schedule }) => {
  const nextScreenDate = getNextScreenDate(schedule.cron, schedule.timezone)

  return (
    <TableRow>
      <StyledTableCell align='right'>
        <StatusChip color='secondary' label='Next' />
      </StyledTableCell>
      <StyledTableCell>
        <ScreenedIcon screenedAt={formatDate(nextScreenDate)} />
      </StyledTableCell>
      <StyledTableCell></StyledTableCell>
      <TableCell></TableCell>
    </TableRow>
  )
}

const ScreenTableBody: React.FC<{
  data?: TPersistedScreen[]
  schedule: TSchedule
}> = ({ data, schedule }) => {
  return (
    <Table size='small' aria-label='screens-table'>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>Screened:</TableCell>
          <TableCell>Review Status:</TableCell>
          <TableCell>Results</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <NextScreenRow schedule={schedule} />
        {data?.map((row, i) => (
          <CompletedScreenRow key={i} index={i} row={row} />
        ))}
      </TableBody>
    </Table>
  )
}

const ScreensTable: React.FC<{
  jobID: number
  neverRun: boolean
  schedule: TSchedule
}> = ({ jobID, neverRun, schedule }) => {
  const { data, isLoading } = useGetJobScreens(
    jobID,
    5,
    { enabled: !neverRun } // Don't fetch data if the job never ran
  )

  const screens = data?.screens

  return (
    <Collapse
      in={screens && screens.length > 0}
      collapsedSize={INITIAL_DATA_HEIGHT}
    >
      <ContentWithLoader
        showLoader={!neverRun}
        data={screens}
        isFetching={isLoading}
      >
        <ScreenTableBody data={screens} schedule={schedule} />
      </ContentWithLoader>
    </Collapse>
  )
}

export default ScreensTable
