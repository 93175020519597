import React from 'react'

import { styled } from '@mui/material/styles'

import { withBreadcrumbs } from 'components/lib/Breadcrumbs'
import SearchForm, { TSearchFormParams } from './SearchForm'
import { TAuditTrailSearchParams } from 'lib/features/audit'
import SearchResults from './SearchResults'
import { FIRST_PAGE, DEFAULT_PAGE_SIZE } from './ResultsTable/PaginationActions'

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '0px auto'
}))

const AuditTrailPage: React.FC = () => {
  const [params, setParams] = React.useState<TAuditTrailSearchParams>({
    name: '',
    idNumber: '',
    startDate: null,
    endDate: null,
    pageNumber: FIRST_PAGE,
    pageSize: DEFAULT_PAGE_SIZE
  })

  const handleFilter = (searchParams: TSearchFormParams) => {
    setParams({
      ...params,
      ...searchParams,
      pageNumber: FIRST_PAGE
    })
  }

  const setPageNumber = (pageNumber: number) => {
    setParams({
      ...params,
      pageNumber: pageNumber
    })
  }

  const setPageSize = (pageSize: number) => {
    setParams({
      ...params,
      pageSize: pageSize
    })
  }

  return (
    <Root>
      <SearchForm onFilterClick={handleFilter} />
      <SearchResults
        params={params}
        setPageSize={setPageSize}
        setPageNumber={setPageNumber}
      />
    </Root>
  )
}

export default withBreadcrumbs(AuditTrailPage)
