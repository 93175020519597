/**
 * This module provides tools for reporting to Google Anlytics 4. It uses the react-ga4 package.
 */
import GA4 from 'react-ga4'

// GA4 needs to be initialized before it is used anywhere else in the codebase.
if (process.env.REACT_APP_GA_ID !== undefined) {
  GA4.initialize(process.env.REACT_APP_GA_ID)
}

type TScreenEvent = Parameters<
  (
    name: 'screen_triggered',
    params:
      | {
          score_threshold: number
          num_rows: number
          type: 'advanced' | 'batch'
        }
      | Record<string, boolean> // For tracking selected list source categories.
  ) => void
>

type TWorkflowSubmittedEvent = Parameters<(name: 'workflow_submitted') => void>

type TAuditSearchSubmittedEvent = Parameters<
  (name: 'audit_search_submitted') => void
>

type TCreateScheduledJobSubmittedEvent = Parameters<
  (name: 'create_scheduled_job_submitted') => void
>

type TEditScheduledJobSubmittedEvent = Parameters<
  (name: 'edit_scheduled_job_submitted') => void
>

type TDeleteScheduledJobSubmittedEvent = Parameters<
  (name: 'delete_scheduled_job_submitted') => void
>

/**
 * TViewAuditTrailEvent is a special case of the GA4 recommended event'select_content'.
 */
type TViewAuditTrailEvent = Parameters<
  (name: 'select_content', params: { content_type: 'audit' }) => void
>

/**
 * TLoginEvent encapsulates the GA4 recommended event 'login'.
 */
type TLoginEvent = Parameters<(name: 'login') => void>

/**
 * TFileDownloadEvent encapsulates the GA4 recommended event 'file_download'.
 */
type TFileDownloadEvent = Parameters<
  (
    name: 'file_download',
    params: {
      subject: string
      type: 'data' | 'report'
      gen_time?: number
    }
  ) => void
>

/**
 * TExceptionEvent encapsulates the GA4 recommended event 'exception'.
 */
type TExceptionEvent = Parameters<
  (
    name: 'exception',
    params: {
      description: string
      fatal?: boolean
    }
  ) => void
>

/**
 * TGA4Event is a union of all supported event types.
 */
type TGA4Event =
  | TScreenEvent
  | TWorkflowSubmittedEvent
  | TAuditSearchSubmittedEvent
  | TCreateScheduledJobSubmittedEvent
  | TEditScheduledJobSubmittedEvent
  | TDeleteScheduledJobSubmittedEvent
  | TViewAuditTrailEvent
  | TLoginEvent
  | TFileDownloadEvent
  | TExceptionEvent

function computeRole(roles: string[] | undefined) {
  if (roles === undefined) return

  for (let i = 0; i < roles.length; i++) {
    if (roles[i].startsWith('tier_')) {
      return roles[i].substring(5)
    }
  }
}

/**
 * trackUser configures the Google Analytics tag with context about the user.
 *
 * @param internal - If the user is an internal castellum.ai user.
 * @param userID - The user ID from Auth0.
 * @param adverseMedia - If the user has access to adverse media
 * @param token - The access token from Auth0.
 */
export const trackUser = (
  token: Token,
  adverseMedia = false,
  userID: string | undefined,
  internal: boolean
): void => {
  const orgID = token['http://castellum.ai/org_id']
  const roles = token['http://castellum.ai/roles']
  const role = computeRole(roles)

  GA4.set({
    user_id: userID,
    user_properties: {
      org_id: orgID,
      adverse_media: adverseMedia,
      internal,
      role
    }
  })
}

/**
 * trackEvent is a generic function for reporting events to Google Analytics.
 *
 * @param {TGA4Event} args - The event name and parameters.
 */
export const trackEvent = (...args: TGA4Event): void => {
  GA4.event(args[0], args[1])
}
