import React from 'react'
import { HashLink } from 'react-router-hash-link'
import { LinkProps as RouterLinkProps } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import { styled, useTheme } from '@mui/material/styles'
import MenuIcon from '@mui/icons-material/Menu'
import useMediaQuery from '@mui/material/useMediaQuery'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import AppBar from '@mui/material/AppBar'
import Container from '@mui/material/Container'
import Fade from '@mui/material/Fade'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'

import HeaderLink from './HeaderLink'
import HeaderHashLink from './HeaderHashLink'
import { useBranding } from 'lib/hooks'

const PREFIX = 'HeaderHome'

const classes = {
  bar: `${PREFIX}-bar`,
  link: `${PREFIX}-link`,
  logo: `${PREFIX}-logo`,
  button: `${PREFIX}-button`,
  menuItem: `${PREFIX}-menuItem`
}

const SignUpButton = styled(Button)(() => ({
  marginRight: 12
}))

const LogoLink = styled(Link)(({ theme }) => ({
  fontSize: '3em',
  height: '1em',
  color: theme.palette.primary.dark,
  '& Icon': {
    textAlign: 'center'
  },
  '& img': {
    height: '100%'
  },
  '& span': {
    fontSize: '2.25rem'
  }
}))

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderBottom: `1px solid ${theme.palette.divider}`,
  [`& .${classes.bar}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  [`& .${classes.link}`]: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    color: theme.palette.text.primary
  },
  [`& .${classes.button}`]: {
    color: theme.palette.text.primary,
    borderColor: theme.palette.text.primary
  },
  [`& .${classes.menuItem}`]: {
    '& .MuiTypography-root': {
      marginTop: '4px',
      marginBottom: '4px'
    }
  }
}))

interface RouteData {
  component: 'Link' | 'HashLink'
  label: string
  route: string
  visibility: 'public' | 'private'
}

const routes: RouteData[] = [
  {
    component: 'Link',
    label: 'Home',
    route: 'https://www.castellum.ai#why-choose-castellumai',
    visibility: 'public'
  },
  {
    component: 'Link',
    label: 'Knowledge Base',
    route: 'https://support.castellum.ai',
    visibility: 'public'
  },
  {
    component: 'Link',
    label: 'Support',
    route: 'https://support.castellum.ai/hc/en-us/requests/new',
    visibility: 'public'
  }
]

// These should probably be defined elsewhere
const HashLinkRef = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(
  (props, ref) => <HashLink {...props} innerRef={ref} />
)
HashLinkRef.displayName = 'HashLinkRef'

interface Props {
  children: React.ReactElement
  absolute: boolean
}

const ElevationScroll = (props: Props) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  })

  return React.cloneElement(props.children, {
    elevation: trigger && !props.absolute ? 2 : 0
  })
}

export const HeaderHome: React.FC = () => {
  const theme = useTheme()
  const wideDisplay = useMediaQuery(theme.breakpoints.up('md'))
  const landscape = useMediaQuery('(orientation: landscape)')
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const absoluteHeader = landscape && !wideDisplay
  const branding = useBranding()
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0()

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = () => {
    setAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const redirectToLogIn = () => {
    loginWithRedirect({})
  }

  const redirectToLogOut = () => {
    logout({ returnTo: process.env.REACT_APP_SITE_DOMAIN })
  }

  const redirectToSignUp = () => {
    loginWithRedirect({
      appState: { targetUrl: window.location.pathname },
      initialScreen: 'signUp'
    })
  }

  const headerLinks = React.useMemo(() => {
    const items = []
    for (const route of routes) {
      if (route.visibility === 'private' && !isAuthenticated) {
        continue
      }
      if (route.component === 'HashLink') {
        items.push(
          <HeaderHashLink
            key={`menu-${route.label}`}
            className={classes.link}
            to={route.route}
          >
            {route.label}
          </HeaderHashLink>
        )
      } else if (route.component === 'Link') {
        items.push(
          <HeaderLink
            className={classes.link}
            key={`menu-${route.label}`}
            href={route.route}
          >
            {route.label}
          </HeaderLink>
        )
      }
    }

    return items
  }, [classes.link, isAuthenticated])

  const menuItems = React.useMemo(() => {
    let items = []
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].visibility === 'private' && !isAuthenticated) {
        continue
      }
      if (routes[i].component === 'HashLink') {
        items.push(
          <MenuItem
            key={`menu-${routes[i].label}`}
            component={HashLinkRef}
            to={routes[i].route}
            onClick={handleMenuItemClick}
            divider={i === routes.length - 1}
            className={classes.menuItem}
          >
            <ListItemText primary={routes[i].label} />
          </MenuItem>
        )
      } else if (routes[i].component === 'Link') {
        items.push(
          <MenuItem
            key={`menu-${routes[i].label}`}
            component={Link}
            href={routes[i].route}
            onClick={handleMenuItemClick}
            divider={i === routes.length - 1}
            className={classes.menuItem}
          >
            <ListItemText primary={routes[i].label} />
          </MenuItem>
        )
      }
    }

    if (isAuthenticated) {
      items.push(
        <MenuItem key='menu-logout' className={classes.menuItem}>
          <ListItemText primary='Log Out' onClick={redirectToLogOut} />
        </MenuItem>
      )
    } else {
      items = items.concat([
        <MenuItem key='menu-signup' className={classes.menuItem}>
          <ListItemText primary='Sign Up' onClick={redirectToSignUp} />
        </MenuItem>,
        <MenuItem key='menu-login' className={classes.menuItem}>
          <ListItemText primary='Log In' onClick={redirectToLogIn} />
        </MenuItem>
      ])
    }

    return items
  }, [isAuthenticated, loginWithRedirect, logout])

  return (
    <ElevationScroll absolute={absoluteHeader}>
      <StyledAppBar
        elevation={0}
        position={absoluteHeader ? 'absolute' : 'fixed'}
      >
        <Toolbar>
          <Container maxWidth='xl' className={classes.bar}>
            <LogoLink href='https://www.castellum.ai#why-choose-castellumai'>
              {branding.isSuccess && (
                <img
                  src={`${process.env.REACT_APP_CDN_PATH}/${branding.data.logoURI}`}
                  alt='logo'
                />
              )}
            </LogoLink>

            {wideDisplay && (
              <>
                <div>{headerLinks}</div>
                <div>
                  {!isAuthenticated && (
                    <>
                      <SignUpButton
                        variant='contained'
                        color='secondary'
                        size='small'
                        onClick={redirectToSignUp}
                      >
                        Sign Up
                      </SignUpButton>
                      <Button
                        variant='contained'
                        color='secondary'
                        size='small'
                        onClick={redirectToLogIn}
                      >
                        Log In
                      </Button>
                    </>
                  )}

                  {isAuthenticated && (
                    <Button
                      variant='contained'
                      color='secondary'
                      size='small'
                      onClick={redirectToLogOut}
                    >
                      Log Out
                    </Button>
                  )}
                </div>
              </>
            )}

            {!wideDisplay && (
              <>
                <IconButton
                  className={classes.button}
                  onClick={handleMenuClick}
                  size='large'
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  TransitionComponent={Fade}
                  TransitionProps={{
                    timeout: 200
                  }}
                  MenuListProps={{
                    disablePadding: true
                  }}
                >
                  {menuItems}
                </Menu>
              </>
            )}
          </Container>
        </Toolbar>
      </StyledAppBar>
    </ElevationScroll>
  )
}
