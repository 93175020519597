import React from 'react'

import GenerateReportButton, { ButtonProps } from './GenerateReportButton'
import ExportButton from './ExportButton'
import { SupportDialog } from 'components/lib/SupportDialog'

import { Can } from 'lib/authorization/authorizationContext'

export const ReportButton: React.FC<ButtonProps> = ({
  screenData,
  disabled = false,
  fullWidth = false
}) => {
  return (
    <>
      <Can I='view' a='PDF'>
        {() => (
          <GenerateReportButton
            screenData={screenData}
            disabled={disabled}
            fullWidth={fullWidth}
          />
        )}
      </Can>
      <Can not I='view' a='PDF'>
        {() => (
          <SupportDialog
            buttonComponent={(onClick) => (
              <ExportButton fullWidth={fullWidth} onClick={onClick} />
            )}
          >
            <span>
              Upgrade to premium{' '}
              <a
                href='https://www.castellum.ai/pricing'
                rel='noreferrer'
                target='_blank'
              >
                here
              </a>{' '}
              to access this feature, see sample report{' '}
              <a
                href='https://www.castellum.ai/products/investigator'
                target='_blank'
                rel='noopener noreferrer'
              >
                here
              </a>
              .
            </span>
          </SupportDialog>
        )}
      </Can>
    </>
  )
}

export default ReportButton
