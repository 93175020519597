import { useGRPC } from 'lib/hooks'
import {
  GetScreenDataRequest,
  GetScreenDataResponse,
  HitRecord,
  ScreenedEntityData
} from 'proto-js'
import {
  TPersistedScreenData,
  TPersistedScreenedEntity
} from 'lib/features/Screening'
import { replyAuditStatus } from 'lib/helpers/reply'
import { screenFromProtobuf, jobFromProtobuf } from './utils'

export type TGetScreenDataRequest = {
  screenID: number
}

export const prepareRPCRequest = (
  request: TGetScreenDataRequest
): typeof GetScreenDataRequest => {
  const rpcRequest = new GetScreenDataRequest()
  rpcRequest.setScreenId(request.screenID)
  return rpcRequest
}

export type TGetScreenDataResponse = TPersistedScreenData

const getScreenedEntitiesDataFromResponse = (
  screenedEntitiesDataPB: Array<typeof ScreenedEntityData>
) => {
  return screenedEntitiesDataPB.map((screenedEntityDataPB) => {
    const screenedEntityPB = screenedEntityDataPB.getScreenedEntity()
    const hitsPB = screenedEntityDataPB.getHitsList()
    const name = screenedEntityPB.getName()
    const idNumber = screenedEntityPB.getIdNumber()
    const location = screenedEntityPB.getLocation()
    const dob = screenedEntityPB.getDob()
    const referenceId = screenedEntityPB.getReferenceId()
    const screenedEntity: TPersistedScreenedEntity = {
      id: screenedEntityPB.getId(),
      ...(name && { name: name }),
      ...(idNumber && { idNumber: idNumber }),
      ...(location && { location: location }),
      ...(dob && { dob: dob }),
      ...(referenceId && { referenceId: referenceId }),
      totalHits: screenedEntityPB.getTotalHits(),
      status: 'incomplete', // Temporary set to incomplete but update via replyAuditStatus after
      hits: hitsPB.map((hitRecordPB: typeof HitRecord) => {
        return {
          id: hitRecordPB.getHitId(),
          classification: hitRecordPB.getClassification(),
          protobuf: hitRecordPB.getHit()
        }
      })
    }
    screenedEntity.status = replyAuditStatus(screenedEntity)
    return screenedEntity
  })
}

export const prepareRPCResponse = (
  resp: typeof GetScreenDataResponse
): TGetScreenDataResponse => {
  const screenDataPB = resp.getScreenData()
  return {
    screen: screenFromProtobuf(screenDataPB.getScreen()),
    job: jobFromProtobuf(screenDataPB.getJob()),
    entities: getScreenedEntitiesDataFromResponse(
      screenDataPB.getScreenedEntitiesDataList()
    )
  }
}

type TGetScreenDataRPC = (
  params: TGetScreenDataRequest
) => Promise<TGetScreenDataResponse>

/**
 * useGetScreenDataRPC provides an async function to invoke the GetScreenData RPC.
 *
 * @returns {TGetScheduledJobRPC} - An async function to invoke the RPC.
 */
export const useGetScreenDataRPC = (): TGetScreenDataRPC => {
  const { workflowPromiseService } = useGRPC()
  return async (params: TGetScreenDataRequest) => {
    return workflowPromiseService
      .getScreenData(prepareRPCRequest(params), {})
      .then(prepareRPCResponse)
  }
}
