import React from 'react'
import { TBrandingQuery } from 'lib/hooks/useBranding'
import { StyleSheet, Image, Text, View } from '@react-pdf/renderer'
import { User } from '@auth0/auth0-spa-js'

const styles = StyleSheet.create({
  subHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 25,
    fontWeight: 300
  },
  table: {
    display: 'flex',
    flexDirection: 'row'
  },
  tableColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  titleImage: {
    height: 35,
    position: 'absolute',
    left: 0,
    top: 5
  },
  title: {
    fontSize: 38,
    marginBottom: 15,
    textAlign: 'center',
    fontFamily: 'Roboto'
  }
})

const timeOptions = {
  hour: '2-digit',
  minute: '2-digit',
  timeZoneName: 'short'
}

export const Subheader: React.FC<{ title: string; user: User | undefined }> = ({
  title,
  user
}) => {
  const dateString = new Date().toLocaleTimeString([], timeOptions)

  return (
    <View fixed style={styles.subHeader}>
      <View style={styles.table}>
        <View style={styles.tableColumn}>
          <Text style={styles.tableCell}>User: </Text>
        </View>
        <View style={styles.tableColumn}>
          <Text style={styles.tableCell}>{user && user.email}</Text>
        </View>
      </View>

      <View style={styles.table}>
        <View style={[styles.tableColumn, { alignItems: 'flex-end' }]}>
          <Text style={styles.tableCell}>{title}</Text>
          <Text style={styles.tableCell}>{dateString}</Text>
        </View>
      </View>
    </View>
  )
}

interface IHeaderProps {
  branding: TBrandingQuery
}

const Header: React.FC<IHeaderProps> = ({ branding }) => {
  const companyName = branding.isSuccess ? branding.data.company : ''
  return (
    <View fixed>
      <Text style={styles.title}>{companyName.toUpperCase()}</Text>
      {branding.isSuccess && (
        <Image
          style={styles.titleImage}
          src={`${process.env.REACT_APP_CDN_PATH}/${branding.data.logoURI}?x-request=pdf`}
        />
      )}
    </View>
  )
}

export default Header
