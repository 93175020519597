import { useQuery, UseQueryResult } from 'react-query'
import { ICountry } from 'types'
import { useCountriesDataRPC } from 'lib/hooks/api/useCountriesDataRPC'

/**
 * Hook for fetching and caching countries data via react-query.
 *
 * @returns {QueryResult<TResp>}
 */
export const useCountriesData = (): UseQueryResult<ICountry[], Error> => {
  const rpc = useCountriesDataRPC()
  return useQuery('countriesData', rpc, {
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 300000
  })
}

export default useCountriesData
