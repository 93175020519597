import React from 'react'
import { useTheme } from '@mui/material/styles'
import Paper from '@mui/material/Paper'

const ContentPane: React.FC = ({ children }) => {
  const theme = useTheme()
  return (
    <Paper
      elevation={4}
      sx={{ padding: '40px', marginBottom: theme.spacing(3) }}
    >
      {children}
    </Paper>
  )
}

export default ContentPane
