import React from 'react'

import Button, { ButtonProps } from '@mui/material/Button'
import { SupportDialog } from 'components/lib/SupportDialog'
import { trackEvent } from 'lib/analytics/ga4'

import { Can } from 'lib/authorization'

export interface AuditButtonProps extends ButtonProps {
  openAudit?: React.MouseEventHandler<HTMLButtonElement | undefined>
}

export const AuditButton: React.FC<AuditButtonProps> = ({
  openAudit,
  ...buttonProps
}) => {
  const handleOpenAudit = (
    e: React.MouseEvent<HTMLButtonElement | undefined, MouseEvent>
  ) => {
    if (openAudit) {
      trackEvent('select_content', { content_type: 'audit' })
      openAudit(e)
    }
  }

  return (
    <Can I='view' a='AuditTrail' passThrough>
      {(allowed: boolean) => (
        <SupportDialog
          buttonComponent={(openModal) => (
            <Button
              onClick={allowed ? handleOpenAudit : openModal}
              {...buttonProps}
            >
              Audit
            </Button>
          )}
        />
      )}
    </Can>
  )
}

export default AuditButton
