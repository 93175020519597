import React from 'react'
import { styled } from '@mui/material/styles'
import { HashLink } from 'react-router-hash-link'
import { LinkProps as RouterLinkProps } from 'react-router-dom'
import Button, { ButtonProps } from '@mui/material/Button'

const HashLinkRef = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(
  (props, ref) => <HashLink {...props} innerRef={ref} />
)
HashLinkRef.displayName = 'HashLinkRef'

const PREFIX = 'HeaderHashLink'

const classes = {
  root: `${PREFIX}-root`,
  label: `${PREFIX}-label`
}

type StyledProps = {
  component: typeof HashLinkRef
}

const StyledButton = styled(Button)(({ theme }) => ({
  [`& .${classes.root}`]: {
    position: 'relative',
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.dark,
      backgroundColor: 'transparent'
    },
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '2px',
      bottom: 0,
      left: 0,
      backgroundColor: theme.palette.primary.dark,
      visibility: 'hidden',
      WebkitTransform: 'scaleX(0)',
      transform: 'scaleX(0)',
      WebkitTransition: 'all 0.3s ease-in-out 0s',
      transition: 'all 0.3s ease-in-out 0s'
    },
    '&:hover:before': {
      visibility: 'visible',
      WebkitTransform: 'scaleX(1)',
      transform: 'scaleX(1)'
    }
  },
  [`& .${classes.label}`]: {
    textTransform: 'capitalize'
  }
})) as typeof Button

const CustomButton: React.FC<ButtonProps<typeof HashLinkRef>> = (
  props: ButtonProps<typeof HashLinkRef>
) => <StyledButton {...props} component={HashLinkRef} />

const HeaderHashLink = CustomButton

export default HeaderHashLink
