import React from 'react'

import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'

import Spinner from 'components/lib/Spinner'
import ResultsList from './ResultsList'
import { ReportButton } from 'components/lib/Reporting'
import SaveScreenButton from './SaveScreenButton'
import { NoMatches } from 'components/lib/Search'
import { TAdHocScreenData } from 'lib/features/Screening'

const Root = styled('div')(() => ({
  marginTop: 40,
  width: '100%'
}))

const ButtonsGridContainer = styled(Grid)(() => ({
  paddingTop: 15,
  justifyContent: 'center',
  gap: '20px 32px'
}))

const ButtonGridItem = styled(Grid)(() => ({
  width: 200
}))

export type SearchResultsProps = {
  screenData: TAdHocScreenData | undefined
  selected?: number
  onSelect: (idx: number | null) => void
  listSourceQueryString: string
  isSuccess: boolean
}

const SearchResults: React.FC<SearchResultsProps> = ({
  screenData,
  onSelect,
  listSourceQueryString,
  selected,
  isSuccess
}) => {
  let component = <Spinner />

  if (isSuccess && screenData !== undefined) {
    component =
      screenData.entities[0].hits.length === 0 ? (
        <>
          <NoMatches />
          <ButtonsGridContainer container>
            <ButtonGridItem item>
              <SaveScreenButton
                screenData={screenData}
                screenedEntityIndex={0}
                variant='contained'
                fullWidth
                color='grey'
              />
            </ButtonGridItem>
            <ButtonGridItem item>
              <ReportButton screenData={screenData} fullWidth />
            </ButtonGridItem>
          </ButtonsGridContainer>
        </>
      ) : (
        <ResultsList
          screenData={screenData}
          reportButton={<ReportButton screenData={screenData} />}
          selected={selected}
          onSelect={onSelect}
          listSourceQueryString={listSourceQueryString}
        />
      )
  }

  return <Root data-cy='search-results-container'>{component}</Root>
}

export default SearchResults
