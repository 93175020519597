import React from 'react'
import {
  UnaryAuthInterceptor,
  StreamAuthInterceptor
} from 'lib/gRPC/interceptors'
import { useNotification } from 'lib/notification'

import {
  DataServicePromiseClient,
  SearchServicePromiseClient,
  WorkflowServicePromiseClient
} from 'proto-js'
import { useAuth0 } from '@auth0/auth0-react'

export interface IGRPCContext {
  dataPromiseService: typeof DataServicePromiseClient
  searchPromiseService: typeof SearchServicePromiseClient
  workflowPromiseService: typeof WorkflowServicePromiseClient
}

export const GRPCContext = React.createContext<IGRPCContext | undefined>(
  undefined
)

export const GRPCProvider: React.FC = ({ children }) => {
  const {
    getAccessTokenSilently,
    loginWithRedirect,
    getAccessTokenWithPopup
  } = useAuth0()
  const setNotification = useNotification()

  const [unaryAuthInterceptor] = React.useState(
    new UnaryAuthInterceptor(
      getAccessTokenSilently,
      loginWithRedirect,
      getAccessTokenWithPopup,
      setNotification
    )
  )
  const [streamAuthInterceptor] = React.useState(
    new StreamAuthInterceptor(
      getAccessTokenSilently,
      loginWithRedirect,
      setNotification
    )
  )
  const [dataPromiseService] = React.useState(
    new DataServicePromiseClient(process.env.REACT_APP_PROXY, null, {
      unaryInterceptors: [unaryAuthInterceptor]
    })
  )
  const [searchPromiseService] = React.useState(
    new SearchServicePromiseClient(process.env.REACT_APP_PROXY, null, {
      unaryInterceptors: [unaryAuthInterceptor]
    })
  )
  const [workflowPromiseService] = React.useState(
    new WorkflowServicePromiseClient(process.env.REACT_APP_PROXY, null, {
      unaryInterceptors: [unaryAuthInterceptor],
      streamInterceptors: [streamAuthInterceptor]
    })
  )

  return (
    <GRPCContext.Provider
      value={{
        dataPromiseService: dataPromiseService,
        searchPromiseService: searchPromiseService,
        workflowPromiseService: workflowPromiseService
      }}
    >
      {children}
    </GRPCContext.Provider>
  )
}
