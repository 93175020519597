import {
  TScreenedEntity,
  TPersistedScreenedEntity,
  TScreenedEntityStatus
} from 'lib/features/Screening'

// Logic for resolving a query based on audit and results
// https://castellumai.atlassian.net/browse/PR-155
export function replyAuditStatus(
  screenEntity: TScreenedEntity | TPersistedScreenedEntity
): TScreenedEntityStatus {
  const hits = screenEntity.hits
  let status: TScreenedEntityStatus = 'incomplete'
  let falsePositiveCount = 0
  let hitWithoutAuditExists = false

  for (let i = 0; i < hits.length; i++) {
    const hit = hits[i]
    hitWithoutAuditExists =
      hitWithoutAuditExists || hit.classification === undefined

    if (hit.classification) {
      const classification = hit.classification

      switch (classification) {
        case 'true-positive':
          return 'flagged'
        case undefined:
          status = 'incomplete'
          break
        case 'need-more-info':
          if (status === 'incomplete') {
            status = 'wip'
          }
          break
        case 'false-positive':
          falsePositiveCount++
          break
      }
    }
  }

  if (hits.length === 0) {
    status = 'cleared'
  } else if (falsePositiveCount === hits.length) {
    status = 'resolved'
  } else if (status === 'wip' && hitWithoutAuditExists) {
    status = 'incomplete'
  }

  return status
}
