import React from 'react'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'

import { withBreadcrumbs } from 'components/lib/Breadcrumbs'
import Form from './Form'
import ScreenedEntitiesList from 'components/lib/ScreenedEntitiesList'
import ScreenStats from 'components/lib/ScreenStats'
import WorkflowDialog from 'components/lib/Workflow'
import { useDialog } from 'lib/hooks'
import { AuditTrailDialog } from 'components/lib/AuditTrail'
import { ReportButton } from 'components/lib/Reporting'
import {
  TPersistedScreenedEntity,
  TScreenedEntity,
  useBatchScreening
} from 'lib/features/Screening'
import { screenedEntitiesStats } from 'lib/features/job'
import QueryResults from './QueryResults'
import {
  parseListSourcesOptions,
  TOptionTree
} from 'components/lib/ListSourcesDropDown'
import { useNotification } from 'lib/notification'
import { trackEvent } from 'lib/analytics/ga4'
import {
  FUZZY_DOB_DEFAULT_MONTHS_RANGE,
  DEFAULT_DOB_MONTHS_RANGE
} from 'lib/constants/search'

const PREFIX = 'BatchSearchPage'

const classes = {
  form: `${PREFIX}-form`,
  queries: `${PREFIX}-queries`
}

const Root = styled('div')(() => ({
  margin: '0px auto',
  [`& .${classes.form}`]: {
    width: '100%',
    padding: '20px 40px 20px 40px'
  },
  [`& .${classes.queries}`]: {
    height: '100%',
    flexDirection: 'column'
  }
}))

const BatchSearchPage: React.ComponentType = () => {
  const setNotification = useNotification()

  const batchScreen = useBatchScreening({
    onError: () =>
      setNotification({
        message:
          'We had an issue processing your search. Please try again later.',
        variant: 'error'
      })
  })
  const { query, stream } = batchScreen
  const { data, isSuccess } = query
  const [numRowsEstimate, setNumRowsEstimate] = React.useState(0)
  const [showFileInstructions, setShowFileInstructions] = React.useState(true)
  const [screenedEntityIndex, setScreenedEntityIndex] = React.useState(0)

  const workflowDialog = useDialog()
  const auditTrailDialog = useDialog()

  const handleSelectScreenedEntity = (i: number | null) => {
    if (i !== null) {
      setScreenedEntityIndex(i)
    }
  }

  const handleReset = () => {
    setScreenedEntityIndex(0)
    setShowFileInstructions(true)
    batchScreen.reset()
  }

  const handleScreen = (
    file: File | null,
    listSourcesOptions: TOptionTree,
    scoreThreshold: number,
    weakAKAs: boolean,
    fuzzyDOB: boolean
  ) => {
    const {
      listSourceCategoriesParam,
      listSourcesParam,
      allSelectedListSourceCategories
    } = parseListSourcesOptions(listSourcesOptions)

    if (allSelectedListSourceCategories.length === 0) {
      setNotification({
        variant: 'error',
        message: 'Select at least one List Source'
      })

      return
    }

    handleReset()
    setShowFileInstructions(false)

    if (file === null) {
      return
    }

    // Estimate the number of records in the file.
    // This should be moved to the file validation step.
    //
    // Not clear if it's possible to match on a generic unicode character so here we
    // explicitely look for rows that consist of only spaces, commas, or quotes and
    // skip those rows.
    const reRecord = /^[\s,'"]*$/
    const reader = new FileReader()

    reader.readAsText(file)
    reader.onload = (e) => {
      const result = (e.target?.result || '') as string

      if (e.target === null || result === '') {
        return
      }

      if (e.target.readyState === FileReader.DONE) {
        let nRows = -1 // -1 so as to compensate for the header

        result.split(/\r?\n/).forEach((line) => {
          if (!reRecord.test(line)) {
            nRows++
          }
        })

        trackEvent('screen_triggered', {
          score_threshold: scoreThreshold * 100,
          num_rows: nRows,
          type: 'batch',
          ...Object.fromEntries(
            allSelectedListSourceCategories.map((cat) => [
              `list_source_category_${cat}`,
              true
            ])
          )
        })

        setNumRowsEstimate(nRows)
      }
    }

    const dobMonthsRange = fuzzyDOB
      ? FUZZY_DOB_DEFAULT_MONTHS_RANGE
      : DEFAULT_DOB_MONTHS_RANGE

    batchScreen.invoke({
      scoreThreshold: scoreThreshold,
      weakAKAs: weakAKAs,
      dobMonthsRange: dobMonthsRange,
      listSourceCategories: listSourceCategoriesParam,
      listSources: listSourcesParam,
      file
    })
  }

  // # TODO GET numLines somehow
  let numQueries = numRowsEstimate
  if (stream.isSuccess && data !== undefined) {
    numQueries = data.entities.length
  }

  const { numFlagged, numResolved, numScreened } = screenedEntitiesStats(
    data?.entities
  )

  let screenedEntity: TScreenedEntity | TPersistedScreenedEntity | undefined
  if (isSuccess && data !== undefined) {
    screenedEntity = data.entities[screenedEntityIndex]
  }

  let screenedEntityID: number | undefined
  if (screenedEntity && 'id' in screenedEntity) {
    screenedEntityID = screenedEntity.id
  }

  return (
    <Root>
      <Grid
        container
        spacing={3}
        direction='column'
        justifyContent='flex-start'
        alignItems='stretch'
        wrap='nowrap'
      >
        <Grid item style={{ display: 'flex' }}>
          <Paper className={classes.form} elevation={4}>
            <Grid container>
              <Grid item xs={12}>
                <Form
                  screen={handleScreen}
                  reset={handleReset}
                  isLoading={stream.isFetching}
                  isError={stream.isError}
                  reportButton={
                    <ReportButton
                      fullWidth
                      screenData={data}
                      disabled={stream.isFetching || showFileInstructions}
                    />
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <ScreenStats
                  open={!showFileInstructions}
                  numQueries={numQueries}
                  numScreened={numScreened}
                  numFlagged={numFlagged}
                  numResolved={numResolved}
                  isError={stream.isError}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item className={classes.queries}>
          <QueryResults
            showFileInstructions={showFileInstructions}
            numRows={numRowsEstimate}
            numScreened={numScreened}
            numFlagged={numFlagged}
            queriesList={
              <ScreenedEntitiesList
                exportable={stream.isSuccess && data !== undefined}
                screenEntities={data?.entities ?? []}
                selected={screenedEntityIndex}
                onSelect={handleSelectScreenedEntity}
                onAudit={auditTrailDialog.onOpen}
                onWorkflow={workflowDialog.onOpen}
              />
            }
          />
        </Grid>
      </Grid>

      {isSuccess &&
        data !== undefined &&
        data.entities.length > screenedEntityIndex && (
          <>
            <WorkflowDialog
              {...workflowDialog}
              screenData={data}
              screenedEntityIndex={screenedEntityIndex}
            />

            {screenedEntityID !== undefined && (
              <AuditTrailDialog
                {...auditTrailDialog}
                screenedEntityID={screenedEntityID}
              />
            )}
          </>
        )}
    </Root>
  )
}

export default withBreadcrumbs(BatchSearchPage)
