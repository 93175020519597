import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import {
  useGetJobScreensRPC,
  TGetJobScreensResponse
} from 'lib/hooks/api/useGetJobScreensRPC'
import { jobQueryKeys } from './queryKeys'

type TQueryOptions = Omit<
  UseQueryOptions<
    TGetJobScreensResponse,
    (string | Record<string, number | undefined>)[],
    TGetJobScreensResponse,
    (string | Record<string, number | undefined>)[]
  >,
  'queryKey' | 'queryFn'
>

/**
 * useGetJobScreens is a hook for retrieving screens for a given job.
 *
 * @param {TQueryOptions} options - react-query query options.
 * @returns {UseQueryResult<TGetJobScreensResponse>} - The query result..
 */
export const useGetJobScreens = (
  jobID: number,
  pageSize?: number,
  options?: TQueryOptions
): UseQueryResult<TGetJobScreensResponse> => {
  const rpc = useGetJobScreensRPC()

  return useQuery(
    jobQueryKeys.screens(jobID, pageSize),
    () => rpc(jobID, pageSize),
    {
      refetchOnWindowFocus: false,
      ...options
    }
  )
}
