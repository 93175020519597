import React, { createContext, useContext } from 'react'
import { Notification, TNotificationVariant } from 'components/lib/Notification'

type TNotification = {
  message: string | null
  variant: TNotificationVariant | null
}

export type TNotificationDispatch = (notification: TNotification) => void

export const NotificationContext = createContext<
  TNotificationDispatch | undefined
>(undefined)

export const useNotification = (): TNotificationDispatch => {
  const context = useContext(NotificationContext)

  if (context === undefined) {
    throw new Error(
      'useNotification must be used within a NotificationProvider'
    )
  }

  return context
}

const DEFAULT_VARIANT = 'error'
const DEFAULT_DURATION = 5000

export const NotificationProvider: React.FC = ({ children }) => {
  const [notification, setNotification] = React.useState<TNotification>({
    message: null,
    variant: null
  })

  const handleClose = () => {
    setNotification({ ...notification, message: null })
  }

  const variant = notification?.variant || DEFAULT_VARIANT
  const msg = notification?.message || ''
  return (
    <NotificationContext.Provider value={setNotification}>
      {children}
      <Notification
        message={msg ? decodeURI(msg) : null}
        variant={variant}
        autoHideDuration={DEFAULT_DURATION}
        onClose={handleClose}
      />
    </NotificationContext.Provider>
  )
}
