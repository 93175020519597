import React, { useEffect } from 'react'
import Chip from '@mui/material/Chip'
import Assignment from '@mui/icons-material/Assignment'
import { useTheme } from '@mui/material/styles'

import { useValidateBatchFile } from 'lib/features/Screening'
import { useNotification } from 'lib/notification'

type TFilenameChipProps = {
  filename: string
  isError?: boolean
  remove?: () => void
}

/**
 * FilenameChip renders a chip that displays a filename. It should be used to represent a file when
 * we don't have the actual File object locally e.g. to represent a file that has been uploaded
 * previously.
 *
 * @component
 */
export const FilenameChip: React.FC<TFilenameChipProps> = ({
  filename,
  isError = false,
  remove
}) => {
  const theme = useTheme()
  return (
    <Chip
      icon={<Assignment />}
      label={filename}
      clickable
      color='primary'
      onDelete={remove}
      sx={{
        backgroundColor: isError
          ? theme.palette.error.main
          : theme.palette.primary.main
      }}
    />
  )
}

type TFileUploadChipProps = Omit<TFilenameChipProps, 'filename'> & {
  file: File
  onError: () => void
}

/**
 * FileUploadChip renders a chip that displays a filename. It takes in a File object that is
 * actively being submitted by a user and calls our validation RPC.
 *
 * @component
 */
export const FileUploadChip: React.FC<TFileUploadChipProps> = ({
  file,
  isError,
  remove,
  onError
}) => {
  const setNotification = useNotification()
  const showError = (error: Error) => {
    setNotification({
      message: error.message,
      variant: 'error'
    })
  }
  const fileValidation = useValidateBatchFile(file, { onError: showError })
  useEffect(() => {
    onError()
  }, [fileValidation.isError])

  return <FilenameChip filename={file.name} isError={isError} remove={remove} />
}

export default FileUploadChip
