import React from 'react'

import { styled } from '@mui/material/styles'

import { TScheduledJobWithStatus } from 'lib/features/job'

import { Link as RouterLink } from 'react-router-dom'
import {
  CompleteIcon,
  IncompleteIcon,
  ScreenedIcon
} from 'components/App/ScheduledScreeningPage/lib/Icons'
import Collapse from '@mui/material/Collapse'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import IconButton from '@mui/material/IconButton'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import ScreensTable from './ScreensTable'
import { formatDate } from 'lib/utils'
import DeleteButtonWithConfirmation from './DeleteButton'
import { useDialog } from 'lib/hooks'

const DetailsTableCell = styled(TableCell)(() => ({
  paddingBottom: 0,
  paddingTop: 0
}))

const BoldTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontWeight: 500,
    fontSize: 14
  }
}))

const ScreensRow = styled(TableRow)(() => ({
  // hide last border
  '&:last-child > td, &:last-child > th': {
    border: 0
  }
}))

const JobInfoRow = styled(TableRow)(() => ({
  '& > td': {
    borderBottom: 'unset'
  }
}))

const JobRows: React.FC<{ rowData: TScheduledJobWithStatus }> = ({
  rowData
}) => {
  const [open, setOpen] = React.useState(false)
  const deleteDialog = useDialog()
  const { job, lastScreened, lastReviewCompleted } = rowData

  return (
    <>
      <JobInfoRow key={job.id}>
        <BoldTableCell data-cy='job-name'>
          {job.name}{' '}
          <IconButton
            aria-label='edit job'
            size='small'
            data-cy='edit-job-button'
            component={RouterLink}
            to={`/jobs/${job.id}/edit`}
          >
            <ModeEditOutlineIcon />
          </IconButton>
          <DeleteButtonWithConfirmation
            open={deleteDialog.open}
            setOpen={deleteDialog.setOpen}
            onClose={deleteDialog.onClose}
            job={job}
          />
        </BoldTableCell>
        <BoldTableCell data-cy='job-last-screened'>
          Last Screened:{' '}
          {lastScreened !== undefined && (
            <ScreenedIcon screenedAt={formatDate(lastScreened)} />
          )}
        </BoldTableCell>
        <BoldTableCell>
          Review Status:{' '}
          {lastReviewCompleted ? <CompleteIcon /> : <IncompleteIcon />}
        </BoldTableCell>
        <TableCell></TableCell>
        <TableCell align='right'>
          <IconButton
            aria-label='expand row'
            size='small'
            data-cy='expand-row'
            onClick={() => setOpen((state) => !state)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </JobInfoRow>
      <ScreensRow data-cy='job-screens'>
        <DetailsTableCell colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <ScreensTable
              jobID={job.id}
              schedule={job.schedule}
              neverRun={lastScreened === undefined}
            />
          </Collapse>
        </DetailsTableCell>
      </ScreensRow>
    </>
  )
}

const JobsTable: React.FC<{ data: TScheduledJobWithStatus[] }> = ({ data }) => {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {data.map((jobWithStatus, i) => (
            <JobRows key={i} rowData={jobWithStatus} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default JobsTable
