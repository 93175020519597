import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import {
  useGetScreenedEntityAuditsRPC,
  TGetScreenedEntityAuditsResponse
} from 'lib/hooks/api/useGetScreenedEntityAuditsRPC'
import { auditQueryKeys } from './queryKeys'

type TQueryOptions = Omit<
  UseQueryOptions<
    TGetScreenedEntityAuditsResponse,
    (string | Record<string, number>)[],
    TGetScreenedEntityAuditsResponse,
    (string | Record<string, number>)[]
  >,
  'queryKey' | 'queryFn'
>

/**
 * useGetScreenedEntityAudits is a hook for retreiving audit logs for a particular screened entity
 * from the back-end.
 *
 * @param {number} screenedEntityID - The screen entity ID to retreive audit logs for.
 * @param {TQueryOptions} options - react-query query options.
 * @returns {UseQueryResult<TGetScreenedEntityAuditsResponse>} - The query results.
 */
export const useGetScreenedEntityAudits = (
  screenedEntityID: number,
  options?: TQueryOptions
): UseQueryResult<TGetScreenedEntityAuditsResponse> => {
  const rpc = useGetScreenedEntityAuditsRPC()

  return useQuery(
    auditQueryKeys.logs(screenedEntityID),
    () => rpc(screenedEntityID),
    {
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  )
}
