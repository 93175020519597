import React, { useState } from 'react'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormHelperText from '@mui/material/FormHelperText'
import Grid from '@mui/material/Grid'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

import { TimePicker } from '@mui/x-date-pickers/TimePicker'

import { TCRONExpression } from 'lib/features/job'
import Weekly from './Weekly'
import Monthly from './Monthly'

type TSchedulerFrequency = 'daily' | 'weekly' | 'monthly'

type TFrequencyProps = {
  frequency: TSchedulerFrequency
  onSelect: (
    event: React.MouseEvent<HTMLElement>,
    newFrequency: TSchedulerFrequency
  ) => void
}

const Frequency = (props: TFrequencyProps): JSX.Element => (
  <FormControl fullWidth>
    <FormGroup row>
      <ToggleButtonGroup
        exclusive
        fullWidth
        onChange={props.onSelect}
        sx={{ height: '40px' }}
        value={props.frequency}
      >
        <ToggleButton value={'daily'} aria-label='daily'>
          Daily
        </ToggleButton>
        <ToggleButton value='weekly' aria-label='weekly'>
          Weekly
        </ToggleButton>
        <ToggleButton value='monthly' aria-label='monthly'>
          Monthly
        </ToggleButton>
      </ToggleButtonGroup>
    </FormGroup>
  </FormControl>
)

type TTimeProps = {
  cron: TCRONExpression
  onChange: (cron: TCRONExpression) => void
  onError: (isError: boolean) => void
}

const Time = ({ cron, onChange, onError }: TTimeProps) => {
  const [time, setTime] = useState<Date | null>(
    new Date(2022, 11, 28, cron.hour, cron.minute) // The year, month, and day components are arbitrary
  )
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const handleChange = (newTime: Date | null) => {
    if (newTime) {
      setTime(newTime)
      onChange({
        ...cron,
        minute: newTime.getMinutes(),
        hour: newTime.getHours()
      })
    }
  }

  const handleError = (reason: string | null) => {
    setErrorMessage(reason ? 'Invalid Time' : null)
    onError(!!reason)
  }

  return (
    <FormControl fullWidth data-cy='form-control-time'>
      <TimePicker
        ampm
        value={time}
        label='Time'
        onChange={handleChange}
        onError={handleError}
        slotProps={{
          textField: {
            fullWidth: true,
            variant: 'outlined',
            inputProps: {
              sx: { height: '7px' }
            }
          }
        }}
      />
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </FormControl>
  )
}

type TSchedulerProps = {
  cron: TCRONExpression
  onChange: (cron: TCRONExpression) => void
  onError: (isError: boolean) => void
}

const Scheduler = ({
  cron,
  onChange,
  onError
}: TSchedulerProps): JSX.Element => {
  const [frequency, setFrequency] = useState<TSchedulerFrequency>('daily')

  const handleSelectFrequency = (
    event: React.MouseEvent<HTMLElement>,
    newFrequency: TSchedulerFrequency
  ) => {
    if (newFrequency) {
      setFrequency(newFrequency)
      onChange({
        ...cron,
        dayOfMonth: '*',
        month: '*',
        dayOfWeek: '*'
      })
    }
  }

  return (
    <Grid container spacing={1} sx={{ maxWidth: '500px' }}>
      <Grid item xs={12} sm={8}>
        <Frequency frequency={frequency} onSelect={handleSelectFrequency} />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Time cron={cron} onChange={onChange} onError={onError} />
      </Grid>

      <Grid item xs={12}>
        {frequency === 'weekly' && <Weekly cron={cron} onChange={onChange} />}
        {frequency === 'monthly' && <Monthly cron={cron} onChange={onChange} />}
      </Grid>
    </Grid>
  )
}

export default Scheduler
