import React from 'react'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import Divider from '@mui/material/Divider'
import TableRow from '@mui/material/TableRow'

import { getCountryName } from 'lib/entity'
import Highlight from 'components/lib/Highlight'
import { Can } from 'lib/authorization'
import UpgradeLink from 'components/lib/Auth/UpgradeLink'
import { ListEntry, Location } from 'proto-js'
import BlurredField from 'components/lib/BlurredField'

const TableWrapper = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingLeft: theme.spacing(2)
}))

const StyledTableCell = styled(TableCell)(() => ({
  borderBottom: '0px solid'
}))

const Title = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingLeft: theme.spacing(2)
}))

interface LocationRowProps {
  canViewValue: boolean
  field: string
  value: string
}

const MOCK_ADDRESS = '12345 XXXXXXXXX XXX'

const LocationRow = ({
  canViewValue = true,
  field,
  value
}: LocationRowProps) => {
  if (value === '') return null

  return (
    <TableRow>
      <StyledTableCell variant='head'>{field}</StyledTableCell>
      <StyledTableCell variant='body'>
        {canViewValue ? (
          <Highlight str={value} />
        ) : (
          <BlurredField data-cy='blurred-item'>{MOCK_ADDRESS}</BlurredField>
        )}
      </StyledTableCell>
    </TableRow>
  )
}

interface LocationTableProps {
  canViewValues: boolean
  location: typeof Location
  divider: boolean
}

const LocationTable = ({
  canViewValues,
  location,
  divider
}: LocationTableProps) => {
  const address = location.getAddress()
  const city = location.getCity()
  const state = location.getState()
  const postal = location.getPostal()
  const region = location.getRegion()
  const country = location.getCountry()

  return (
    <TableWrapper>
      <Table size='small'>
        <colgroup>
          <col width='20%' />
          <col width='80%' />
        </colgroup>
        <TableBody>
          <LocationRow
            field='Address'
            canViewValue={canViewValues}
            value={address}
          />
          <LocationRow field='City' canViewValue={canViewValues} value={city} />
          <LocationRow
            field='State'
            canViewValue={canViewValues}
            value={state}
          />
          <LocationRow
            field='Postal'
            canViewValue={canViewValues}
            value={postal}
          />
          <LocationRow
            field='Region'
            canViewValue={canViewValues}
            value={region}
          />
          {country !== undefined && (
            <LocationRow
              field='Country'
              canViewValue
              value={getCountryName(country)}
            />
          )}
        </TableBody>
      </Table>
      {divider && <Divider variant='fullWidth' />}
    </TableWrapper>
  )
}

interface LocationPaneProps {
  entity: typeof ListEntry
}

const LocationsPane = ({ entity }: LocationPaneProps): JSX.Element => {
  const addresses = entity.getAddressesList()
  const pobs = entity.getPobsList()

  return (
    <Can I='view' a='Screen' field='location' passThrough>
      {(allowed: boolean) => (
        <>
          {addresses.length > 0 && <Title variant='subtitle1'>ADDRESSES</Title>}
          {addresses.map((l: typeof Location, i: number) => (
            <LocationTable
              canViewValues={allowed}
              key={i}
              location={l}
              divider={i !== addresses.length - 1} // Don't show divider on last element
            />
          ))}

          {pobs.length > 0 && <Title variant='subtitle1'>PLACE OF BIRTH</Title>}
          {pobs.map((l: typeof Location, i: number) => (
            <LocationTable
              canViewValues={allowed}
              key={i}
              location={l}
              divider={i !== pobs.length - 1} // Don't show divider on last element
            />
          ))}
          {!allowed && (addresses.length > 0 || pobs.length > 0) && (
            <Title align='center' variant='subtitle1'>
              <UpgradeLink additionalText='to see additional location information.' />
            </Title>
          )}
        </>
      )}
    </Can>
  )
}

export default LocationsPane
