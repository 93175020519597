import { useQuery, UseQueryResult } from 'react-query'
import { useEntityTypesDataRPC } from 'lib/hooks/api/useEntityTypesDataRPC'

/**
 * Hook for fetching and caching entity types information via react-query.
 *
 * @returns {QueryResult<TResp>}
 */
export const useEntityTypesData = (): UseQueryResult<string[], Error> => {
  const rpc = useEntityTypesDataRPC()
  return useQuery('entityTypesData', rpc, {
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 300000
  })
}

export default useEntityTypesData
