import React from 'react'

import TextField, { StandardTextFieldProps } from '@mui/material/TextField'
import { FieldState } from 'lib/hooks/useFormField'

interface FormFieldProps extends StandardTextFieldProps {
  state: FieldState<string>
}

const FormField: React.FC<FormFieldProps> = ({
  id,
  size = 'small',
  state,
  variant = 'standard',
  fullWidth = true,
  onChange,
  onKeyPress,
  ...fieldProps
}: FormFieldProps) => (
  <TextField
    id={id}
    size={size}
    value={state.value}
    label={state.label}
    error={state.status === 'error'}
    disabled={state.status === 'disabled'}
    helperText={state.message}
    type='search'
    variant={variant}
    fullWidth={fullWidth}
    onChange={onChange}
    onKeyPress={onKeyPress}
    {...fieldProps}
  />
)

export default FormField
