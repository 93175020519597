import React from 'react'

import Button, { ButtonProps } from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import Fade from '@mui/material/Fade'
import CloudUpload from '@mui/icons-material/CloudUpload'

import { usePostScreen, TAdHocScreenData } from 'lib/features/Screening'
import { Can } from 'lib/authorization'
import { SupportDialog } from 'components/lib/SupportDialog'

const tooltipText = 'Screen successfully saved'
export interface SaveScreenButtonProps extends ButtonProps {
  screenData: TAdHocScreenData
  screenedEntityIndex: number
}

export const SaveScreenButton: React.FC<SaveScreenButtonProps> = ({
  screenData,
  screenedEntityIndex,
  ...buttonProps
}) => {
  const postScreen = usePostScreen(screenedEntityIndex)
  const disableButton = postScreen.isLoading || postScreen.isSuccess

  return (
    <Can I='update' a='Workflow' passThrough>
      {(allowed: boolean) => (
        <SupportDialog
          buttonComponent={(openModal) => (
            <Tooltip
              title={disableButton ? tooltipText : ''}
              disableFocusListener
              disableTouchListener
              enterDelay={500}
              leaveDelay={200}
              TransitionComponent={Fade}
            >
              <div>
                <Button
                  disabled={disableButton}
                  onClick={
                    allowed
                      ? () => {
                          postScreen.mutate({
                            screen: screenData.screen,
                            screenedEntity:
                              screenData.entities[screenedEntityIndex]
                          })
                        }
                      : openModal
                  }
                  endIcon={<CloudUpload />}
                  {...buttonProps}
                >
                  Save Audit Record
                </Button>
              </div>
            </Tooltip>
          )}
        />
      )}
    </Can>
  )
}

export default SaveScreenButton
