import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './components/App'
import * as serviceWorker from './serviceWorker'
import { Auth0Provider, CacheLocation } from '@auth0/auth0-react'
import history from './components/App/history'
import { trackEvent } from 'lib/analytics/ga4'

import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react'

type AppState = {
  returnTo?: string | undefined
  [key: string]: string | undefined
}

const config = {
  apiKey: process.env.REACT_APP_HONEYBADGER_KEY,
  environment: process.env.REACT_APP_ENV ?? process.env.NODE_ENV,
  revision: process.env.REACT_APP_GIT_COMMIT
}

const honeybadger = Honeybadger.configure(config)

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState?: AppState | undefined) => {
  trackEvent('login')
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

if (
  !process.env.REACT_APP_AUTH_DOMAIN ||
  !process.env.REACT_APP_AUTH_CLIENT_ID
) {
  throw new Error('Missing required environment variable!')
}

const cache = process.env.REACT_APP_AUTH_STORAGE as CacheLocation

ReactDOM.render(
  <HoneybadgerErrorBoundary honeybadger={honeybadger}>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH_DOMAIN}
      clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      cacheLocation={cache}
      audience={process.env.REACT_APP_AUTH_AUDIENCE}
    >
      <App />
    </Auth0Provider>
  </HoneybadgerErrorBoundary>,
  document.getElementById('root')
)

serviceWorker.unregister()
