import { useGRPC } from 'lib/hooks'
import {
  Audit,
  GetScreenedEntityAuditsRequest,
  GetScreenedEntityAuditsResponse,
  HitAudits
} from 'proto-js'
import { THitAudits, TAuditTrail } from 'lib/features/audit'

const prepareRPCRequest = (screenedEntityID: number) => {
  const request = new GetScreenedEntityAuditsRequest()
  request.setScreenedEntityId(screenedEntityID)
  return request
}

const prepareRPCResponse = (
  resp: typeof GetScreenedEntityAuditsResponse
): TAuditTrail =>
  resp.getHitsList().map(
    (hit: typeof HitAudits): THitAudits => ({
      id: hit.getId(),
      name: hit.getName(),
      score: hit.getScore(),
      listSource: hit.getListSource(),
      lists: hit.getListsList(),
      audits: hit.getAuditsList().map((audit: typeof Audit) => ({
        classification: audit.getClassification(),
        matchType: audit.getMatchType(),
        reasons: audit.getReasonsList(),
        analysis: audit.getAnalysis(),
        narrative: audit.getNarrative(),
        timestamp: audit.getTimestamp().toDate()
      })),
      url: hit.getUrl()
    })
  )

export type TGetScreenedEntityAuditsResponse = ReturnType<
  typeof prepareRPCResponse
>

type TGetScreenedEntityAuditsRPC = (
  ...args: Parameters<typeof prepareRPCRequest>
) => Promise<ReturnType<typeof prepareRPCResponse>>

/**
 * useGetScreenedEntityAuditsRPC provides an RPC to fetch audits for a particular screened entity.
 *
 * @returns {TGetScreenedEntityAuditRPC} - An async function to invoke the RPC.
 */
export const useGetScreenedEntityAuditsRPC = (): TGetScreenedEntityAuditsRPC => {
  const { workflowPromiseService } = useGRPC()
  return async (...args: Parameters<typeof prepareRPCRequest>) => {
    return workflowPromiseService
      .getScreenedEntityAudits(prepareRPCRequest(...args), {})
      .then(prepareRPCResponse)
  }
}
