import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import {
  useGetScheduledJobsRPC,
  TGetScheduledJobsResponse
} from 'lib/hooks/api/useGetScheduledJobsRPC'
import { jobQueryKeys } from './queryKeys'

type TQueryOptions = Omit<
  UseQueryOptions<
    TGetScheduledJobsResponse,
    string[],
    TGetScheduledJobsResponse,
    string[]
  >,
  'queryKey' | 'queryFn'
>

/**
 * useGetScheduledJobs is a hook for querying all jobs for a given user.
 *
 * @param {TQueryOptions} options - react-query query options.
 * @returns {UseQueryResult<TGetScheduledJobsResponse>} - The query result..
 */
export const useGetScheduledJobs = (
  options?: TQueryOptions
): UseQueryResult<TGetScheduledJobsResponse> => {
  const rpc = useGetScheduledJobsRPC()
  return useQuery(jobQueryKeys.userJobs, () => rpc(), {
    refetchOnWindowFocus: false,
    ...options
  })
}
