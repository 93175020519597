import React from 'react'
import { ListSourcesState } from '../types'

import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { ADVERSE_MEDIA } from 'lib/constants/list_sources'

const styles = StyleSheet.create({
  cell: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center'
  },
  container: {
    border: '3 solid black',
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: 6,
    paddingHorizontal: 6,
    flexWrap: 'wrap'
  },
  numericValue: {
    textAlign: 'center',
    marginRight: 6,
    fontSize: 10
  },
  highlight: {
    backgroundColor: 'salmon'
  },
  headerRow: {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    fontSize: 12
  },
  leftCell: {
    width: '34%'
  },
  header: {
    lineHeight: 1.6,
    textDecoration: 'underline',
    paddingBottom: 8
  },
  pageTitle: {
    fontSize: 18,
    fontWeight: 700,
    textAlign: 'center',
    paddingBottom: 10
  },
  rightCell: {
    width: '66%'
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  rowWrapper: {
    width: '50%',
    padding: '2 8'
  },
  listSourceSourceName: {
    textAlign: 'left',
    textTransform: 'uppercase',
    fontSize: 10,
    marginLeft: 8
  },
  listSourceCell: {
    paddingVertical: 4
  }
})

interface ListSourceCellProps {
  listSource: string
  listsScreened: number
  totalLists: number
  hasHits: boolean
}

const ListSourceCell: React.FC<ListSourceCellProps> = ({
  hasHits,
  listSource,
  listsScreened,
  totalLists
}) => {
  const rowStyles = [styles.row, styles.listSourceCell]

  if (hasHits) {
    rowStyles.push(styles.highlight)
  }

  const isMedia = listSource === ADVERSE_MEDIA

  return (
    <View wrap={false} style={styles.rowWrapper}>
      <View style={rowStyles}>
        <Text
          style={[styles.cell, styles.rightCell, styles.listSourceSourceName]}
        >
          {listSource}
        </Text>
        <Text style={[styles.cell, styles.leftCell, styles.numericValue]}>
          {isMedia ? 'GLOBAL' : `${listsScreened}/${totalLists}`}
        </Text>
      </View>
    </View>
  )
}

const ListSourceHeaders: React.FC = () => (
  <View fixed style={styles.headerRow}>
    <Text
      style={[
        styles.cell,
        styles.rightCell,
        styles.header,
        { paddingHorizontal: 40 }
      ]}
    >
      LIST SOURCE SCREENED
    </Text>
    <Text style={[styles.cell, styles.leftCell, styles.header]}>
      LISTS SCREENED
    </Text>
  </View>
)

const ListSourceSummary: React.FC<{ listSourcesState: ListSourcesState }> = ({
  listSourcesState
}) => {
  const summaryItems = []
  for (const categoryState of listSourcesState.values()) {
    for (const [
      listSourceName,
      listSourceState
    ] of categoryState.listSources.entries()) {
      if (!listSourceState.selected) {
        continue
      }
      let listsScreened = 0
      for (const listSelected of listSourceState.lists.values()) {
        if (listSelected) {
          listsScreened++
        }
      }

      summaryItems.push(
        <ListSourceCell
          key={listSourceName}
          listSource={listSourceName}
          listsScreened={listsScreened}
          totalLists={listSourceState.lists.size}
          hasHits={listSourceState.hasHits}
        />
      )
    }
  }

  summaryItems.unshift(
    <ListSourceHeaders key='header-1' />,
    <ListSourceHeaders key='header-2' />
  )

  return (
    <>
      <Text fixed style={styles.pageTitle}>
        Appendix
      </Text>
      <View style={styles.container}>{summaryItems}</View>
    </>
  )
}

export default ListSourceSummary
