import { TFormErrors, IFormValues } from './JobForm'
import { TServerError } from 'lib/gRPC'
import { FormikHelpers } from 'formik'
import { validateScheduleField } from './ScheduleField'

const MAX_JOB_NAME_LENGTH = 50

export const handleServerErrors = (
  error: TServerError,
  formikHelpers: FormikHelpers<IFormValues>
): void => {
  if (error.message === 'Duplicate job name') {
    formikHelpers.setErrors({
      name: 'This name is already taken. Please try a different name.'
    })
  }
}

export const validateJobNameField = (
  errors: TFormErrors,
  value: string
): void => {
  if (!value) {
    errors.name = 'Required'
  } else if (value.length > MAX_JOB_NAME_LENGTH) {
    errors.name = `Must be ${MAX_JOB_NAME_LENGTH} characters or less`
  }
}

export const validateFn = (isEditing: boolean) => {
  return (values: IFormValues): TFormErrors => {
    const errors = {} as TFormErrors

    validateJobNameField(errors, values.name)
    validateScheduleField(errors, values.schedule)

    // When editing an existing job we don't have access to the previously submitted File object,
    // just the filename so we need to relax the validation rules for that scenario.
    if (!isEditing && !values.screenEntities) {
      errors.screenEntities = 'Required'
    }

    return errors
  }
}
