import React from 'react'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import Checkbox from '@mui/material/Checkbox'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import ListItemButton from '@mui/material/ListItemButton'

import Highlight from 'components/lib/Highlight'
import { hitAuditStatus } from 'lib/helpers/hit'
import { getHitScore, getHighlightedEntity } from 'lib/entity'
import { THit } from 'lib/features/Screening'
import { EMPTY_DISPLAY_NAME } from 'lib/constants/entities'

const PREFIX = 'HitsListVirtual'

const classes = {
  hitsWrapper: `${PREFIX}-hitsWrapper`,
  root: `${PREFIX}-root`,
  item: `${PREFIX}-item`,
  text: `${PREFIX}-text`,
  iconWrapper: `${PREFIX}-iconWrapper`,
  default: `default`,
  listItemText: `${PREFIX}-listItemText`,
  success: `success`,
  error: `error`,
  needMoreInfo: `needMoreInfo`,
  selectAll: `${PREFIX}-selectAll`
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column'
  },
  [`& .${classes.hitsWrapper}`]: {
    height: '100%',
    flex: 1
  },
  [`& .${classes.item}`]: {
    height: '80px'
  },
  [`& .${classes.text}`]: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: theme.spacing(2)
  },
  [`& .${classes.iconWrapper}`]: {
    minWidth: '36px'
  },
  [`& .${classes.default}`]: {
    backgroundColor: 'white'
  },
  [`& .${classes.listItemText}`]: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  [`& .${classes.success}`]: {
    backgroundColor: theme.palette.success.light
  },
  [`& .${classes.error}`]: {
    backgroundColor: theme.palette.error.light
  },
  [`& .${classes.needMoreInfo}`]: {
    backgroundColor: theme.palette.warning.light
  }
}))

const SelectAllHeader = styled(ListItemText)(() => ({
  '& .MuiListItemText-primary': {
    fontWeight: 500
  }
}))

type THitsListItem = {
  hit: THit
  style: React.CSSProperties
  idx: number
  selectedIdx: number | null
  onCheck: (checked: boolean, hitIndex: number) => void
  onSelect: (hitIdx: number) => void
  checked: boolean
}

const HitsListItem: React.FC<THitsListItem> = ({
  hit,
  idx,
  selectedIdx,
  style,
  onCheck,
  onSelect,
  checked
}) => {
  const handleSelect = () => {
    onSelect(idx)
  }

  const handleCheck = (e: React.MouseEvent<HTMLButtonElement>) => {
    const target = e.target as HTMLInputElement

    onCheck(target.checked, idx)
  }

  const listEntry = hit.protobuf.getListEntry()
  const listSourcesStr = listEntry.getListSource().getName()
  const highlightedEntity = getHighlightedEntity(hit.protobuf)
  const fullName = highlightedEntity?.getName()?.getFullName()
  const displayName = fullName || EMPTY_DISPLAY_NAME

  const score = getHitScore(hit.protobuf)

  const statusClass = hitAuditStatus(hit.classification)

  return (
    <ListItemButton
      style={style}
      className={clsx(classes.item, classes[statusClass])}
      divider
      selected={selectedIdx === idx}
      onClick={handleSelect}
      data-cy='hit-list-item'
    >
      <ListItemIcon className={classes.iconWrapper}>
        <Checkbox
          color='primary'
          data-cy='hit-checkbox'
          onClick={handleCheck}
          checked={checked}
        />
      </ListItemIcon>
      <ListItemText
        primary={
          <div className={classes.listItemText}>
            <Highlight className={classes.text} str={displayName} />
            <Typography>{score}</Typography>
          </div>
        }
        secondary={<span className={classes.text}>{listSourcesStr}</span>}
      />
    </ListItemButton>
  )
}

type TRendererData = {
  hits: THit[]
  selectedIdx: number | null
  checkedState: number[]
  onCheck: (checked: boolean, hitIndex: number) => void
  onSelect: (hitIdx: number) => void
}

type THitsListRenderer = {
  index: number
  style: React.CSSProperties
  data: TRendererData
}

const HitsListRenderer: React.FC<THitsListRenderer> = ({
  index,
  style,
  data
}) => (
  <HitsListItem
    style={style}
    key={index}
    idx={index}
    hit={data.hits[index]}
    selectedIdx={data.selectedIdx}
    onSelect={data.onSelect}
    onCheck={data.onCheck}
    checked={data.checkedState.indexOf(index) !== -1}
  />
)

type THitsListVirtual = {
  allChecked: boolean
  checkedState: number[]
  hits: THit[]
  onCheck: (checked: boolean, hitIndex: number) => void
  onSelect: (hitIdx: number) => void
  onSelectAll: (e: React.MouseEvent<HTMLButtonElement>) => void
  selected: number | null
}

const HitsListVirtual: React.FC<THitsListVirtual> = ({
  allChecked,
  hits = [],
  selected,
  onCheck,
  onSelect,
  onSelectAll,
  checkedState = []
}) => {
  const itemData: TRendererData = {
    hits: hits,
    selectedIdx: selected,
    onSelect: onSelect,
    onCheck: onCheck,
    checkedState: checkedState
  }

  return (
    <Root className={classes.root}>
      <ListItem className={classes.item}>
        <ListItemIcon className={classes.iconWrapper}>
          <Checkbox
            color='primary'
            data-cy='select-all-checkbox'
            checked={allChecked}
            onClick={onSelectAll}
          />
        </ListItemIcon>
        <SelectAllHeader primary='Click here to select all.  Click below to select one at a time.' />
      </ListItem>
      <div className={classes.hitsWrapper}>
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              height={height}
              width={width}
              itemSize={80}
              itemCount={hits.length}
              itemData={itemData}
            >
              {HitsListRenderer}
            </FixedSizeList>
          )}
        </AutoSizer>
      </div>
    </Root>
  )
}

export default HitsListVirtual
