import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import Breadcrumbs from '@mui/material/Breadcrumbs'
import Container from '@mui/material/Container'
import Link, { LinkProps } from '@mui/material/Link'
import { styled } from '@mui/material/styles'

import TeaserLink from 'components/lib/Auth/TeaserLink'
import Header from 'components/lib/Header'
import Footer from 'components/lib/Footer'
import { EnterpriseLink } from 'components/lib/SupportDialog'

const PREFIX = 'withBreadcrumbs'

const classes = {
  root: `${PREFIX}-root`,
  breadCrumb: `${PREFIX}-breadCrumb`,
  breadCrumbActive: `${PREFIX}-breadCrumbActive`,
  breadCrumbInactive: `${PREFIX}-breadCrumbInactive`,
  content: `${PREFIX}-content`,
  page: `${PREFIX}-page`
}

const StyledLink = styled(Link)(() => ({
  cursor: 'pointer',
  textDecoration: 'none'
}))

const AdWrapper = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '60px',
    backgroundImage: `url(${process.env.REACT_APP_CDN_PATH}/media/background.svg)`,
    minHeight: '100vh'
  },
  [`& .${classes.breadCrumb}`]: {
    margin: theme.spacing(3)
  },
  [`& .${classes.breadCrumbActive}`]: {
    fontWeight: 'bold'
  },
  [`& .${classes.breadCrumbInactive}`]: {
    fontWeight: 'normal'
  },
  [`& .${classes.content}`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(6)
  },
  [`& .${classes.page}`]: {
    width: '100%',
    maxWidth: '1200px',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4)
  }
}))

function getBreadcrumbClass(path: string) {
  const location = useLocation()
  if (location.pathname.split('/')[1] === path.split('/')[1]) {
    return classes.breadCrumbActive
  }
  return classes.breadCrumbInactive
}

interface LinkRouterProps extends LinkProps {
  to: string
  replace?: boolean
}

const LinkRouter = React.forwardRef<HTMLAnchorElement, LinkRouterProps>(
  (props, ref) => (
    <Link
      {...props}
      ref={ref}
      component={RouterLink}
      underline='hover'
      className={getBreadcrumbClass(props.to)}
    />
  )
)

LinkRouter.displayName = 'LinkRouter'

const BreadcrumbsSection: React.FC = () => (
  <Breadcrumbs className={classes.breadCrumb} separator='|'>
    <LinkRouter to='/search'>Advanced Search</LinkRouter>
    <TeaserLink
      action='create'
      subject='BatchSearch'
      routerLinkProps={{ component: LinkRouter, to: '/screen/persons' }}
      component={(props) => <StyledLink {...props}></StyledLink>}
    >
      Batch Screening
    </TeaserLink>
    <TeaserLink
      action='view'
      subject='Jobs'
      routerLinkProps={{ component: LinkRouter, to: '/jobs' }}
      component={(props) => <StyledLink {...props}></StyledLink>}
    >
      Scheduled Screening
    </TeaserLink>
    <TeaserLink
      action='update'
      subject='Workflow'
      routerLinkProps={{ component: LinkRouter, to: '/audit-trail' }}
      component={(props) => <StyledLink {...props}></StyledLink>}
    >
      Audit
    </TeaserLink>
    <TeaserLink
      action='view'
      subject='Report'
      dialogBody={<EnterpriseLink />}
      routerLinkProps={{ component: LinkRouter, to: '/reports' }}
      component={(props) => <StyledLink {...props}></StyledLink>}
    >
      Reports
    </TeaserLink>
  </Breadcrumbs>
)

export function withBreadcrumbs<T>(
  Page: React.ComponentType<T>
): (props: T) => JSX.Element {
  const PageWithBreadcrumbs = (props: T) => {
    return (
      <AdWrapper>
        <Header />

        <div className={classes.root}>
          <BreadcrumbsSection />
          <Container className={classes.content} maxWidth='xl'>
            <div className={classes.page}>
              <Page {...props} />
            </div>
          </Container>
        </div>

        <Footer />
      </AdWrapper>
    )
  }

  return PageWithBreadcrumbs
}
