import { useQuery, UseQueryResult } from 'react-query'

import {
  useGetListSourcesRPC,
  TGetListSourcesResponse
} from 'lib/hooks/api/useGetListSourcesRPC'

/**
 * Hook for providing cached list sources data via react-query.
 *
 * @returns {QueryResult<TGetListSourcesResponse>}
 */
export const useListSources = (): UseQueryResult<TGetListSourcesResponse> => {
  const fetcher = useGetListSourcesRPC()
  return useQuery('listSources', () => fetcher(), {
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 300000
  })
}
