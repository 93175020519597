import React, { Dispatch } from 'react'
import { FieldState, FieldAction } from 'lib/hooks/useFormField'
import { Toggle } from 'components/lib/Form'

const TOGGLE_NAME = 'fuzzyDOB'
const TOOLTIP_TEXT = 'When turned on, expands search to +/- 1 year.'

export interface FuzzyDOBToggleProps {
  fuzzyDOBField?: FieldState<boolean>
  setFuzzyDOB?: Dispatch<FieldAction<boolean>>
  controlled?: boolean
  allowed?: boolean
}

const FuzzyDOBToggle: React.FC<FuzzyDOBToggleProps> = ({
  fuzzyDOBField,
  setFuzzyDOB,
  controlled = false,
  allowed
}) => (
  <Toggle
    field={fuzzyDOBField}
    setField={setFuzzyDOB}
    controlled={controlled}
    label={fuzzyDOBField?.label || 'FuzzyDOB'}
    tooltipText={TOOLTIP_TEXT}
    name={TOGGLE_NAME}
    disabled={!allowed}
  />
)

export default FuzzyDOBToggle
