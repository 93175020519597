import { useGRPC } from 'lib/hooks'
import { DeleteScheduledJobRequest } from 'proto-js'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'

export type TDeleteScheduledJobRequest = {
  id: number
}

export type TDeleteScheduledJobResponse = Empty

export const prepareRPCRequest = (
  request: TDeleteScheduledJobRequest
): typeof DeleteScheduledJobRequest => {
  const rpcRequest = new DeleteScheduledJobRequest()
  rpcRequest.setJobId(request.id)
  return rpcRequest
}

export type TDeleteScheduledJobRPC = (
  params: TDeleteScheduledJobRequest
) => Promise<TDeleteScheduledJobResponse>

export const useDeleteScheduledJobRPC = (): TDeleteScheduledJobRPC => {
  const { workflowPromiseService } = useGRPC()
  return async (params: TDeleteScheduledJobRequest) => {
    return workflowPromiseService.deleteScheduledJob(
      prepareRPCRequest(params),
      {}
    )
  }
}
