import {
  TScreenedEntity,
  TPersistedScreenedEntity
} from 'lib/features/Screening'
import useGRPC from '../useGRPC'
import ProtoTimestamp from 'google-protobuf/google/protobuf/timestamp_pb.js'
import {
  HitRecord,
  PostScreenedEntityRequest,
  PostScreenedEntityResponse
} from 'proto-js'

export type TPostScreenedEntityParams = {
  screenID: number
  screenedEntity: TScreenedEntity
}

const prepareRPCRequest = (params: TPostScreenedEntityParams) => {
  const request = new PostScreenedEntityRequest()
  request.setScreenId(params.screenID)
  request.setName(params.screenedEntity.name)
  request.setIdNumber(params.screenedEntity.idNumber)
  request.setLocation(params.screenedEntity.location)
  if (params.screenedEntity.dob) {
    request.setDob(ProtoTimestamp.Timestamp.fromDate(params.screenedEntity.dob))
  }
  request.setReferenceId(params.screenedEntity.referenceId)
  request.setTotalHits(params.screenedEntity.totalHits)
  request.setHitsList(params.screenedEntity.hits.map((hit) => hit.protobuf))
  return request
}

export type TPostScreenedEntityResponse = TPersistedScreenedEntity

export const prepareRPCResponse = (
  resp: typeof PostScreenedEntityResponse
): TPostScreenedEntityResponse => ({
  id: resp.getScreenedEntityId(),
  name: resp.getName(),
  idNumber: resp.getIdNumber(),
  location: resp.getLocation(),
  dob: resp.getDob()?.toDate(),
  referenceId: resp.getReferenceId(),
  totalHits: resp.getTotalHits(),
  hits: resp.getHitsList().map((hitRecord: typeof HitRecord) => ({
    id: hitRecord.getHitId(),
    protobuf: hitRecord.getHit()
  })),
  status: 'incomplete'
})

type TPostScreenedEntityRPC = (
  params: TPostScreenedEntityParams
) => Promise<TPostScreenedEntityResponse>

/**
 *  usePostScreenedEntityRPC provides an async function to invoke the PostScreenedEntity RPC.
 *
 * @returns {TPostScreenedEntityRPC} - An async function to invoke the RPC.
 */
export const usePostScreenedEntityRPC = (): TPostScreenedEntityRPC => {
  const { workflowPromiseService } = useGRPC()
  return async (params: TPostScreenedEntityParams) => {
    return workflowPromiseService
      .postScreenedEntity(prepareRPCRequest(params), {})
      .then(prepareRPCResponse)
  }
}
