import { createContext, useContext } from 'react'
import { createContextualCan } from '@casl/react'
import { AppAbility } from './ability'
import { rulesToFields } from '@casl/ability/extra'
import { useAuth0 } from '@auth0/auth0-react'

//eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AbilityContext = createContext<AppAbility>(undefined!)
export const Can = createContextualCan(AbilityContext.Consumer)

export const useAbility = (): AppAbility => {
  return useContext(AbilityContext)
}

type TSettings = {
  isPremium: boolean
  maxResults: number
}

export const useScreenSettings = (): TSettings => {
  const ability = useAbility()
  const { user } = useAuth0()

  const defaultListValues = rulesToFields(ability, 'list', 'Screen')
  const screenResultLimit = parseInt(defaultListValues['limit'] as string)

  return {
    isPremium: !!user?.['http://castellum.ai/roles']?.includes('tier_premium'),
    maxResults: screenResultLimit
  }
}
