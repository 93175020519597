import React from 'react'
import TextField, { StandardTextFieldProps } from '@mui/material/TextField'
import { useField } from 'formik'

interface TTextFieldProps extends StandardTextFieldProps {
  name: string
}

export const ConnectedTextField = ({
  name,
  variant = 'standard',
  type = 'text',
  fullWidth = true,
  ...muiTextFieldProps
}: TTextFieldProps): JSX.Element => {
  const [field, meta] = useField(name)

  return (
    <TextField
      {...muiTextFieldProps}
      {...field}
      id={name}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      fullWidth={fullWidth}
      type={type}
      variant={variant}
    />
  )
}

export default ConnectedTextField
