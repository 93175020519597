import React from 'react'
import clsx from 'clsx'

import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import AppBar from '@mui/material/AppBar'
import Backdrop, { BackdropProps } from '@mui/material/Backdrop'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

import CloseIcon from '@mui/icons-material/Close'

import WorkflowStepper from './WorkflowStepper'
import workflowReducer, { initialWorkflowState } from './workflowReducer'
import { HitsListVirtual } from 'components/lib/HitsList'
import EntityCard from '../EntityCard'
import Classify from './steps/Classify'
import Reasons from './steps/Reasons'
import Analysis from './steps/Analysis'
import Review from './steps/Review'
import Spinner from '../Spinner'
import { getHighlightedEntity } from 'lib/entity'
import QueryOverview from './QueryOverview'
import DialogButtons from './DialogButtons'
import { TScreenData } from 'lib/features/Screening'
import { steps } from '.'
import SubmitWorkflow, { TPostFunc } from './SubmitWorkflow'
import { trackEvent } from 'lib/analytics/ga4'

const PREFIX = 'WorkflowDialog'

const classes = {
  rootPaper: `${PREFIX}-rootPaper`,
  appBar: `${PREFIX}-appBar`,
  title: `${PREFIX}-title`,
  box: `${PREFIX}-box`,
  flexContainer: `${PREFIX}-flexContainer`,
  flexItemTop: `${PREFIX}-flexItemTop`,
  flexItemBottom: `${PREFIX}-flexItemBottom`,
  scroll: `${PREFIX}-scroll`,
  form: `${PREFIX}-form`
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.rootPaper}`]: {
    backgroundImage: `url(${process.env.REACT_APP_CDN_PATH}/media/background.svg)`
  },
  [`& .${classes.appBar}`]: {
    position: 'relative'
  },
  [`& .${classes.title}`]: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  [`& .${classes.box}`]: {
    height: '100%',
    width: '100%',
    paddingTop: theme.spacing(1)
  },
  [`& .${classes.flexContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch'
  },
  [`& .${classes.flexItemTop}`]: {
    flexBasis: '50%',
    marginBottom: theme.spacing(1)
  },
  [`& .${classes.flexItemBottom}`]: {
    flexBasis: '50%',
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column'
  },
  [`& .${classes.scroll}`]: {
    overflow: 'auto'
  },
  [`& .${classes.form}`]: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

const StyledBackdrop = styled(Backdrop)<BackdropProps>(({ theme }) => ({
  zIndex: theme.zIndex.modal + 1
}))

type TWorkflowDialog = {
  open: boolean
  screenData: TScreenData
  screenedEntityIndex: number
  onClose: () => void
}

const WorkflowDialog: React.FC<TWorkflowDialog> = ({
  open,
  screenData,
  screenedEntityIndex,
  onClose
}) => {
  const [state, dispatch] = React.useReducer(
    workflowReducer,
    initialWorkflowState
  )
  const [isPosting, setIsPosting] = React.useState(false)

  const screenedEntity = screenData.entities[screenedEntityIndex]
  const singleHitSelected = state.checkedHitIndices.length === 1
  const allChecked =
    state.checkedHitIndices.length === screenedEntity.hits.length

  const handleSelectAll = (e: React.MouseEvent<HTMLButtonElement>) => {
    const hitIndices = []

    // If the checkbox is unchecked, we'll just return an empty array
    const target = e.target as HTMLInputElement

    if (target.checked) {
      for (let i = 0; i < screenedEntity.hits.length; i++) {
        hitIndices.push(i)
      }
    }

    dispatch({ type: 'RESET_AUDIT' })
    dispatch({ type: 'CHECK_MULTIPLE_HITS', payload: hitIndices })
  }

  const handleSelectHit = (hitIndex: number) => {
    dispatch({ type: 'RESET_AUDIT' })
    dispatch({ type: 'SELECT_HIT', payload: hitIndex })
  }

  const handleCheckHit = (checked: boolean, hitIndex: number) => {
    if (checked) {
      dispatch({ type: 'CHECK_HIT', payload: hitIndex })
    } else {
      dispatch({ type: 'UNCHECK_HIT', payload: hitIndex })
    }
  }

  const handleClose = () => {
    dispatch({ type: 'RESET' })
    onClose()
  }

  const handleBack = () => {
    if (state.stepIdx > 0) {
      dispatch({ type: 'SET_STEP', payload: state.stepIdx - 1 })
    }
  }

  const handleNext = () => {
    if (state.stepIdx < steps.length - 1) {
      dispatch({ type: 'SET_STEP', payload: state.stepIdx + 1 })
    }
  }

  const reset = () => {
    if (state.checkedHitIndices.length === 0) {
      dispatch({ type: 'RESET_AUDIT' })
      return
    }

    const lastCheckedHitIndex = state.checkedHitIndices.slice(-1)[0]
    const nextHitIndex = screenedEntity.hits.findIndex(
      (h, i) => i > lastCheckedHitIndex && h.classification === undefined
    )

    // If the array of hits contains the last item in the list, close the modal
    // Otherwise, advance to the next hit index
    if (nextHitIndex < 0) {
      handleClose()
    } else {
      dispatch({ type: 'RESET_CHECKED' })
      dispatch({ type: 'SELECT_HIT', payload: nextHitIndex })
    }
  }

  const handleSubmit = (postWorkflow: TPostFunc) => {
    trackEvent('workflow_submitted')
    setIsPosting(true)
    postWorkflow()
      .then(reset)
      .finally(() => setIsPosting(false))
  }

  // TODO: could be extracted to its own component
  let form = (
    <Classify
      state={state}
      dispatch={dispatch}
      disabled={state.checkedHitIndices.length === 0}
    />
  )

  const step = steps[state.stepIdx]
  switch (step) {
    case 'classify':
      form = (
        <Classify
          state={state}
          dispatch={dispatch}
          disabled={state.checkedHitIndices.length === 0}
        />
      )
      break
    case 'reasons':
      form = <Reasons state={state} dispatch={dispatch} />
      break
    case 'analysis':
      form = <Analysis state={state} dispatch={dispatch} />
      break
    case 'review':
      form = (
        <Review
          screenEntity={screenedEntity}
          listEntry={
            state.checkedHitIndices.length === 1
              ? screenedEntity.hits[
                  state.checkedHitIndices[0]
                ].protobuf.getListEntry()
              : undefined
          }
          timestamp={new Date()}
          classification={state.classification}
          matchType={state.matchType}
          reasons={[...state.reasons]}
          analysis={state.analysis}
          singleHitSelected={singleHitSelected}
        />
      )
      break
    default:
      console.warn('Unrecognized step: ' + step)
  }

  return (
    <>
      <StyledDialog
        PaperProps={{ className: classes.rootPaper }}
        open={open}
        onClose={handleClose}
        data-cy='workflow-dialog'
        fullScreen
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography
              variant='h6'
              className={classes.title}
              data-cy='workflow-dialog-title'
            >
              Evaluate Hits
            </Typography>
            <IconButton
              size='small'
              edge='start'
              color='inherit'
              onClick={handleClose}
            >
              <CloseIcon data-cy='close-button' />
            </IconButton>
          </Toolbar>
        </AppBar>

        <DialogTitle>
          <QueryOverview screenEntity={screenedEntity} />
        </DialogTitle>

        <DialogContent>
          <Grid
            container
            className={classes.box}
            spacing={2}
            justifyContent='center'
          >
            <Grid item xs={3} className={classes.box}>
              <Paper className={classes.box}>
                <HitsListVirtual
                  allChecked={allChecked}
                  onSelectAll={handleSelectAll}
                  hits={screenedEntity.hits}
                  selected={state.selectedHitIndex}
                  onSelect={handleSelectHit}
                  onCheck={handleCheckHit}
                  checkedState={state.checkedHitIndices}
                />
              </Paper>
            </Grid>

            <Grid
              item
              xs={9}
              className={clsx(classes.flexContainer, classes.box)}
            >
              <Paper className={clsx(classes.flexItemTop, classes.scroll)}>
                {screenedEntity.hits.length > 0 ? (
                  <EntityCard
                    variant='plain'
                    entity={getHighlightedEntity(
                      screenedEntity.hits[state.selectedHitIndex].protobuf
                    )}
                  />
                ) : (
                  <Spinner />
                )}
              </Paper>

              <Paper className={clsx(classes.flexItemBottom, classes.scroll)}>
                <WorkflowStepper stepIdx={state.stepIdx} />
                <div className={classes.form} data-cy='workflow-form'>
                  {form}
                </div>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>

        <SubmitWorkflow
          screenData={screenData}
          screenedEntityIndex={screenedEntityIndex}
          workflow={state}
        >
          {(postWorkflow) => (
            <DialogButtons
              stepIdx={state.stepIdx}
              onBackClick={handleBack}
              numHitsSelected={state.checkedHitIndices.length}
              onNextClick={handleNext}
              onSubmitClick={() => handleSubmit(postWorkflow)}
            />
          )}
        </SubmitWorkflow>
      </StyledDialog>

      <StyledBackdrop open={isPosting}>
        <Spinner />
      </StyledBackdrop>
    </>
  )
}

export default WorkflowDialog
