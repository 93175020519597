import React from 'react'
import { styled } from '@mui/material/styles'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'

import { FilenameChip } from 'components/lib/Form'

const PREFIX = 'ScreenStats'

const classes = {
  container: `${PREFIX}-container`,
  stats: `${PREFIX}-stats`,
  bars: `${PREFIX}-bars`,
  table: `${PREFIX}-table`,
  tableCell: `${PREFIX}-tableCell`,
  success: `${PREFIX}-success`,
  successBar: `${PREFIX}-successBar`,
  error: `${PREFIX}-error`,
  errorBar: `${PREFIX}-errorBar`
}

const StyledCollapse = styled(Collapse)(({ theme }) => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`
  },

  [`& .${classes.bars}`]: {
    flexBasis: '50%',
    textAlign: 'left',
    margin: '5px 15px 5px 15px'
  },

  [`& .${classes.success}`]: {
    backgroundColor: theme.palette.success.light
  },

  [`& .${classes.successBar}`]: {
    backgroundColor: theme.palette.success.main
  },

  [`& .${classes.error}`]: {
    backgroundColor: theme.palette.error.light
  },

  [`& .${classes.errorBar}`]: {
    backgroundColor: theme.palette.error.main
  }
}))

const FilenameGrid = styled(Grid)(({ theme }) => ({
  paddingRight: 25,
  marginRight: 25,
  borderRight: `1px solid ${theme.palette.divider}`
}))

const StatsGrid = styled(Grid)(({ theme }) => ({
  flexBasis: '50%',
  margin: '5px 15px 5px 15px',
  paddingRight: '25px',
  borderRight: `1px solid ${theme.palette.divider}`
}))

type TScreenStatsProps = {
  open?: boolean
  numQueries?: number
  numScreened?: number
  numFlagged?: number
  numResolved?: number
  isError?: boolean
  filename?: string
}

const ScreenStats: React.FC<TScreenStatsProps> = ({
  open = false,
  numQueries = 0,
  numScreened = 0,
  numFlagged = 0,
  numResolved = 0,
  isError = false,
  filename
}) => {
  let screenedProgress = 0
  if (numQueries > 0) {
    screenedProgress = Math.round((numScreened / numQueries) * 100)
    screenedProgress = Math.min(screenedProgress, 100)
  }

  let resolvedProgress = 0
  if (numQueries > 0) {
    resolvedProgress = Math.round((numResolved / numQueries) * 100)
    resolvedProgress = Math.min(resolvedProgress, 100)
  }

  let screenedProgressClasses = {}
  if (isError) {
    screenedProgressClasses = {
      colorSecondary: classes.error,
      barColorSecondary: classes.errorBar
    }
  } else if (screenedProgress === 100) {
    screenedProgressClasses = {
      colorSecondary: classes.success,
      barColorSecondary: classes.successBar
    }
  }

  let resolvedProgressClasses = {}
  if (isError) {
    resolvedProgressClasses = {
      colorSecondary: classes.error,
      barColorSecondary: classes.errorBar
    }
  } else if (resolvedProgress === 100) {
    resolvedProgressClasses = {
      colorSecondary: classes.success,
      barColorSecondary: classes.successBar
    }
  }

  return (
    <StyledCollapse in={open} timeout={300}>
      <div className={classes.container}>
        <StatsGrid container flexDirection='row' justifyContent='right'>
          {filename !== undefined && (
            <FilenameGrid item justifyContent='center' flexDirection='column'>
              <Grid item textAlign='center'>
                File:
              </Grid>
              <Grid item>
                <FilenameChip filename={filename} />
              </Grid>
            </FilenameGrid>
          )}
          <Grid item flexDirection='column'>
            <Grid item>Flagged</Grid>
            <Grid item>{numFlagged}</Grid>
          </Grid>
        </StatsGrid>

        <div className={classes.bars}>
          <Grid
            container
            justifyContent='center'
            spacing={1}
            alignItems='center'
          >
            <Grid item xs={9}>
              <LinearProgress
                variant='determinate'
                value={screenedProgress}
                color='secondary'
                classes={screenedProgressClasses}
              />
            </Grid>

            <Grid item xs={3}>
              <Typography variant='body2' noWrap>
                {`${numScreened} Screened`}
              </Typography>
            </Grid>

            <Grid item xs={9}>
              <LinearProgress
                variant='determinate'
                value={resolvedProgress}
                color='secondary'
                classes={resolvedProgressClasses}
              />
            </Grid>

            <Grid item xs={3}>
              <Typography variant='body2' noWrap>
                {`${numResolved} Resolved`}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </StyledCollapse>
  )
}

export default ScreenStats
