import React from 'react'
import EntityInfo, { AdverseMediaInfo } from './EntityInfo'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { insertNewlineAfter } from 'lib/helpers/pdf'
import { AuditTrailData } from '../types'
import {
  THit,
  TPersistedScreenedEntity,
  TScreenedEntity,
  TScreenedEntityStatus,
  screenedEntityStatusLabels
} from 'lib/features/Screening'
import { TAudit, THitAudits } from 'lib/features/audit'
import { getTypeName } from 'lib/entity'
import { formatDateISO8601 } from 'lib/utils'

const pdfStyles = StyleSheet.create({
  audit: {
    paddingTop: '8px',
    marginLeft: '10px',
    fontSize: 9
  },
  auditTimestamp: {
    color: 'gray'
  },
  leftCell: {
    width: 75
  },
  rightCell: {
    flex: 1
  },
  screenItem: {
    fontSize: 12,
    marginBottom: '10px'
  },
  screenItemStatus: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 10
  },
  query: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#fce700',
    padding: '10px',
    alignItems: 'center'
  },
  unresolved: {
    color: 'gray'
  },
  resolved: {
    color: '#249819'
  },
  flagged: {
    color: '#cf0a1a'
  },
  queryHeaderRow: {
    flexDirection: 'row',
    display: 'flex'
  },
  hitEven: {
    fontSize: 10,
    backgroundColor: '#f0f0f0',
    padding: '10px'
  },
  hitOdd: {
    fontSize: 10,
    backgroundColor: '#f7f7f7',
    padding: '10px'
  }
})

const lineWrapLength = 75

const AuditLog: React.FC<{ audit: TAudit }> = ({ audit }) => {
  return (
    <View style={pdfStyles.audit}>
      <Text style={pdfStyles.auditTimestamp}>{audit.timestamp.toString()}</Text>
      <Text>{audit.narrative}</Text>
    </View>
  )
}

const HitInfo: React.FC<{
  hit: THit | Persisted<THit>
  num: number
  hitAudits?: THitAudits
}> = ({ hit, num, hitAudits }) => {
  const isMedia = getTypeName(hit.protobuf.getListEntry()) === 'media'
  const audits = hitAudits?.audits
  const hitStyle = num % 2 === 0 ? 'hitEven' : 'hitOdd'

  return (
    <View style={pdfStyles[hitStyle]} wrap={false}>
      {isMedia ? <AdverseMediaInfo hit={hit} /> : <EntityInfo hit={hit} />}
      {audits !== undefined && audits.length > 0 && (
        <View>
          <Text>Audit Trail:</Text>
          {audits.map((audit, i) => (
            <AuditLog key={i} audit={audit} />
          ))}
        </View>
      )}
    </View>
  )
}

function queryStatusClass(status: TScreenedEntityStatus) {
  let statusStyle = 'unresolved'

  if (status === 'flagged') {
    statusStyle = 'flagged'
  } else if (status === 'resolved') {
    statusStyle = 'resolved'
  } else if (status === 'cleared') {
    statusStyle = 'resolved'
  }

  return statusStyle
}

const getHitAudits = (
  auditTrailData: AuditTrailData,
  hit: THit | Persisted<THit>
) => {
  if ('id' in hit && hit['id'] in auditTrailData) {
    return auditTrailData[hit['id']]
  }
}

const Query: React.FC<{
  screenEntity: TScreenedEntity | TPersistedScreenedEntity
  auditTrailData: AuditTrailData
}> = ({
  screenEntity: { dob, hits, name, location, idNumber, referenceId, status },
  auditTrailData
}) => {
  const statusStyle = queryStatusClass(status)
  const hitElements = []
  for (let i = 0; i < hits.length; i++) {
    const hit = hits[i]
    const hitAudits = getHitAudits(auditTrailData, hit)
    hitElements.push(
      <HitInfo key={i} num={i} hit={hit} hitAudits={hitAudits} />
    )
  }

  const formattedDob = dob instanceof Date ? formatDateISO8601(dob) : dob

  return (
    <View style={pdfStyles.screenItem}>
      <View wrap={false}>
        <View style={pdfStyles.query}>
          <View style={{ flex: 4 }}>
            {name ? (
              <View style={pdfStyles.queryHeaderRow}>
                <Text style={pdfStyles.leftCell}>Name:</Text>
                <Text style={pdfStyles.rightCell}>
                  {insertNewlineAfter(name, lineWrapLength)}
                </Text>
              </View>
            ) : null}
            {location ? (
              <View style={pdfStyles.queryHeaderRow}>
                <Text style={pdfStyles.leftCell}>Address:</Text>
                <Text style={pdfStyles.rightCell}>{`${insertNewlineAfter(
                  location,
                  lineWrapLength
                )}`}</Text>
              </View>
            ) : null}
            {idNumber ? (
              <View style={pdfStyles.queryHeaderRow}>
                <Text style={pdfStyles.leftCell}>ID:</Text>
                <Text style={pdfStyles.rightCell}>
                  {insertNewlineAfter(idNumber, lineWrapLength)}
                </Text>
              </View>
            ) : null}
            {dob ? (
              <View style={pdfStyles.queryHeaderRow}>
                <Text style={pdfStyles.leftCell}>DOB:</Text>
                <Text style={pdfStyles.rightCell}>{formattedDob}</Text>
              </View>
            ) : null}
            {referenceId ? (
              <View style={pdfStyles.queryHeaderRow}>
                <Text style={pdfStyles.leftCell}>Reference ID:</Text>
                <Text style={pdfStyles.rightCell}>
                  {insertNewlineAfter(referenceId, lineWrapLength)}
                </Text>
              </View>
            ) : null}
          </View>
          <View style={pdfStyles.screenItemStatus}>
            <Text>Status</Text>
            <Text style={pdfStyles[statusStyle]}>
              {screenedEntityStatusLabels[status]}
            </Text>
          </View>
        </View>
        {hitElements.length > 0 && <View>{hitElements[0]}</View>}
      </View>
      {hitElements.length > 1 && <View>{hitElements.slice(1)}</View>}
    </View>
  )
}

export default Query
