import React from 'react'
import { styled } from '@mui/material/styles'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import FormControlLabel from '@mui/material/FormControlLabel'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import Checkbox from '@mui/material/Checkbox'

import { toTitleCase } from 'lib/utils'
import { TWorkflowConsumer } from 'components/lib/Workflow/workflowReducer'
import {
  THitClassification,
  THitClassificationReason
} from 'lib/features/Screening'

const PREFIX = 'Reason'

const classes = {
  root: `${PREFIX}-root`,
  formSpace: `${PREFIX}-formSpace`,
  formType: `${PREFIX}-formType`,
  formReasons: `${PREFIX}-formReasons`
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },

  [`& .${classes.formSpace}`]: {
    flex: 3
  },

  [`& .${classes.formType}`]: {
    margin: theme.spacing(1),
    flex: 1
  },

  [`& .${classes.formReasons}`]: {
    margin: theme.spacing(1),
    flex: 4
  }
}))

type TReasonMap = {
  [key in Exclude<THitClassification, 'unspecified'>]: {
    [key: string]: THitClassificationReason[]
  }
}

const reasonMap: TReasonMap = {
  ['true-positive']: {
    individual: [
      'Address match',
      'Date of birth match',
      'ID match',
      'Name match',
      'Place of birth match'
    ],
    entity: ['Address match', 'ID match', 'Name match'],
    vessel: ['Flag match', 'ID match', 'Name match', 'Vessel type match'],
    aircraft: ['Aircraft type match', 'ID match', 'Tail number match'],
    location: ['Address match']
  },
  ['false-positive']: {
    individual: [
      'Address is different',
      'Date of birth is different',
      'ID is different',
      'Partial name match',
      'Place of birth is different',
      'Individual matched with entity',
      'Individual matched with location',
      'Individual matched with payment message text',
      'Individual matched with vessel'
    ],
    entity: [
      'Address is different',
      'ID is different',
      'Partial name match',
      'Entity matched with aircraft',
      'Entity matched with individual',
      'Entity matched with payment message text',
      'Entity matched with vessel'
    ],
    vessel: [
      'ID is different',
      'Partial name match',
      'Vessel matched with aircraft',
      'Vessel matched with entity',
      'Vessel matched with individual',
      'Vessel matched with payment message text'
    ],
    aircraft: [
      'Aircraft type or model is different',
      'ID is different',
      'Tail number is a partial match',
      'Aircraft matched with entity',
      'Aircraft matched with individual',
      'Aircraft matched with vessel',
      'Aircraft matched with payment message text'
    ],
    location: [
      'Partial location match',
      'Location matched with aircraft',
      'Location matched with entity',
      'Location matched with individual',
      'Location matched with payment message text',
      'Location matched with vessel'
    ]
  },
  ['need-more-info']: {
    individual: [
      'Need address to analyze match',
      'Need date of birth to analyze match',
      'Need ID to analyze match',
      'Need name on government issued ID to to analyze match',
      'Need place of birth to analyze match'
    ],
    entity: [
      'Need full address to analyze match',
      'Need ID to analyze match',
      'Need ownership information to analyze match'
    ],
    vessel: [
      'Need ID to analyze match',
      'Need vessel flag to analyze match',
      'Need vessel type to analyze match',
      'Need year of manufacture to analyze match'
    ],
    aircraft: [
      'Need aircraft type to analyze match',
      'Need ID to analyze match'
    ],
    location: ['Need full address to analyze match']
  }
}

const Reason: React.FC<TWorkflowConsumer> = ({ state, dispatch }) => {
  const handleReasonSelect = (r: THitClassificationReason) => () => {
    const reasonSet = new Set(state.reasons)
    if (reasonSet.has(r)) {
      reasonSet.delete(r)
    } else {
      reasonSet.add(r)
    }

    dispatch({ type: 'SET_REASONS', payload: reasonSet })
  }

  const handleTypeSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const eType = e.target.value
    dispatch({ type: 'SET_MATCH_TYPE', payload: eType })
    dispatch({ type: 'SET_REASONS', payload: new Set() })
  }

  const title = 'Reasons'
  let validReasons = []
  switch (state.classification) {
    case 'true-positive':
      validReasons = reasonMap[state.classification][state.matchType]
      break
    case 'false-positive':
      validReasons = reasonMap[state.classification][state.matchType]
      break
    case 'need-more-info':
      validReasons = reasonMap[state.classification][state.matchType]
      break
    default:
      throw new Error(`Unrecognized classification: ${state.classification}`)
  }

  const typeRadioControls: JSX.Element[] = []
  const reasonRadioControls: JSX.Element[] = []

  // Get the possible entity type values from reasonMap
  Object.keys(reasonMap['true-positive']).forEach((t, i) => {
    const typeEnum = t
    typeRadioControls.push(
      <FormControlLabel
        key={i}
        value={typeEnum}
        control={<Radio color='primary' />}
        label={toTitleCase(typeEnum)}
      />
    )
  })

  validReasons.forEach((r: THitClassificationReason, i: number) => {
    reasonRadioControls.push(
      <FormControlLabel
        key={i}
        control={
          <Checkbox
            color='primary'
            checked={state.reasons.has(r)}
            onChange={handleReasonSelect(r)}
            value={r}
          />
        }
        label={r}
      />
    )
  })

  return (
    <Root className={classes.root}>
      <div className={classes.formSpace} />

      <FormControl
        component='fieldset'
        className={classes.formType}
        variant='standard'
      >
        <FormLabel component='legend'>Match Type</FormLabel>
        <RadioGroup value={state.matchType} onChange={handleTypeSelect}>
          {typeRadioControls}
        </RadioGroup>
      </FormControl>

      <FormControl
        component='fieldset'
        className={classes.formReasons}
        variant='standard'
      >
        <FormLabel component='legend'>{title}</FormLabel>
        {reasonRadioControls}
      </FormControl>
    </Root>
  )
}

export default Reason
