import React from 'react'

import { TNotificationVariant } from 'components/lib/Notification'

interface INotification {
  open: boolean
  message: string
  variant: TNotificationVariant
}

const defaults: INotification = {
  variant: 'info',
  open: false,
  message: ''
}

export const useNotification = (
  init?: Partial<INotification>
): [INotification, React.Dispatch<React.SetStateAction<INotification>>] => {
  const [notification, setNotification] = React.useState<INotification>({
    ...defaults,
    ...init
  })
  return [notification, setNotification]
}
