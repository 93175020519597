import { utils, WorkBook, writeFileXLSX } from 'xlsx'
import { formatDateISO8601 } from 'lib/utils'
import { TExportEntityItem } from 'components/lib/Reporting'
import {
  ALERT_TITLES,
  ALIAS_TITLES,
  LOCATION_TITLES,
  ADDITIONAL_TITLES,
  REFERENCE_ID_TITLE,
  SCREENING_INPUT_INDEX_TITLE,
  SCREEN_TITLES
} from './titles'

import {
  TListEntryDetails,
  TAliasDetails,
  TLocationDetails,
  TAdditionalDetails,
  TScreenDetails,
  TListEntriesWithScreen,
  TReportData
} from './types'

import {
  generateSheet,
  generateListEntryDetails,
  generateAliasDetails,
  generateLocationsDetails,
  generateAdditionalDetails
} from './utils'

export function generateData(data: TListEntriesWithScreen[]): TReportData {
  const alertRows: TListEntryDetails[] = []
  let aliasRows: TAliasDetails[] = []
  let locationRows: TLocationDetails[] = []
  let additionalRows: TAdditionalDetails[] = []

  data.forEach((screen) => {
    screen.entries.forEach((listEntry, index) => {
      const listEntryDetails = generateListEntryDetails(
        listEntry,
        index,
        (screen.screenEntityIndex || 0) + 1,
        screen.referenceId ?? ''
      )

      alertRows.push({ ...listEntryDetails })

      aliasRows = aliasRows.concat(
        generateAliasDetails(listEntry, listEntryDetails)
      )
      locationRows = locationRows.concat(
        generateLocationsDetails(listEntry, listEntryDetails)
      )
      additionalRows = additionalRows.concat(
        generateAdditionalDetails(listEntry, listEntryDetails)
      )
    })
  })

  return {
    alertRows,
    aliasRows,
    locationRows,
    additionalRows
  }
}

function prependTitles(titles: string[]) {
  return [REFERENCE_ID_TITLE, SCREENING_INPUT_INDEX_TITLE, ...titles]
}

function generateSheets(
  workbook: WorkBook,
  screenData: TScreenDetails[],
  sheetData: TReportData
) {
  generateSheet(
    workbook,
    'Screening Input',
    screenData,
    prependTitles(SCREEN_TITLES)
  )

  generateSheet(
    workbook,
    'Alerts',
    sheetData.alertRows,
    prependTitles(ALERT_TITLES)
  )
  generateSheet(
    workbook,
    'Aliases',
    sheetData.aliasRows,
    prependTitles(ALIAS_TITLES)
  )
  generateSheet(
    workbook,
    'Locations',
    sheetData.locationRows,
    prependTitles(LOCATION_TITLES)
  )
  generateSheet(
    workbook,
    'Additional Information',
    sheetData.additionalRows,
    prependTitles(ADDITIONAL_TITLES)
  )
}

function getDobString(dob?: Date | string): string {
  if (dob instanceof Date) {
    // Emulate the format input to the batch report
    return formatDateISO8601(dob)
  }

  return dob || ''
}

function generateScreenData(entities: TExportEntityItem[]) {
  return entities.map((entity) => {
    const screenEntity = entity.screenEntity

    return {
      [REFERENCE_ID_TITLE]: screenEntity.referenceId,
      [SCREENING_INPUT_INDEX_TITLE]: entity.index + 1,
      Name: screenEntity.name,
      Location: screenEntity.location,
      'ID Number': screenEntity.idNumber,
      DOB: getDobString(screenEntity.dob)
    }
  })
}

function generateListEntries(entities: TExportEntityItem[]) {
  return entities.map((entity) => {
    const entries = entity.screenEntity.hits.map((hit) =>
      hit.protobuf.getListEntry()
    )

    return {
      screenEntityIndex: entity.index,
      referenceId: entity.screenEntity.referenceId,
      entries
    }
  })
}

export function generateReport(
  title: string,
  filename: string,
  entities: TExportEntityItem[]
): void {
  /* Create worksheet from HTML DOM TABLE */
  const wb = utils.book_new()
  wb.Props = { Title: title }

  const screenData = generateScreenData(entities)
  const listEntries = generateListEntries(entities)
  const sheetData = generateData(listEntries)

  generateSheets(wb, screenData, sheetData)

  /* Export to file (start a download) */
  writeFileXLSX(wb, filename, {})
}
