import React from 'react'
import { Redirect } from 'react-router-dom'

import { CreateJobForm, EditJobForm } from './Form'
import { useGetScheduledJob } from 'lib/features/job'
import { WaveLoader } from 'components/lib/Loader'

export const CreateJobPage = (): JSX.Element => <CreateJobForm />

type TEditJobPageProps = {
  jobID: number
}

export const EditJobPage = ({ jobID }: TEditJobPageProps): JSX.Element => {
  const jobQry = useGetScheduledJob(jobID)

  if (jobQry.isSuccess) {
    return <EditJobForm job={jobQry.data} />
  } else if (jobQry.isLoading) {
    return <WaveLoader />
  } else {
    return <Redirect to='/error' />
  }
}
