import React from 'react'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import {
  getCountryName,
  getHitScore,
  getEntityMatchInformation
} from 'lib/entity'
import { insertNewlineAfter } from 'lib/helpers/pdf'
import { Location } from 'proto-js'
import { THit } from 'lib/features/Screening'
import { formatMediaDate } from 'lib/utils'

const LINE_WRAP_LENGTH = 85

const pdfStyles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  leftCell: {
    width: 65
  },
  locationList: {
    display: 'flex',
    flexDirection: 'column'
  },
  locationLabel: {
    textAlign: 'left',
    width: 50
  },
  locationValue: {
    textAlign: 'left',
    width: 400
  },
  rightCell: {
    width: 450
  }
})

const LocationRow: React.FC<{ value: string }> = ({ value }) => {
  if (value === '') {
    return null
  }

  return <Text style={pdfStyles.locationValue}>{value}</Text>
}

const LocationDetails: React.FC<{
  index: number
  location: typeof Location
}> = ({ index, location }) => {
  const address = location.getAddress()
  const city = location.getCity()
  const state = location.getState()
  const postal = location.getPostal()
  const region = location.getRegion()
  const country = location.getCountry()

  // Add some top padding when multiple matches
  const rowStyles = [pdfStyles.row, pdfStyles.locationList]
  if (index >= 1) {
    rowStyles.push({ paddingTop: 10 })
  }

  return (
    <View style={rowStyles}>
      <LocationRow value={address} />
      <LocationRow value={city} />
      <LocationRow value={state} />
      <LocationRow value={postal} />
      <LocationRow value={region} />
      {country !== undefined && <LocationRow value={getCountryName(country)} />}
    </View>
  )
}

const Row: React.FC<{
  children?: JSX.Element
  text?: string
  label: string
}> = ({ children, text, label }) => {
  if (!children && !text) {
    return null
  }

  return (
    <View style={pdfStyles.row}>
      <Text style={pdfStyles.leftCell}>{label}:</Text>
      {children || <Text style={pdfStyles.rightCell}>{text}</Text>}
    </View>
  )
}

export const AdverseMediaInfo: React.FC<{ hit: THit }> = ({
  hit: { protobuf }
}) => {
  const entry = protobuf.getListEntry()
  const name = entry.getUrl()
  const listSources = entry.getListSource().getName()
  const score = getHitScore(protobuf)
  const title = entry.getTitle()
  const snippet = entry.getSnippet().replaceAll(/<\/?span.*?>/g, '')
  const lastUpdated = entry.getLastUpdated()

  return (
    <View>
      <Row label='Name' text={insertNewlineAfter(name, LINE_WRAP_LENGTH)} />
      <Row label='Watchlist' text={listSources} />
      <Row label='Score' text={score.toString()} />
      <Row label='Title' text={insertNewlineAfter(title, LINE_WRAP_LENGTH)} />
      <Row label='Abstract' text={snippet} />
      {lastUpdated && (
        <Row label='Date' text={formatMediaDate(lastUpdated.toDate())} />
      )}
    </View>
  )
}

const EntityInfo: React.FC<{ hit: THit }> = ({ hit }) => {
  const name = hit.protobuf.getListEntry().getName().getFullName()
  const listSources = hit.protobuf.getListEntry().getListSource().getName()
  const additionalHitInfo = getEntityMatchInformation(hit.protobuf)
  const score = getHitScore(hit.protobuf)

  const addressDetails = additionalHitInfo.addresses.map((address, index) => (
    <LocationDetails
      key={`address-${index}`}
      location={address}
      index={index}
    />
  ))

  const pobDetails = additionalHitInfo.pobs.map((pob, index) => (
    <LocationDetails key={`pob-${index}`} location={pob} index={index} />
  ))

  const idNumber =
    (additionalHitInfo.id && additionalHitInfo.id.getNumber()) || ''
  const names = additionalHitInfo.names
    .map((name) => name.getFullName())
    .join(', ')

  return (
    <View>
      <Row label='Name' text={insertNewlineAfter(name, LINE_WRAP_LENGTH)} />
      <Row label='Watchlist' text={listSources} />
      <Row label='Score' text={score.toString()} />
      <Row label='ID' text={insertNewlineAfter(idNumber, LINE_WRAP_LENGTH)} />
      <Row label='Alias' text={insertNewlineAfter(names, LINE_WRAP_LENGTH)} />
      <Row label='Location'>
        {addressDetails.length > 0 && (
          <View style={pdfStyles.rightCell}>{addressDetails}</View>
        )}
      </Row>
      <Row label='POB'>
        {pobDetails.length > 0 && (
          <View style={pdfStyles.rightCell}>{pobDetails}</View>
        )}
      </Row>
    </View>
  )
}

export default EntityInfo
