import React from 'react'
import { styled } from '@mui/material/styles'

import Header from 'components/lib/Header'
import Footer from 'components/lib/Footer'
import { Banner } from './Banner'
import SearchForm from './SearchForm'
import GlobeSVG from 'media/globe'

const PREFIX = 'HomePageContainer'

const classes = {
  root: `${PREFIX}-root`,
  landing: `${PREFIX}-landing`,
  banner: `${PREFIX}-banner`,
  formContainer: `${PREFIX}-formContainer`,
  form: `${PREFIX}-form`,
  globeContainer: `${PREFIX}-globeContainer`,
  globe: `${PREFIX}-globe`,
  content: `${PREFIX}-content`
}

const StyledHomePage = styled('div')(() => ({
  [`&.${classes.root}`]: {
    minHeight: 'inherit',
    width: '100%'
  },
  [`& .${classes.landing}`]: {
    minHeight: 'inherit',
    overflow: 'hidden'
  },
  [`& .${classes.banner}`]: {
    paddingTop: '25vh',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    zIndex: 1
  },
  [`& .${classes.formContainer}`]: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 2
  },
  [`& .${classes.form}`]: {
    width: '45vw',
    minWidth: '300px'
  },
  [`& .${classes.globeContainer}`]: {
    height: '60vw',
    minHeight: '400px',
    minWidth: '400px',
    position: 'absolute',
    right: '0px',
    width: '60vw',
    whiteSpace: 'nowrap',
    zIndex: -1
  },
  [`& .${classes.globe}`]: {
    paddingLeft: '5vw',
    height: '100%',
    width: '100%'
  },
  [`& .${classes.content}`]: {
    minHeight: 'inherit',
    position: 'relative',
    zIndex: 3,
    backgroundImage: `url(${process.env.REACT_APP_CDN_PATH}/media/background.svg)`
  }
}))

const HomePageContainer: React.FC = () => {
  return (
    <StyledHomePage className={classes.root}>
      <Header />
      <a id='home' href='#home'>
        <p style={{ display: 'hidden' }} />
      </a>
      <div className={classes.landing}>
        <div className={classes.globeContainer}>
          <GlobeSVG className={classes.globe} />
        </div>

        <div className={classes.banner}>
          <Banner />
        </div>

        <div className={classes.formContainer}>
          <div className={classes.form}>
            <SearchForm />
          </div>
        </div>
      </div>
      <Footer />
    </StyledHomePage>
  )
}

export default HomePageContainer
