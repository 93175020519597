import React from 'react'

import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

const MISSING_ARTICLE_DEFAULT_TEXT = 'See source URL for full article.'

// The source data has newlines instead of <br> elements. The 'pre-line' CSS setting renders them
// like <br>s.
const StyledArticleBody = styled(Typography)(() => ({
  whiteSpace: 'pre-line'
}))

type TAdverseMediaPaneProps = {
  title: string
  snippet: string
  article?: string
  url: string
}

export const AdverseMediaPane = ({
  title,
  snippet,
  article,
  url
}: TAdverseMediaPaneProps): JSX.Element => (
  <Grid container direction='column' spacing={2}>
    <Grid item>
      <Typography variant='h5'>{title}</Typography>
    </Grid>
    {!article && (
      <Grid item>
        <StyledArticleBody
          variant='body2'
          display='inline'
          dangerouslySetInnerHTML={{
            __html: snippet
          }}
        />
      </Grid>
    )}
    <Grid item>
      <StyledArticleBody
        variant='body2'
        display='inline'
        dangerouslySetInnerHTML={{
          __html: article || MISSING_ARTICLE_DEFAULT_TEXT
        }}
      />
    </Grid>
    <Grid item>
      <Typography variant='subtitle1'>SOURCE URL</Typography>
    </Grid>
    <Grid item>
      <Typography variant='body2' display='inline'>
        <Link target='_blank' href={url}>
          {url}
        </Link>
      </Typography>
    </Grid>
  </Grid>
)

export default AdverseMediaPane
