import { utils, WorkBook } from 'xlsx'
import { getTypeName, dateRangeToString } from 'lib/entity'
import { List, Name, ID, Location, ListEntry } from 'proto-js'
import { ListValue } from 'google-protobuf/google/protobuf/struct_pb.js'
import { toTitleCase } from 'lib/utils'
import { REFERENCE_ID_TITLE, SCREENING_INPUT_INDEX_TITLE } from './titles'

import {
  TListEntryDetails,
  TAliasDetails,
  TLocationDetails,
  TLocationInfo,
  TAdditionalDetails,
  TScreenDetails
} from './types'

export function generateListEntryDetails(
  listEntry: typeof ListEntry,
  index: number,
  screenEntityIndex?: number,
  referenceId?: string
): TListEntryDetails {
  const listSource = listEntry.getListSource()

  const baseDetails = {
    'Alert Index': index + 1,
    ID: listEntry.getProviderId(),
    'List Source': listSource.getName(),
    Category: listSource.getCategory().getName(),
    Lists: listSource
      .getListsList()
      .map((list: typeof List) => list.getName())
      .join(' | '),
    Type: toTitleCase(getTypeName(listEntry)),
    'Primary Name': listEntry.getName().getFullName()
  }

  return {
    ...(referenceId !== undefined && {
      [REFERENCE_ID_TITLE]: referenceId
    }),
    ...(screenEntityIndex && {
      [SCREENING_INPUT_INDEX_TITLE]: screenEntityIndex
    }),
    ...baseDetails
  }
}

function generateIDDetails(
  isPrimary: boolean,
  alias: typeof Name,
  id?: typeof ID
) {
  return {
    Alias: alias.getFullName(),
    Primary: isPrimary,
    'Low Quality': alias.getLowQuality(),
    'ID Type': id?.getType() || '',
    'ID Number': id?.getNumber() || '',
    'Issue Date': dateRangeToString(id?.getIssueDate()) || '',
    'Expiration Date': dateRangeToString(id?.getExpirationDate()) || ''
  }
}
export function generateIDsDetails(
  isPrimary: boolean,
  alias: typeof Name,
  listEntryDetails: TListEntryDetails
): (TListEntryDetails & TAliasDetails)[] {
  const rowData: TAliasDetails[] = []
  const ids = alias.getIdsList()

  if (ids.length === 0) {
    rowData.push({
      ...listEntryDetails,
      ...generateIDDetails(isPrimary, alias, undefined)
    })
  }

  ids.forEach((id: typeof ID) => {
    rowData.push({
      ...listEntryDetails,
      ...generateIDDetails(isPrimary, alias, id)
    })
  })

  return rowData
}

export function generateAliasDetails(
  listEntry: typeof ListEntry,
  listEntryDetails: TListEntryDetails
): TAliasDetails[] {
  const primaryAliasRow = generateIDsDetails(
    true,
    listEntry.getName(),
    listEntryDetails
  )

  let aliasRowData: TAliasDetails[] = []
  listEntry
    .getAliasesList()
    .forEach(
      (alias: typeof Name) =>
        (aliasRowData = aliasRowData.concat(
          generateIDsDetails(false, alias, listEntryDetails)
        ))
    )

  return primaryAliasRow.concat(aliasRowData)
}

function generateLocationInfo(
  type: 'Address' | 'POB',
  location: typeof Location
): TLocationInfo {
  return {
    'Location Type': type,
    Address: location.getAddress(),
    City: location.getCity(),
    State: location.getState(),
    Post: location.getPostal(),
    Region: location.getRegion(),
    Country: location.getCountry()
  }
}

export function generateLocationsDetails(
  listEntry: typeof ListEntry,
  listEntryDetails: TListEntryDetails
): TLocationDetails[] {
  const rowData: TLocationDetails[] = []
  const addresses = listEntry.getAddressesList()
  const pobs = listEntry.getPobsList()

  addresses.forEach((address: typeof Location) => {
    rowData.push({
      ...listEntryDetails,
      ...generateLocationInfo('Address', address)
    })
  })

  pobs.forEach((pob: typeof Location) => {
    rowData.push({
      ...listEntryDetails,
      ...generateLocationInfo('POB', pob)
    })
  })

  return rowData
}

export function generateAdditionalDetails(
  listEntry: typeof ListEntry,
  listEntryDetails: TListEntryDetails
): TAdditionalDetails[] {
  const rowData: TAdditionalDetails[] = []
  const catchAll: Map<string, ListValue> = listEntry.getCatchAllMap()

  catchAll.forEach((v, k) => {
    const values = v.getValuesList().map((v) => v.toJavaScript())

    rowData.push({
      ...listEntryDetails,
      'Field Name': k,
      'Field Value': values.join(', ')
    })
  })

  return rowData
}

export function generateSheet(
  wb: WorkBook,
  title: string,
  data: (
    | TListEntryDetails
    | TAliasDetails
    | TLocationDetails
    | TAdditionalDetails
    | TScreenDetails
  )[],
  titles: string[]
): void {
  const sheet = utils.json_to_sheet(data)
  wb.SheetNames.push(title)
  wb.Sheets[title] = sheet

  utils.sheet_add_aoa(sheet, [titles], {
    origin: 'A1'
  })
}
