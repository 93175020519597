import { useGRPC } from 'lib/hooks'

import {
  SearchScreenedEntitiesRequest,
  SearchScreenedEntitiesResponse,
  ScreenedEntity
} from 'proto-js'
import {
  TSearchScreenedEntitiesResults,
  TAuditTrailSearchParams
} from 'lib/features/audit'
import ProtoTimestamp from 'google-protobuf/google/protobuf/timestamp_pb.js'

export const prepareRPCRequest = (
  params: TAuditTrailSearchParams
): typeof SearchScreenedEntitiesRequest => {
  const request = new SearchScreenedEntitiesRequest()
  request.setName(params.name)
  request.setIdNumber(params.idNumber)

  if (params.startDate) {
    request.setStartDate(ProtoTimestamp.Timestamp.fromDate(params.startDate))
  }

  if (params.endDate) {
    request.setEndDate(ProtoTimestamp.Timestamp.fromDate(params.endDate))
  }

  request.setPageNumber(params.pageNumber)
  request.setPageSize(params.pageSize)

  return request
}

export const prepareRPCResponse = (
  resp: typeof SearchScreenedEntitiesResponse
): TSearchScreenedEntitiesResults => {
  const entities = resp
    .getScreenedEntitiesList()
    .map((entity: typeof ScreenedEntity) => ({
      id: entity.getId(),
      screenId: entity.getScreenId(),
      ranAt: entity.getRanAt().toDate(),
      screenType: entity.getScreenType(),
      minScore: entity.getMinScore(),
      name: entity.getName(),
      idNumber: entity.getIdNumber(),
      location: entity.getLocation(),
      referenceId: entity.getReferenceId()
    }))

  return {
    entities: entities,
    total: resp.getTotal()
  }
}

export type TSearchScreenedEntitiesResponse = ReturnType<
  typeof prepareRPCResponse
>

type TSearchScreenedEntitiesRPC = (
  ...args: Parameters<typeof prepareRPCRequest>
) => Promise<ReturnType<typeof prepareRPCResponse>>

/**
 * useSearchScreenedEntitiesRPC provides an RPC to fetch results for screen entity search.
 *
 * @returns {TSearchScreenedEntitiesRPC} - An async function to invoke the RPC.
 */
export const useSearchScreenedEntitiesRPC = (): TSearchScreenedEntitiesRPC => {
  const { workflowPromiseService } = useGRPC()

  return async (...args: Parameters<typeof prepareRPCRequest>) => {
    return workflowPromiseService
      .searchScreenedEntities(prepareRPCRequest(...args), {})
      .then(prepareRPCResponse)
  }
}
