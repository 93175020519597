import React from 'react'
import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View
} from '@react-pdf/renderer'
import { User } from '@auth0/auth0-spa-js'

import PageNumber from './Footer'
import Query from './Query'
import Summary from './Summary'
import Header, { Subheader } from './Header'
import CategorySummary from './CategorySummary'
import ListSourceSummary from './ListSourceSummary'
import { TBrandingQuery } from 'lib/hooks/useBranding'
import { ListSourcesState, AuditTrailData } from '../types'

import {
  TAdHocScreenData,
  TScreenedEntity,
  TPersistedScreenedEntity,
  TScreenedEntityStatus
} from 'lib/features/Screening'

const pdfStyles = StyleSheet.create({
  page: {
    paddingTop: 20,
    paddingBottom: 30,
    paddingHorizontal: 30,
    fontSize: 12,
    fontFamily: 'Roboto'
  },
  pageWithoutFooterImage: {
    paddingBottom: 45
  },
  image: {
    position: 'absolute',
    left: 0,
    height: 850
  },
  arabic: {
    fontFamily: 'Arabic'
  },
  korean: {
    fontFamily: 'Korean'
  },
  chinese: {
    fontFamily: 'Chinese'
  }
})

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: `${process.env.REACT_APP_CDN_PATH}/media/Roboto/Roboto-Regular.ttf`
    },
    {
      src: `${process.env.REACT_APP_CDN_PATH}/media/Roboto/Roboto-Light.ttf`,
      fontWeight: 300
    },
    {
      src: `${process.env.REACT_APP_CDN_PATH}/media/Roboto/Roboto-Bold.ttf`,
      fontWeight: 700
    }
  ]
})

// For the PDF to work, it is assumed we will have defined fonts named
// Arabic, Chinese and Korean
Font.register({
  family: 'Arabic',
  src: `${process.env.REACT_APP_CDN_PATH}/media/fonts/Janna%20LT%20Regular.ttf`
})

Font.register({
  family: 'Chinese',
  src: `${process.env.REACT_APP_CDN_PATH}/media/fonts/SimSun.ttf`
})

Font.register({
  family: 'Korean',
  src: `${process.env.REACT_APP_CDN_PATH}/media/fonts/NotoSansKR-Regular.ttf`
})

// Don't break words - it looks weird for the list names
const hyphenationCallback = (word: string) => [word]

Font.registerHyphenationCallback(hyphenationCallback)

const SORT_PRIORITY: Record<TScreenedEntityStatus, number> = {
  flagged: 1,
  wip: 2,
  incomplete: 3,
  resolved: 4,
  cleared: 5
}

const sortByStatus = (
  a: TScreenedEntity | TPersistedScreenedEntity,
  b: TScreenedEntity | TPersistedScreenedEntity
): number => {
  const aSortPriority = a.status ? SORT_PRIORITY[a.status] : 0
  const bSortPriority = b.status ? SORT_PRIORITY[b.status] : 0

  return aSortPriority - bSortPriority
}

interface ReportProps {
  branding: TBrandingQuery
  listSourcesState: ListSourcesState
  auditTrailData: AuditTrailData
  screenData: TAdHocScreenData
  title: string
  user: User | undefined
}

const PDFReport: React.FC<ReportProps> = ({
  branding,
  listSourcesState,
  auditTrailData,
  screenData,
  title,
  user
}) => {
  const entitiesSortedByStatus = [...screenData.entities].sort(sortByStatus)

  const queryElements = entitiesSortedByStatus.map((entity, index) => (
    <Query key={index} screenEntity={entity} auditTrailData={auditTrailData} />
  ))

  return (
    <Document>
      <Page size='A4' style={pdfStyles.page}>
        <View>
          <Text style={pdfStyles.arabic} />
          <Text style={pdfStyles.korean} />
          <Text style={pdfStyles.chinese} />
        </View>
        <Header branding={branding} />
        <Subheader title={title} user={user} />
        <Summary screenData={screenData} />
        <CategorySummary listSourcesState={listSourcesState} />
        <PageNumber />
      </Page>
      <Page
        size='A4'
        style={[pdfStyles.page, pdfStyles.pageWithoutFooterImage]}
      >
        <Header branding={branding} />
        <Subheader title={title} user={user} />
        {queryElements}
        <PageNumber />
      </Page>
      <Page size='A4' style={[pdfStyles.page, { paddingBottom: 36 }]}>
        <Header branding={branding} />
        <Subheader title={title} user={user} />
        <ListSourceSummary listSourcesState={listSourcesState} />
        <PageNumber />
      </Page>
    </Document>
  )
}

export default React.memo(PDFReport)
