import React from 'react'

import { styled } from '@mui/material/styles'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { Link as RouterLink } from 'react-router-dom'
import Launch from '@mui/icons-material/Launch'
import Work from '@mui/icons-material/Work'

import { toTitleCase } from 'lib/utils'
import IDsTable, { WrappedHighlight } from './IDsTable'

import UpgradeLink from 'components/lib/Auth/UpgradeLink'
import { ID, ListEntry, Name, Relationship } from 'proto-js'
import { Can } from 'lib/authorization'
import { EMPTY_DISPLAY_NAME } from 'lib/constants/entities'

const PaneContent = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingLeft: theme.spacing(2)
}))

/**
 * Parses an entity's relationships information and returns two lists of names to render, one for
 * connections i.e. associates without a direct relation type, and one for close associates i.e.
 * associates with a direct relation type.
 *
 * @param {typeof ListEntry} entity - The ListEntry protobuf message.
 * @returns {[ListItem[], ListItem[]]} - Two lists of ListItem components for connections and close
 *  associates
 */
const getRelationshipsLists = (
  entity: typeof ListEntry,
  listSourceQueryString: string
) => {
  const connections: React.ReactElement[] = []
  const isUBO = entity.getListSource().getName().includes('UBO')
  const tooltipText = isUBO ? 'Elevated Risk Entry' : 'Root PEP'

  entity
    .getRelationshipsList()
    .forEach((relationship: typeof Relationship, i: number) => {
      const relation = relationship.getRelation()
      const associateName = relationship.getName()
      if (associateName && associateName !== '') {
        connections.push(
          <ListItem key={i}>
            <ListItemText
              primary={
                <Typography variant='subtitle2'>
                  {relationship.getElevatedStatus() && (
                    <Work
                      fontSize='inherit'
                      titleAccess={tooltipText}
                      htmlColor='red'
                    />
                  )}

                  <Link
                    component={RouterLink}
                    target='_blank'
                    to={`/search?name=${encodeURIComponent(
                      associateName
                    )}${listSourceQueryString}&expand=true`}
                    underline='hover'
                  >
                    <>
                      {associateName}
                      <sup>
                        <Launch
                          titleAccess='search in new tab'
                          fontSize='inherit'
                        />
                      </sup>
                    </>
                  </Link>
                  {relation && (
                    <Typography variant='body2' display='inline'>
                      {' '}
                      - ({toTitleCase(relation)})
                    </Typography>
                  )}
                </Typography>
              }
            />
          </ListItem>
        )
      }
    })

  return [connections]
}

type TAliasInformation = {
  name: typeof Name
  ids: typeof ID[]
}

const AliasInformation = ({ name, ids }: TAliasInformation) => {
  const displayName = name.getFullName() || EMPTY_DISPLAY_NAME

  return (
    <>
      <ListItem>
        <ListItemText
          primary={
            <Typography variant='subtitle2'>
              <WrappedHighlight str={displayName} />
              {name.getLowQuality() && (
                <Typography variant='body2' display='inline'>
                  {' - (Weak AKA)'}
                </Typography>
              )}
            </Typography>
          }
        />
      </ListItem>
      {ids.length > 0 && <IDsTable ids={ids} />}
    </>
  )
}

interface ProfilePaneProps {
  entity: typeof ListEntry
  listSourceQueryString: string
}
/**
 * Component containing the contents of the 'Profile' tab of the ListEntry Card. This includes and
 * entities aliases and information about relationships.
 *
 * @param {typeof ListEntry} entity - The ListEntry protobuf message.
 */
const ProfilePane = ({
  entity,
  listSourceQueryString = ''
}: ProfilePaneProps): JSX.Element => {
  const [connections] = getRelationshipsLists(entity, listSourceQueryString)
  let idsPresent = false
  const aliasesListItems = [entity.getName()]
    .concat(entity.getAliasesList())
    .map((alias: typeof Name, i: number) => {
      const idList = alias.getIdsList()

      if (idList.length > 0) idsPresent = true

      return <AliasInformation key={i} name={alias} ids={idList} />
    })

  return (
    <PaneContent>
      {aliasesListItems.length > 0 && (
        <>
          {idsPresent && (
            <Can not I='view' a='Screen' field='id'>
              <Typography align='center'>
                <UpgradeLink additionalText='to see additional ID information.' />
              </Typography>
            </Can>
          )}
          <Typography variant='subtitle1'>ALIASES</Typography>
          <List dense>{aliasesListItems}</List>
        </>
      )}
      {connections.length > 0 && (
        <>
          <Typography variant='subtitle1'>CONNECTED TO</Typography>
          <List dense>{connections}</List>
        </>
      )}
    </PaneContent>
  )
}

export default ProfilePane
