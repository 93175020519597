import React from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import DownloadIcon from '@mui/icons-material/Download'

import { TScreenedEntityListItem } from 'components/lib/ScreenedEntitiesList'

import { Can } from 'lib/authorization/authorizationContext'
import { SupportDialog } from 'components/lib/SupportDialog'
import { generateReport } from 'lib/reporting/xlsx'
import { reportDate } from 'components/lib/Reporting/utils'
import { trackEvent } from 'lib/analytics/ga4'

interface IExportButtonProps extends ButtonProps {
  reportType: string
  entityItems: TScreenedEntityListItem[]
}

const ExportButton: React.FC<IExportButtonProps> = ({
  entityItems,
  reportType,
  ...buttonProps
}) => {
  const generateExcel = () => {
    trackEvent('file_download', {
      subject: 'hits',
      type: 'data'
    })

    generateReport(
      reportType,
      `Screening Report ${reportDate()}.xlsx`,
      entityItems
    )
  }

  return (
    <Can I='view' a='XLSX' passThrough>
      {(can: boolean) => (
        <SupportDialog
          buttonComponent={(onClick) => (
            <Button
              color='grey'
              variant='contained'
              data-cy='download-excel-button'
              onClick={can ? generateExcel : onClick}
              {...buttonProps}
            >
              Results <DownloadIcon />
            </Button>
          )}
        />
      )}
    </Can>
  )
}

export default ExportButton
