import React, { useMemo, useState, useEffect } from 'react'
import { UseQueryResult } from 'react-query'
import Checkbox from '@mui/material/Checkbox'
import Fade from '@mui/material/Fade'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { toTitleCase } from 'lib/utils'
import { useField } from 'formik'

interface EntityTypesSelectProps {
  name: string
  entityTypesData: UseQueryResult<string[]>
}

export const EntityTypesSelect: React.FC<EntityTypesSelectProps> = ({
  entityTypesData,
  name
}) => {
  const [selected, setSelected] = useState<{ [key: string]: boolean }>({})
  const [selectAll, setSelectAll] = useState(true)
  const [field, , helpers] = useField(name)

  useEffect(() => {
    if (entityTypesData.status === 'success') {
      const tempSelected: { [key: string]: boolean } = {}
      for (const entityType of entityTypesData.data) {
        tempSelected[entityType] = true
      }
      helpers.setValue(
        Object.keys(tempSelected).filter((et) => tempSelected[et])
      )
      setSelected(tempSelected)
    }
  }, [entityTypesData.data, entityTypesData.status])

  const entityTypesMenuItems = useMemo(() => {
    const menuItems = []
    menuItems.push(
      <MenuItem key={'type=All}'} value='ALL' dense divider>
        <Checkbox
          color='primary'
          checked={Object.keys(selected).length === field.value.length}
        />
        <Typography variant='subtitle2'>All</Typography>
      </MenuItem>
    )

    if (entityTypesData.status === 'success') {
      for (const entityType of entityTypesData.data) {
        menuItems.push(
          <MenuItem key={`type=${entityType}`} value={entityType} dense>
            <Checkbox color='primary' checked={selected[entityType]} />
            <Typography variant='subtitle2'>
              {toTitleCase(entityType)}
            </Typography>
          </MenuItem>
        )
      }
    }

    return menuItems
  }, [
    field.value.length,
    entityTypesData.data,
    entityTypesData.status,
    selected
  ])

  return (
    <TextField
      data-cy='entity-select'
      select
      variant='standard'
      size='small'
      fullWidth
      value={[]}
      label='Type'
      error={entityTypesData.status === 'error'}
      SelectProps={{
        multiple: true,
        onChange: (e) => {
          const et = (e.target.value as string[])[0]
          let newSelected: { [key: string]: boolean }
          if (et === 'ALL') {
            setSelectAll((selectAll) => !selectAll)
            newSelected = Object.assign(
              {},
              ...Object.keys(selected).map((k) => ({ [k]: !selectAll }))
            )
          } else {
            newSelected = { ...selected, [et]: !selected[et] }
          }

          setSelectAll(
            Object.keys(newSelected).length ===
              Object.keys(newSelected).filter((et) => newSelected[et]).length
          )
          setSelected(newSelected)
          helpers.setValue(
            Object.keys(newSelected).filter((et) => newSelected[et])
          )
        },
        MenuProps: {
          TransitionComponent: Fade,
          TransitionProps: {
            timeout: 200
          }
        }
      }}
    >
      {entityTypesMenuItems}
    </TextField>
  )
}

export default EntityTypesSelect
