import React from 'react'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import { useNotification } from 'lib/notification'
import { useDeleteScheduledJob, TPersistedScheduledJob } from 'lib/features/job'
import { trackEvent } from 'lib/analytics/ga4'

type TDeleteButtonWithConfirmation = {
  open: boolean
  onClose: () => void
  setOpen: (newValue: boolean) => void
  job: TPersistedScheduledJob
}

const DeleteButtonWithConfirmation: React.FC<TDeleteButtonWithConfirmation> = ({
  open,
  onClose,
  setOpen,
  job
}) => {
  const { name, id } = job
  const deleteScheduledJob = useDeleteScheduledJob(id)
  const setNotification = useNotification()
  const handleClose = () => {
    onClose()
  }
  const handleOpen = () => {
    setOpen(true)
  }

  const showNotification = () => {
    setNotification({
      message: 'Scheduled screen deleted',
      variant: 'success'
    })
  }

  const handleDelete = () => {
    trackEvent('delete_scheduled_job_submitted')
    deleteScheduledJob.mutate(
      {
        id: id
      },
      {
        onSuccess: showNotification
      }
    )
    setOpen(false)
  }

  return (
    <>
      <IconButton
        aria-label='delete job'
        size='small'
        data-cy='delete-job-button'
        onClick={handleOpen}
      >
        <DeleteForeverIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'Are you sure you want to delete the job: ' + name + '?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Once deleted, this job will no longer be run and you will no longer
            be able to access job history. Only audit records will remain.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DeleteButtonWithConfirmation
