import { TScheduledJob } from 'lib/features/job'
import { useGRPC } from 'lib/hooks'
import { scheduleToProtobuf, jobFromProtobuf } from './utils'
import {
  File,
  PostScheduledJobRequest,
  PostScheduledJobResponse,
  ScheduledJob
} from 'proto-js'

export type TPostScheduledJobRequest = {
  job: TScheduledJob
  file: File
}

export const prepareRPCRequest = async (
  request: TPostScheduledJobRequest
): Promise<typeof PostScheduledJobRequest> => {
  const job = new ScheduledJob()
  job.setName(request.job.name)
  job.setSchedule(scheduleToProtobuf(request.job.schedule))
  job.setFilename(request.job.filename)
  if (request.job.listSourceCategoryIDs) {
    job.setListSourceCategoryIdsList(request.job.listSourceCategoryIDs)
  }
  if (request.job.listSourceIDs) {
    job.setListSourceIdsList(request.job.listSourceIDs)
  }
  if (request.job.listIDs) {
    job.setListIdsList(request.job.listIDs)
  }
  job.setMinScore(request.job.minScore)
  job.setExcludeWeakAkas(!request.job.weakAKAs)
  job.setDobMonthsRange(request.job.dobMonthsRange)

  const file = new File()
  file.setBytes(await request.file.arrayBuffer().then((b) => new Uint8Array(b)))
  const rpcRequest = new PostScheduledJobRequest()
  rpcRequest.setJob(job)
  rpcRequest.setFile(file)

  return rpcRequest
}

export type TPostScheduledJobResponse = Persisted<TScheduledJob>

export const prepareRPCResponse = (
  resp: typeof PostScheduledJobResponse
): TPostScheduledJobResponse => jobFromProtobuf(resp.getJob())

type TPostScheduledJobRPC = (
  params: TPostScheduledJobRequest
) => Promise<TPostScheduledJobResponse>

/**
 * usePostScheduledJobRPC provides an async function to invoke the PostScheduledJob RPC.
 *
 * @returns {TPostScheduledJobRPC} - An async function to invoke the RPC.
 */
export const usePostScheduledJobRPC = (): TPostScheduledJobRPC => {
  const { workflowPromiseService } = useGRPC()
  return async (params: TPostScheduledJobRequest) =>
    await prepareRPCRequest(params).then(async (req) => {
      return workflowPromiseService
        .postScheduledJob(req, {})
        .then(prepareRPCResponse)
    })
}
