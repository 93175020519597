import { TPersistedScheduledJob } from 'lib/features/job'
import { Hit } from 'proto-js'

export type TScreenType = 'advanced' | 'batch' | 'scheduled'

export type TScreenedEntityStatus =
  | 'incomplete'
  | 'cleared'
  | 'flagged'
  | 'resolved'
  | 'wip'

export type THitClassification =
  | 'true-positive'
  | 'false-positive'
  | 'need-more-info'

// TScreenParameters represents the screen level query parameters.
export type TScreenParameters = {
  listSourceCategories?: string[]
  listSources?: { [key: string]: string[] }
  scoreThreshold: number
  weakAKAs: boolean
  dob?: Date
  dobMonthsRange: number
}

// TPersistedScreen encapsulates information about a screen that's been queried from our back-end.
export type TPersistedScreen = {
  id: number
  jobID?: number
  screenType: TScreenType
  ranAt: Date
  listIDs: number[]
  scoreThreshold: number
  weakAKAs: boolean
  dobMonthsRange: number
  screenedEntitiesCount: number
  screenedEntitiesFlaggedCount: number
  reviewCompleted: boolean
}

// TPersistedScreenData encapsulates data about a TPersistedScreen as well as underlying entities
// that were screened and optionally job information if associated with a job.
export type TPersistedScreenData = {
  job?: TPersistedScheduledJob
  screen: TPersistedScreen
  entities: TPersistedScreenedEntity[]
}

// TAdHocScreen encapsulates information about a screen actively being performed in the app via
// advanced search or batch search.
export type TAdHocScreen = {
  screenType: TScreenType
  ranAt: Date
  parameters: TScreenParameters
}

// TAdHocScreenData encapsulates data about a TAdHocScreen and underlying entities screened.
export type TAdHocScreenData = {
  screen: TAdHocScreen
  entities: TScreenedEntity[]
}

// TPersistedAdHocScreenData is a more narrow type of TAdHocScreenData which has been posted to the
// back-end and has an ID.
export type TPersistedAdHocScreenData = {
  screen: Persisted<TAdHocScreen>
  entities: (TScreenedEntity | TPersistedScreenedEntity)[]
}

// TScreenData is a common interface for the different screen data states.
export type TScreenData =
  | TPersistedScreenData
  | TAdHocScreenData
  | TPersistedAdHocScreenData

// TScreenEntity represents a single entity to be screened.
export type TScreenEntity = {
  name?: string
  idNumber?: string
  location?: string
  dob?: Date
  referenceId?: string
}

// TScreenedEntity represents a single entity that has been screened.
export type TScreenedEntity = TScreenEntity & {
  totalHits: number
  hits: THit[]
  status: TScreenedEntityStatus
}

// TPersistedScreenedEntity represents a single entity that has been screened and persisted to the
// back-end.
export type TPersistedScreenedEntity = Persisted<
  Omit<TScreenedEntity, 'hits' | 'dob'> & {
    hits: Persisted<THit>[]
    dob?: string
  }
>

export const isPersistedScreenedEntity = (
  screenedEntity: TScreenedEntity | TPersistedScreenedEntity
): screenedEntity is TPersistedScreenedEntity => {
  return (screenedEntity as TPersistedScreenedEntity).id !== undefined
}

// THit represents a single hit on a screen entity that may or may not have been classified. It
// encapsulatews the protobuf Hit object type.
export type THit = {
  protobuf: typeof Hit
  classification?: THitClassification
}

// THitClassificationReason is a set of reasons a that can be selected when classifying a hit.
export type THitClassificationReason =
  | 'Unknown'
  // --- True Positive
  | 'Name match'
  | 'Place of birth match'
  | 'Address match'
  | 'ID match'
  | 'Date of birth match'
  | 'Vessel type match'
  | 'Flag match'
  | 'Tail number match'
  | 'Aircraft type match'
  // --- False Positive Common
  | 'Address is different'
  | 'Partial name match'
  | 'ID is different'
  // --- False Positive Individual
  | 'Date of birth is different'
  | 'Place of birth is different'
  | 'Individual matched with entity'
  | 'Individual matched with payment message text'
  | 'Individual matched with location'
  | 'Individual matched with vessel'
  // --- False Positive Enitity
  | 'Entity matched with individual'
  | 'Entity matched with vessel'
  | 'Entity matched with aircraft'
  | 'Entity matched with payment message text'
  // --- False Positive Vessel
  | 'Vessel matched with individual'
  | 'Vessel matched with entity'
  | 'Vessel matched with aircraft'
  | 'Vessel matched with payment message text'
  // --- False Positive Aircraft
  | 'Tail number is a partial match'
  | 'Aircraft type or model is different'
  | 'Aircraft matched with individual'
  | 'Aircraft matched with entity'
  | 'Aircraft matched with vessel'
  | 'Aircraft matched with payment message text'
  // --- False Positive Location
  | 'Location matched with individual'
  | 'Location matched with entity'
  | 'Location matched with vessel'
  | 'Location matched with aircraft'
  | 'Location matched with payment message text'
  | 'Partial location match'
  // --- Need More Information Individual
  | 'Need date of birth to analyze match'
  | 'Need place of birth to analyze match'
  | 'Need address to analyze match'
  | 'Need ID to analyze match'
  | 'Need name on government issued ID to to analyze match'
  // --- Need More Information Entity
  | 'Need full address to analyze match'
  | 'Need ownership information to analyze match'
  | 'Need vessel flag to analyze match'
  | 'Need year of manufacture to analyze match'
  | 'Need aircraft type to analyze match'
  | 'Need vessel type to analyze match'
