import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import {
  useGetScreenDataRPC,
  TGetScreenDataResponse
} from 'lib/hooks/api/useGetScreenDataRPC'
import { screenQueryKeys } from './queryKeys'

type TQueryOptions = Omit<
  UseQueryOptions<
    TGetScreenDataResponse,
    (string | Record<string, number>)[],
    TGetScreenDataResponse,
    (string | Record<string, number>)[]
  >,
  'queryKey' | 'queryFn'
>

/**
 * useGetScreenData is a hook for querying data for particular screen.
 *
 * @param {number} screenID - The screen ID to query.
 * @param {TQueryOptions} options - react-query query options.
 * @returns {UseQueryResult<TGetScreenDataResponse>} - The query result..
 */
export const useGetScreenData = (
  screenID: number,
  options?: TQueryOptions
): UseQueryResult<TGetScreenDataResponse> => {
  const rpc = useGetScreenDataRPC()
  return useQuery(
    screenQueryKeys.persisted(screenID),
    () => rpc({ screenID: screenID }),
    options
  )
}
