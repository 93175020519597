import React from 'react'
import Grid from '@mui/material/Grid'

import {
  ListSourcesDropDown,
  ListsDropDown,
  IDropDownData
} from 'components/lib/ListSourcesDropDown'
import { ConnectedSearchSlider } from 'components/lib/SearchSlider'
import { FuzzyDOBToggle, WeakAkasToggle } from 'components/lib/Search'

type TScreenParameters = {
  listSourcesDropDownOptions: IDropDownData
}

const ScreenParameters = ({
  listSourcesDropDownOptions
}: TScreenParameters): JSX.Element => {
  return (
    <Grid container spacing={2} item xs={12}>
      <Grid item xs={12} md={6}>
        <ListSourcesDropDown
          status={listSourcesDropDownOptions.status}
          options={listSourcesDropDownOptions.options}
          toggle={listSourcesDropDownOptions.toggle}
          prune={listSourcesDropDownOptions.prune}
          clear={listSourcesDropDownOptions.clear}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <ListsDropDown
          status={listSourcesDropDownOptions.status}
          options={listSourcesDropDownOptions.options}
          toggle={listSourcesDropDownOptions.toggle}
          prune={listSourcesDropDownOptions.prune}
          clear={listSourcesDropDownOptions.clear}
        />
      </Grid>

      <Grid item xs={12} lg={8}>
        <ConnectedSearchSlider name='scoreThreshold' />
      </Grid>

      <Grid item xs={6} lg={2}>
        <FuzzyDOBToggle controlled allowed />
      </Grid>
      <Grid item xs={6} lg={2}>
        <WeakAkasToggle controlled allowed />
      </Grid>
    </Grid>
  )
}

export default ScreenParameters
