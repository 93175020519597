import React, { useState } from 'react'

import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Autocomplete, {
  AutocompleteInputChangeReason
} from '@mui/material/Autocomplete'

import { useField } from 'formik'
import { ICountry } from 'types'
import { styled } from '@mui/material/styles'

interface IProps {
  disabled: boolean
  limit: number
  label: string
  name: string
  error?: boolean
  options: ICountry[]
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    value: ICountry[]
  ) => void
}

const StyledCheckBox = styled(Checkbox)(() => ({
  marginRight: 8
}))

const BaseCountriesSelect: React.FC<IProps> = ({
  disabled,
  error = false,
  label,
  limit,
  name,
  onChange,
  options
}) => {
  const [textFieldValue, setTextFieldValue] = useState('')

  const handleInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason !== 'reset') {
      setTextFieldValue(value)
    }
  }

  return (
    <Autocomplete
      multiple
      fullWidth
      id={name}
      size='small'
      limitTags={limit}
      options={options}
      inputValue={textFieldValue}
      onClose={() => setTextFieldValue('')}
      onInputChange={handleInputChange}
      includeInputInList
      disableCloseOnSelect
      disabled={disabled}
      getOptionLabel={(option) => option.name}
      onChange={onChange}
      renderOption={(props, { name }, { selected }) => (
        <li {...props}>
          <StyledCheckBox color='primary' checked={selected} />
          {name}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} error={error} label={label} variant='standard' />
      )}
    />
  )
}

interface IConnectedCountriesSelect {
  countryMenuItems: ICountry[]
  label: string
  limit: number
  name: string
  error?: boolean
}

export const ConnectedCountriesSelect: React.FC<IConnectedCountriesSelect> = ({
  countryMenuItems,
  label,
  limit,
  error = false,
  name
}) => {
  const [, , helpers] = useField(name)

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ICountry[]
  ) => {
    helpers.setValue(value.map((country) => country.iso2))
  }

  return (
    <BaseCountriesSelect
      name={name}
      label={label}
      error={error}
      limit={limit}
      disabled={error}
      options={countryMenuItems}
      onChange={handleChange}
    />
  )
}

export default ConnectedCountriesSelect
