import React, { useCallback } from 'react'
import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import { IEntitySearchParams } from 'types'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import SearchFormBody from './SearchFormBody'

import { Formik } from 'formik'
import { useAbility, useScreenSettings } from 'lib/authorization'
import UpgradeLink from 'components/lib/Auth/UpgradeLink'
import history from '../../history'
import { trackEvent } from 'lib/analytics/ga4'
import { useNotification } from 'lib/notification'
import {
  FUZZY_DOB_DEFAULT_MONTHS_RANGE,
  DEFAULT_DOB_MONTHS_RANGE
} from 'lib/constants/search'
import { useLocation } from 'react-router-dom'
import {
  parseListSourcesOptions,
  IDropDownData
} from 'components/lib/ListSourcesDropDown'
import { getInitialFormValues, validateFn, IFormValues } from './validation'

const Root = styled(Paper, { label: 'SearchForm' })(() => ({
  display: 'flex',
  justifyContent: 'center',
  padding: 40
}))

interface IProps {
  doSearch: (params: IEntitySearchParams) => void
  listSourcesDropDownOptions: IDropDownData
}

const SearchForm: React.FC<IProps> = ({
  doSearch,
  listSourcesDropDownOptions
}) => {
  const ability = useAbility()
  let queryString = useLocation().search
  const queryParams = new URLSearchParams(queryString)
  const queryName = queryParams.get('name')
  const setNotification = useNotification()
  const { maxResults } = useScreenSettings()

  const showUpgradeMessage =
    ability.cannot('filter', 'Screen', 'weakAka') ||
    ability.cannot('filter', 'Screen', 'location') ||
    ability.cannot('filter', 'Screen', 'id') ||
    ability.cannot('filter', 'Screen', 'dob') ||
    ability.can('view', 'Watermark')

  const handleSearch = useCallback(
    (
      {
        name,
        countries,
        citizenships,
        entityTypes,
        id,
        location,
        scoreThreshold,
        weakAKAs,
        dob,
        fuzzyDOB
      }: IFormValues,
      listSourceCategoriesParam: string[],
      listSourcesParam: { [key: string]: string[] },
      allSelectedListSourceCategories: string[]
    ) => {
      trackEvent('screen_triggered', {
        score_threshold: scoreThreshold,
        num_rows: 1,
        type: 'advanced',
        ...Object.fromEntries(
          allSelectedListSourceCategories.map((cat) => [
            `list_source_category_${cat}`,
            true
          ])
        )
      })

      const searchParams: IEntitySearchParams = {
        name: name,
        location: location,
        idNum: id,
        listSourceCategories: listSourceCategoriesParam,
        listSources: listSourcesParam,
        scoreThreshold: scoreThreshold / 100,
        weakAKAs: weakAKAs,
        dob: dob,
        dobMonthsRange: fuzzyDOB
          ? FUZZY_DOB_DEFAULT_MONTHS_RANGE
          : DEFAULT_DOB_MONTHS_RANGE
      }

      if (entityTypes.length > 0) {
        searchParams.entityType = entityTypes
      }

      if (countries.length > 0) {
        searchParams.country = countries
      }

      if (citizenships.length > 0) {
        searchParams.citizenships = citizenships
      }

      doSearch({
        ...searchParams,
        pageSize: maxResults
      })

      queryString = queryString.replace('&expand=true', '')
      history.push(`/search${queryString}`)
    },
    [doSearch]
  )

  const handleSubmit = useCallback(
    (values: IFormValues) => {
      const { name, location, id } = values
      const {
        listSourceCategoriesParam,
        listSourcesParam,
        allSelectedListSourceCategories
      } = parseListSourcesOptions(listSourcesDropDownOptions.options)

      if (!listSourcesDropDownOptions.anySelected()) {
        setNotification({
          variant: 'error',
          message: 'Select at least one List Source'
        })

        return
      }

      const searchIncludesAdverseMedia = allSelectedListSourceCategories.includes(
        'Adverse Media'
      )

      const queriedWithLocationOrId = location || id

      if (searchIncludesAdverseMedia && (!name || queriedWithLocationOrId)) {
        setNotification({
          variant: 'error',
          message:
            'Please deselect Adverse Media or Input Name (ID or Location not searchable in adverse media).'
        })

        return
      }

      handleSearch(
        values,
        listSourceCategoriesParam,
        listSourcesParam,
        allSelectedListSourceCategories
      )
    },
    [listSourcesDropDownOptions.status, listSourcesDropDownOptions.options]
  )

  return (
    <Formik
      initialValues={getInitialFormValues(queryName)}
      onSubmit={handleSubmit}
      validate={validateFn}
    >
      <Root elevation={4}>
        <Grid container rowSpacing={3}>
          <SearchFormBody
            listSourcesDropDownOptions={listSourcesDropDownOptions}
            queryName={queryName}
          />

          <Grid container item xs={12} justifyContent='center'>
            {showUpgradeMessage && (
              <Typography variant='body1' data-cy='upgrade-search-message'>
                <UpgradeLink additionalText='to enable additional search fields, data categories, and remove watermark.' />
              </Typography>
            )}
          </Grid>
        </Grid>
      </Root>
    </Formik>
  )
}

export default SearchForm
