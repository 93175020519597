import React from 'react'

import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

import { dateRangeToString } from 'lib/entity'

import Highlight from 'components/lib/Highlight'
import BlurredField from 'components/lib/BlurredField'
import { ID } from 'proto-js'
import { Can } from 'lib/authorization'

export const WrappedHighlight = styled(Highlight)(() => ({
  overflowWrap: 'break-word'
}))

const IdTable = styled(Table)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  tableLayout: 'fixed'
}))

const getCountryName = (id: typeof ID): string => id.getCountry().array[3]

const MOCK_ID_NUMBER = 'XXXXXXXX'

const IDRow: React.FC<{ canViewIDNumber: boolean; id: typeof ID }> = ({
  canViewIDNumber,
  id
}) => (
  <TableRow>
    <TableCell>{id.getType()}</TableCell>
    <TableCell>
      {canViewIDNumber ? (
        <WrappedHighlight str={id.getNumber()} />
      ) : (
        <BlurredField data-cy='blurred-item'>{MOCK_ID_NUMBER}</BlurredField>
      )}
    </TableCell>
    <TableCell>{id.hasCountry() ? getCountryName(id) : ''}</TableCell>
    <TableCell>{dateRangeToString(id.getIssueDate())}</TableCell>
    <TableCell>{dateRangeToString(id.getExpirationDate())}</TableCell>
  </TableRow>
)

interface IDsTableProps {
  ids: typeof ID[]
}

const IDsTable: React.FC<IDsTableProps> = ({ ids }) => (
  <IdTable size='small'>
    <TableHead>
      <TableRow>
        <TableCell>ID Type</TableCell>
        <TableCell>ID Number</TableCell>
        <TableCell>Country</TableCell>
        <TableCell>Issue Date</TableCell>
        <TableCell>Exp. Date</TableCell>
      </TableRow>
    </TableHead>
    <Can I='view' a='Screen' field='id' passThrough>
      {(allowed: boolean) => (
        <TableBody>
          {ids.map((id: typeof ID, i: number) => (
            <IDRow id={id} canViewIDNumber={allowed} key={i} />
          ))}
        </TableBody>
      )}
    </Can>
  </IdTable>
)

export default IDsTable
