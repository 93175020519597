import React from 'react'
import { styled } from '@mui/material/styles'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import FormControlLabel from '@mui/material/FormControlLabel'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'

import { TWorkflowConsumer } from 'components/lib/Workflow/workflowReducer'
import { THitClassification } from 'lib/features/Screening'

const PREFIX = 'Classify'

const classes = {
  formControl: `${PREFIX}-formControl`,
  group: `${PREFIX}-group`
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`&.${classes.formControl}`]: {
    margin: theme.spacing(3)
  },

  [`& .${classes.group}`]: {
    margin: theme.spacing(1, 0)
  }
})) as typeof FormControl

const Classify: React.FC<TWorkflowConsumer & { disabled: boolean }> = ({
  state,
  dispatch,
  disabled
}) => {
  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'CLASSIFY',
      payload: e.target.value as THitClassification
    })
  }

  return (
    <StyledFormControl
      component='fieldset'
      className={classes.formControl}
      variant='standard'
      disabled={disabled}
    >
      <FormLabel component='legend'>Select one</FormLabel>
      <RadioGroup
        className={classes.group}
        value={state.classification}
        onChange={handleSelect}
      >
        <FormControlLabel
          value='true-positive'
          control={<Radio color='primary' />}
          label='True Positive'
        />
        <FormControlLabel
          value='false-positive'
          control={<Radio color='primary' />}
          label='False Positive'
        />
        <FormControlLabel
          value='need-more-info'
          control={<Radio color='primary' />}
          label='Need More Information'
        />
      </RadioGroup>
    </StyledFormControl>
  )
}

export default Classify
