import React from 'react'
import Typography from '@mui/material/Typography'
import { TAudit } from 'lib/features/audit'

type AuditLogProps = {
  audit: TAudit
}

export const AuditLog = ({ audit }: AuditLogProps): JSX.Element => (
  <div data-cy='audit-log'>
    <Typography variant='overline'>{audit.timestamp.toString()}</Typography>
    <Typography variant='body2'>{audit.narrative}</Typography>
  </div>
)
