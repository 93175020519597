import { User } from '@auth0/auth0-spa-js'
import {
  AbilityBuilder,
  Ability,
  AbilityClass,
  MongoQuery,
  SubjectRawRule,
  ExtractSubjectType,
  AnyAbility
} from '@casl/ability'
import { defaultPermissions } from './defaultPermissions'

export type Screen = {
  type: 'Screen'
  limit: string
  location: string
  weakAka: boolean
  id: string
  dob: string
}

export type Actions =
  | 'view'
  | 'manage'
  | 'create'
  | 'read'
  | 'update'
  | 'delete'
  | 'filter'
  | 'list'

export type Subjects =
  | 'Report'
  | 'XLSX'
  | 'PDF'
  | 'AuditTrail'
  | 'Jobs'
  | 'Workflow'
  | 'BatchSearch'
  | 'Screen'
  | Screen
  | 'Watermark'

export const ADVERSE_MEDIA_PERMISSION_NAME = 'adverseMedia'
export const UBO_PERMISSION_NAME = 'UBO'

export type GenericSubjects = Exclude<Subjects, Screen>

export type AppAbility = Ability<[Actions, Subjects]>
const appAbility = Ability as AbilityClass<AppAbility>

type TCan = AbilityBuilder<AppAbility>['can']
type TCannot = AbilityBuilder<AppAbility>['cannot']

type TQueryParamPermissionMap = {
  [id: string]: (value: string, can: TCan, cannot: TCannot) => void
}

/* List of all the accepted query parameters that control access to various ScreenEntity features
   We exploit the conditions behaviour of CASL to pass in a value for the maximum number
   of search results a user can see to be able to parse it out when needed elsewhere */
export const SEARCH_PERMISSION_MAP: TQueryParamPermissionMap = {
  adverse_media: (value, can) =>
    can('filter', 'Screen', [ADVERSE_MEDIA_PERMISSION_NAME]),
  limit: (value, can) => can('list', 'Screen', { limit: value }),
  search_address: (value, can) => {
    can('filter', 'Screen', ['location'])
    can('view', 'Screen', ['location'])
  },
  search_dob: (value, can) => {
    can('filter', 'Screen', ['dob'])
    can('view', 'Screen', ['dob'])
  },
  search_id: (value, can) => {
    can('filter', 'Screen', ['id'])
    can('view', 'Screen', ['id'])
  },
  search_weak_aka: (value, can) => {
    can('filter', 'Screen', ['weakAka'])
    can('view', 'Screen', ['weakAka'])
  },
  ubo: (value, can) => can('filter', 'Screen', [UBO_PERMISSION_NAME]),
  view_address: (value, can) => {
    can('view', 'Screen', ['location'])
  },
  view_dob: (value, can) => {
    can('view', 'Screen', ['dob'])
  },
  view_id: (value, can) => {
    can('view', 'Screen', ['id'])
  }
}

// List of all the accepted query parameters that control access to various ScreenEntities
// features
export const BATCH_SEARCH_PERMISSION_MAP: TQueryParamPermissionMap = {
  adverse_media: (value, can) =>
    can('filter', 'BatchSearch', [ADVERSE_MEDIA_PERMISSION_NAME]),
  ubo: (value, can) => can('filter', 'BatchSearch', [UBO_PERMISSION_NAME])
}

// List of all the accepted query parameters that control access to various ScheduledJob
// features
export const SCHEDULED_SEARCH_PERMISSION_MAP: TQueryParamPermissionMap = {
  adverse_media: (value, can) =>
    can('manage', 'Jobs', [ADVERSE_MEDIA_PERMISSION_NAME]),
  ubo: (value, can) => can('manage', 'Jobs', [UBO_PERMISSION_NAME])
}

// Parse and assign the permissions out of the query params originating from
// a permission like
// https://castellum.ai/pb.v1.SearchService/ScreenEntity?search_weak_aka&search_id
export const assignSearchParamPermissions = (
  permission: URL,
  map: TQueryParamPermissionMap,
  can: TCan,
  cannot: TCannot
): void => {
  permission.searchParams.forEach((value, key) => {
    const permissionFunction = map[key]

    if (permissionFunction) {
      permissionFunction(value, can, cannot)
    }
  })
}

// Parse and assign the permissions out of a query string such as
// https://castellum.ai/pb.v1.SearchService/GetListEntriesData?crypto_sanctions
export const assignReportPermissions = (permission: URL, can: TCan): void => {
  const queryParams = permission.searchParams

  can('view', 'Report')

  if (queryParams.get('crypto_sanctions')) {
    can('view', 'Report', ['crypto_sanctions'])
  }
}

// Parse and assign the permissions out of a string like
// https://castellum.ai/pb.v1.SearchService/GetScreeningReport?type=pdf
export const assignScreeningReportPermissions = (
  permission: URL,
  can: TCan
): void => {
  const queryParams = permission.searchParams

  if (queryParams.get('type') === 'pdf') {
    can('view', 'PDF')
  }

  if (queryParams.get('type') === 'xlsx') {
    can('view', 'XLSX')
  }
}

type TPermissionMap = {
  [id: string]: (permission: URL, can: TCan, cannot: TCannot) => void
}

// Parse and assign the permissions out of a string like the
// https://castellum.ai/pb.v1.SearchService/ScreenEntity endpoint
export const assignSearchPermissions = (
  permission: URL,
  can: TCan,
  cannot: TCannot
): void => {
  assignSearchParamPermissions(permission, SEARCH_PERMISSION_MAP, can, cannot)
}

// Parse and assign the permissions out of a string like the
// https://castellum.ai/pb.v1.SearchService/ScreenEntities endpoint
export const assignBatchSearchPermissions = (
  permission: URL,
  can: TCan,
  cannot: TCannot
): void => {
  assignSearchParamPermissions(
    permission,
    BATCH_SEARCH_PERMISSION_MAP,
    can,
    cannot
  )
}

// Parse and assign the permissions out of a string like the
// https://castellum.ai/pb.v1.WorkflowService/PostScheduledJob endpoint
export const assignScheduledJobsPermissions = (
  permission: URL,
  can: TCan,
  cannot: TCannot
): void => {
  assignSearchParamPermissions(
    permission,
    SCHEDULED_SEARCH_PERMISSION_MAP,
    can,
    cannot
  )
}

const PERMISSION_MAP: TPermissionMap = {
  '/AuditTrail': (permission, can) => can('view', 'AuditTrail'),
  '/pb.v1.WorkflowService/GetScheduledJobs': (permission, can) =>
    can('view', 'Jobs'),
  '/GetScreeningReport': assignScreeningReportPermissions,
  '/OnboardingWorkflow': (permission, can) => can('update', 'Workflow'),
  '/pb.v1.SearchService/ScreenEntity': assignSearchPermissions,
  '/pb.v1.SearchService/GetListEntriesData': assignReportPermissions,
  '/pb.v1.SearchService/ScreenEntities': assignBatchSearchPermissions,
  '/pb.v1.WorkflowService/BatchScreenEntities': (permission, can) =>
    can('create', 'BatchSearch'),
  '/pb.v1.WorkflowService/PostScheduledJob': assignScheduledJobsPermissions,
  '/RemoveWatermark': (permission, can, cannot) => cannot('view', 'Watermark')
}

export default function abilityForUser(
  user?: User,
  token?: Token
): SubjectRawRule<
  Actions,
  ExtractSubjectType<Subjects>,
  MongoQuery<AnyAbility>
>[] {
  const { can, cannot, rules } = new AbilityBuilder(appAbility)

  const permissionNames = token?.permissions || (defaultPermissions as string[])
  can('view', 'Watermark')

  permissionNames.forEach((permissionName) => {
    try {
      const permission = new URL(permissionName)
      const permissionFunction = PERMISSION_MAP[permission.pathname]

      if (permissionFunction) {
        permissionFunction(permission, can, cannot)
      }
    } catch (TypeError) {
      console.warn('Invalid permission: ', permissionName)
    }
  })

  return rules
}

export function buildAbilityFor(user?: User, token?: Token): AppAbility {
  return new appAbility(abilityForUser(user, token), {
    detectSubjectType: (object) => object?.type
  })
}
