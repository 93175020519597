import React from 'react'
import Placeholder from 'components/lib/Placeholder'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Help from '@mui/icons-material/Help'

const tooltipText =
  'Expected to see something? Try lowering the score, removing filters, ' +
  "or make sure you're entering a full name."

const NoMatches: React.FC = () => (
  <Placeholder>
    <Typography align='center' variant='h5' data-cy='no-matches-text'>
      No Matches
      <Tooltip title={tooltipText}>
        <Help fontSize='small' />
      </Tooltip>
    </Typography>
  </Placeholder>
)

export default NoMatches
