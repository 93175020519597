import { useGRPC } from 'lib/hooks'
import { IEntitySearchParams } from 'types'
import {
  List,
  ListSource,
  ScreenEntityRequest,
  ScreenEntityQuery,
  ScreenEntityResponse
} from 'proto-js'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'

export type TScreenEntityRPC = (
  params: IEntitySearchParams
) => Promise<typeof ScreenEntityResponse>

/**
 * Creates the appropriate protobuf message type for the RPC. The query is configured to
 * return the top 100 results.
 *
 * @param {IEntitySearchParams} params - The query parameters.
 * @returns {IEntitySearchParams} - The protobuf message type for the query.
 */
export const prepareRPCRequest = (
  params: IEntitySearchParams
): typeof ScreenEntityRequest => {
  const qry = new ScreenEntityQuery()
  const req = new ScreenEntityRequest()
  qry.setPageFrom(0)
  qry.setPageSize(params.pageSize || 50)
  qry.setMinScore(params.scoreThreshold)
  params.name !== '' && qry.setName(params.name)
  params.location !== '' && qry.setAddress(params.location)
  params.idNum !== '' && qry.setIdNumber(params.idNum)
  qry.setExcludeWeakAkas(!params.weakAKAs)

  if (params.dob) {
    const timestamp = new Timestamp()
    timestamp.fromDate(params.dob)
    qry.setDob(timestamp)
    qry.setDobMonthsRange(params.dobMonthsRange)
  }

  if (
    Object.prototype.hasOwnProperty.call(params, 'entityType') &&
    params.entityType
  ) {
    qry.setEntityTypesList(params.entityType)
  }
  if (Object.hasOwnProperty.call(params, 'country') && params.country) {
    qry.setCountriesList(params.country)
  }
  if (
    Object.hasOwnProperty.call(params, 'citizenships') &&
    params.citizenships
  ) {
    qry.setCitizenshipsList(params.citizenships)
  }

  if (
    Object.hasOwnProperty.call(params, 'listSourceCategories') &&
    params.listSourceCategories
  ) {
    qry.setListSourceCategoriesList(params.listSourceCategories)
  }

  if (Object.hasOwnProperty.call(params, 'listSources') && params.listSources) {
    const listSources = []
    for (const [listSourceName, listNames] of Object.entries(
      params.listSources
    )) {
      const listSource = new ListSource()
      listSource.setName(listSourceName)
      listSource.setListsList(
        listNames.map((listName) => {
          const list = new List()
          list.setName(listName)
          return list
        })
      )
      listSources.push(listSource)
    }
    qry.setListSourcesList(listSources)
  }

  req.setQuery(qry)
  return req
}

/**
 * Hook providing the function for screening entities.
 *
 * @returns {() => Promise<typeof ScreenEntityResponse>} - The screening function.
 */
export const useScreenEntityRPC = (): TScreenEntityRPC => {
  const { searchPromiseService } = useGRPC()

  return async (
    params: IEntitySearchParams
  ): Promise<typeof ScreenEntityResponse> => {
    const req = prepareRPCRequest(params)

    return searchPromiseService
      .screenEntity(req, {})
      .then((resp: typeof ScreenEntityResponse) => {
        return resp
      })
  }
}
