import React, { useCallback, useEffect, useState } from 'react'

import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'

import { useFormField } from 'lib/hooks'
import DateRangePicker, { isDateValid } from 'components/lib/DateSelection'
import { FormField } from 'components/lib/Search'
import { TAuditTrailSearchParams } from 'lib/features/audit'
import endOfDay from 'date-fns/endOfDay'
import { trackEvent } from 'lib/analytics/ga4'

const StyledForm = styled('form')(() => ({
  width: '100%'
}))

const StyledPaper = styled(Paper)(() => ({
  display: 'flex',
  justifyContent: 'center',
  padding: '25px',
  paddingTop: '20px',
  width: '100%'
}))

/**
 * Are the entered Date obj value(s) in acceptable positions relative to one another (aka chronological)?
 * @param startDate
 * @param endDate
 * @returns whether either date is null or both are in order
 */
export const areDatesChronological = (
  startDate: Date | null,
  endDate: Date | null
): boolean | null => {
  const chronologyValid = (startDate: Date | null, endDate: Date | null) =>
    !startDate || !endDate || startDate <= endDate

  return chronologyValid(startDate, endDate)
}

export type TSearchFormParams = Omit<
  TAuditTrailSearchParams,
  'pageNumber' | 'pageSize'
>

const SearchForm: React.FC<{
  onFilterClick: (params: TSearchFormParams) => void
}> = ({ onFilterClick }) => {
  const [nameField, setNameField] = useFormField<string>('Name', '')
  const [idNumberField, setIdNumberField] = useFormField<string>('ID', '')
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [filterButtonDisabled, setFilterButtonDisabled] = useState<boolean>(
    false
  )

  const handleSearch = useCallback(
    (e) => {
      e.preventDefault()
      trackEvent('audit_search_submitted')

      // Hours are not set as part of endDate, so it will reflect the beginning
      // of the day, as such, set the hours to end of day here
      onFilterClick({
        name: nameField.value,
        idNumber: idNumberField.value,
        startDate: startDate,
        endDate: endDate ? endOfDay(endDate) : null
      })
    },
    [onFilterClick, startDate, endDate, nameField.value, idNumberField.value]
  )

  useEffect(() => {
    if (
      // Disable the filter button if the dates are not valid
      !isDateValid(startDate) ||
      !isDateValid(endDate) ||
      !areDatesChronological(startDate, endDate)
    ) {
      setFilterButtonDisabled(true)
    } else {
      setFilterButtonDisabled(false)
    }
  }, [startDate, endDate])

  return (
    <StyledForm onSubmit={handleSearch}>
      <StyledPaper elevation={4}>
        <Grid container spacing={3}>
          <Grid container spacing={3} item xs={12}>
            <Grid
              container
              spacing={1.9}
              item
              xs={12}
              md={6}
              lg={4}
              style={{ paddingTop: '24px' }}
            >
              <Grid item xs={12}>
                <FormField
                  id='name'
                  state={nameField}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    setNameField({
                      action: 'value',
                      value: e.target.value
                    })
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <FormField
                  id='idNum'
                  state={idNumberField}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    setIdNumberField({
                      action: 'value',
                      value: e.target.value
                    })
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} item xs={12} md={6} lg={4}>
              <Grid item xs={12}>
                <DateRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  startLabel='Start date'
                  endLabel='End date'
                  onStartDateChange={(newDate: Date | null) => {
                    setStartDate(newDate)
                  }}
                  onEndDateChange={(newDate: Date | null) => {
                    setEndDate(newDate)
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant='contained'
                  type='submit'
                  data-cy='trail-filter-button'
                  color='secondary'
                  disabled={filterButtonDisabled}
                >
                  Filter
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </StyledPaper>
    </StyledForm>
  )
}

export default SearchForm
