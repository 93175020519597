import React from 'react'
import { Redirect } from 'react-router-dom'
import { WaveLoader } from 'components/lib/Loader'

type TPageWithLoaderProps = {
  isSuccess: boolean
  isError: boolean
  children: React.ReactNode
}

const PageWithLoader = ({
  isSuccess,
  isError,
  children
}: TPageWithLoaderProps): JSX.Element => {
  if (isSuccess) {
    return <>{children}</>
  } else if (isError) {
    return <Redirect to='/error' />
  }
  return <WaveLoader />
}

export default PageWithLoader
