import React from 'react'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import { TWorkflowConsumer } from 'components/lib/Workflow/workflowReducer'

const AnalysisField = styled(TextField)(() => ({
  flexBasis: '75%'
}))

const Analysis: React.FC<TWorkflowConsumer> = ({ state, dispatch }) => {
  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    dispatch({ type: 'SET_ANALYSIS', payload: e.target.value })
  }

  return (
    <AnalysisField
      label='Analysis'
      multiline
      rows='6'
      variant='outlined'
      value={state.analysis}
      onChange={(e) => handleChange(e)}
    />
  )
}

export default Analysis
