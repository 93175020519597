import React from 'react'

enum ERemoteDataStreamStatus {
  Idle = 'idle',
  Fetching = 'fetching',
  Error = 'error',
  Success = 'success'
}

export interface IRemoteDataStreamState<TData, TError = unknown> {
  status: ERemoteDataStreamStatus
  isIdle: boolean
  isFetching: boolean
  isError: boolean
  isSuccess: boolean
  error: TError | null
}

export enum ERemoteDataStreamActionType {
  Start = 'Start',
  Data = 'Data',
  Error = 'Error',
  Success = 'Success',
  Reset = 'Reset'
}

interface IStartAction {
  type: ERemoteDataStreamActionType.Start
}

interface IDataAction<TData> {
  type: ERemoteDataStreamActionType.Data
  data: TData
}

interface IErrorAction<TError> {
  type: ERemoteDataStreamActionType.Error
  error: TError
}

interface ISuccessAction {
  type: ERemoteDataStreamActionType.Success
}

interface IResetAction {
  type: ERemoteDataStreamActionType.Reset
}

export type TRemoteDataStreamAction<TData, TError = unknown> =
  | IStartAction
  | IDataAction<TData>
  | IErrorAction<TError>
  | ISuccessAction
  | IResetAction

type TRemoteDataStreamDispatch<T> = React.Dispatch<
  TRemoteDataStreamAction<T, Error>
>
type TRemoteDataStreamReducer<T> = React.Reducer<
  IRemoteDataStreamState<T>,
  TRemoteDataStreamAction<T, Error>
>

/**
 * Reducer function for handling the possible actions on remote data streaming state.
 *
 * @param {IRemoteDataStreamState} state - The remote data state.
 * @param {TRemoteDataStreamAction} action - The action to be performed.
 */
function reducer<T>(
  state: IRemoteDataStreamState<T>,
  action: TRemoteDataStreamAction<T>
): IRemoteDataStreamState<T> {
  switch (action.type) {
    case ERemoteDataStreamActionType.Start:
      return {
        ...state,
        status: ERemoteDataStreamStatus.Fetching,
        isIdle: false,
        isFetching: true,
        isError: false,
        isSuccess: false,
        error: null
      }
    case ERemoteDataStreamActionType.Data:
      return {
        ...state,
        status: ERemoteDataStreamStatus.Fetching,
        isIdle: false,
        isFetching: true
      }
    case ERemoteDataStreamActionType.Error:
      return {
        ...state,
        status: ERemoteDataStreamStatus.Error,
        isIdle: false,
        isFetching: false,
        isError: true,
        isSuccess: false,
        error: action.error
      }
    case ERemoteDataStreamActionType.Success:
      return {
        ...state,
        status: ERemoteDataStreamStatus.Success,
        isIdle: false,
        isFetching: false,
        isError: false,
        isSuccess: true,
        error: null
      }
    case ERemoteDataStreamActionType.Reset:
      return {
        status: ERemoteDataStreamStatus.Idle,
        isIdle: true,
        isFetching: false,
        isError: false,
        isSuccess: false,
        error: null
      }
    default:
      throw new Error()
  }
}

/**
 * Hook providing functionality for managing a remote data stream. It returns a state
 * object and dispatch function for performing a predefined set of actions on the state of the
 * stream.
 *
 * @returns {[IRemoteDataStreamState<T>, TRemoteDataStreamDispatch<T>]} - The state and dispatch function
 */
export function useRemoteDataStream<T>(): [
  IRemoteDataStreamState<T>,
  TRemoteDataStreamDispatch<T>
] {
  const [state, dispatch] = React.useReducer<TRemoteDataStreamReducer<T>>(
    reducer,
    {
      status: ERemoteDataStreamStatus.Idle,
      isIdle: true,
      isFetching: false,
      isError: false,
      isSuccess: false,
      error: null
    }
  )
  return [state, dispatch]
}

export default useRemoteDataStream
