import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'

import theme from '../../styles/theme'
import AppContainer from './AppContainer'

const App: React.FC = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppContainer />
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default App
