import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import {
  useSearchScreenedEntitiesRPC,
  TSearchScreenedEntitiesResponse
} from 'lib/hooks/api/useSearchScreenedEntitiesRPC'
import { TAuditTrailSearchParams } from 'lib/features/audit'
import { auditQueryKeys } from './queryKeys'

type TQueryOptions = Omit<
  UseQueryOptions<
    TSearchScreenedEntitiesResponse,
    (string | Record<string, string>)[],
    TSearchScreenedEntitiesResponse,
    (string | Record<string, string>)[]
  >,
  'queryKey' | 'queryFn'
>

/**
 * useSearchScreenedEntities is a hook for searching screened entities given a combination
 * of optional start/end date and name, id number.
 *
 * @param {TAuditTrailSearchParams} params - The search params.
 * @param {TQueryOptions} options - react-query query options.
 * @returns {UseQueryResult<TSearchScreenedEntitiesResponse>} - The query results.
 */
export const useSearchScreenedEntities = (
  params: TAuditTrailSearchParams,
  options: TQueryOptions = {}
): UseQueryResult<TSearchScreenedEntitiesResponse> => {
  const rpc = useSearchScreenedEntitiesRPC()

  return useQuery(
    auditQueryKeys.searchScreenedEntities(params),
    () => rpc(params),
    {
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  )
}
