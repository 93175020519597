import { TScheduledJob } from 'lib/features/job'
import { useGRPC } from 'lib/hooks'
import { GetScheduledJobRequest, GetScheduledJobResponse } from 'proto-js'
import { jobFromProtobuf } from './utils'

export type TGetScheduledJobRequest = {
  jobID: number
}

export const prepareRPCRequest = (
  request: TGetScheduledJobRequest
): typeof GetScheduledJobRequest => {
  const rpcRequest = new GetScheduledJobRequest()
  rpcRequest.setJobId(request.jobID)
  return rpcRequest
}

export type TGetScheduledJobResponse = Persisted<TScheduledJob>

export const prepareRPCResponse = (
  resp: typeof GetScheduledJobResponse
): TGetScheduledJobResponse => jobFromProtobuf(resp.getJob())

type TGetScheduledJobRPC = (
  params: TGetScheduledJobRequest
) => Promise<TGetScheduledJobResponse>

/**
 * useGetScheduledJobRPC provides an async function to invoke the GetScheduledJob RPC.
 *
 * @returns {TGetScheduledJobRPC} - An async function to invoke the RPC.
 */
export const useGetScheduledJobRPC = (): TGetScheduledJobRPC => {
  const { workflowPromiseService } = useGRPC()
  return async (params: TGetScheduledJobRequest) => {
    return workflowPromiseService
      .getScheduledJob(prepareRPCRequest(params), {})
      .then(prepareRPCResponse)
  }
}
